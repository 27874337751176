import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import BrainSelect from '@shared/components/BrainSelect';

import FieldLabel from '../../FieldLabel';

import classes from './styles.scss';

export default function MultiSelectField({
  label,
  info,
  value: values = [],
  settings: { options, valueOnly = true },
  onChange,
  disabled = false,
  isMulti = true,
  isAsync = false,
  ...otherProps
}) {
  const [localValues, setLocalValues] = useState([]);
  useEffect(() => {
    let vals = values;
    // workaround for descriptors that changed from select to multi select
    if (typeof values === 'string') {
      vals = [values];
    }
    setLocalValues(
      valueOnly ? vals?.map((item) => ({ label: item, value: item })) : vals,
    );
  }, []);

  const localOnChange = (newValue) => {
    setLocalValues(newValue);
    onChange(valueOnly ? newValue?.map((item) => item.value) : newValue);
  };

  const onOptionsReady = (optionsS) => {
    if (valueOnly) {
      setLocalValues(optionsS && optionsS.filter((op) => values.includes(op.value)));
    }
  };

  return (
    <div className={classes['multi-select-descriptor']}>
      <BrainSelect
        label={<FieldLabel label={label} info={info} />}
        value={localValues}
        onChange={localOnChange}
        isMulti={isMulti}
        isAsync={isAsync}
        isClearable
        onOptionsReady={onOptionsReady}
        options={options}
        disabled={disabled}
        {...otherProps}
      />
    </div>
  );
}

MultiSelectField.propTypes = {
  settings: PropTypes.shape({
    options: PropTypes.array,
    valueOnly: PropTypes.bool,
  }),

  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.node.isRequired,
      }),
    ),
  ]),

  label: PropTypes.node.isRequired,
  info: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isAsync: PropTypes.bool,
};
