import { useEffect, useState } from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import BrainTextarea from '@shared/components/BrainTextarea';
import useRandomString from '@shared/hooks/useRandomString';

import FieldLabel from '../../FieldLabel';

import classes from './styles.scss';

export default function TextareaField({
  label,
  info,
  disabled = false,
  value = '',
  onChange,
  className,
  ...otherProps
}) {
  const [localValue, setLocalValue] = useState(value ?? '');

  useEffect(() => {
    setLocalValue(value ?? '');
  }, [value]);

  const onLocalChange = (e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    onChange(newValue);
  };

  const inputId = useRandomString();
  return (
    <FormControl classes={{ root: cc([classes['textarea-field'], className]) }}>
      <BrainTextarea
        id={`${inputId}`}
        value={localValue}
        onInput={onLocalChange}
        disabled={disabled}
        {...otherProps}
      />
      <FormLabel
        classes={{ root: classes.label }}
        htmlFor={`${inputId}`}
        data-testid="textarea-field-label"
      >
        <FieldLabel label={label} info={info} />
      </FormLabel>
    </FormControl>
  );
}

TextareaField.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.node.isRequired,
  info: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
