export default function ReplaceFileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41">
      <g fill="none" fillRule="evenodd">
        <g stroke="currentColor" strokeLinejoin="round" strokeWidth="1.5">
          <path d="M23.857 10H17v16.286h12V15.143" />
          <path d="M23.857 10v5.143H29z" />
          <path d="M25 30.286H13v-15" strokeDasharray="2,3" strokeLinecap="round" />
        </g>
      </g>
    </svg>
  );
}
