import classes from './styles.scss';

export default function BugReportFooter() {
  return (
    <div className={classes['bug-report-footer']}>
      The BRAIN team is focused on delivering the best user experience for you. If you
      notice any issues, please&nbsp;
      <a href="https://healthyio.monday.com/boards/3185863348" target="_blank">
        submit a bug report
      </a>
      .
    </div>
  );
}
