import PropTypes from 'prop-types';

import BaseInputField, {
  TYPE_TEXT,
} from '@shared/components/BrainForm/Fields/BaseInputField';

export default function TextField({ settings, ...restProps }) {
  const newSettings = {
    inputType: settings?.inputType ?? TYPE_TEXT,
    labelSettings: settings?.labelSettings ?? {},
  };
  return <BaseInputField settings={newSettings} {...restProps} />;
}

TextField.propTypes = {
  settings: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.node.isRequired,
  info: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};
