// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._3PNqCpD2 {\n  color: var(--defaultBlack);\n  font-size: 14px;\n  letter-spacing: 0.1px;\n  line-height: 16px;\n  height: 48px;\n  white-space: pre-wrap\n}\ndiv._3PNqCpD2:hover,\n  div._3PNqCpD2._13o7-FDd,\n  div._3PNqCpD2._2ig2XDqb,\n  div._3PNqCpD2._2ig2XDqb:hover {\n    background-color: var(--veryLightGrey);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": "_3PNqCpD2",
	"focused": "_13o7-FDd",
	"selected": "_2ig2XDqb"
};
export default ___CSS_LOADER_EXPORT___;
