import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { openTasksSelector } from './selectors';
import { setIsOpen } from './slice';

const selectOpenTasks = createStructuredSelector({ openTasks: openTasksSelector });

export const useUserProfile = () => {
  const dispatch = useDispatch();
  const {
    openTasks: {
      isOpen: showUserProfile,
      loading: fetchTasksLoading,
      error: fetchTasksError,
      tasks,
    },
  } = useSelector(selectOpenTasks);

  const setShowUserProfile = (flag) => dispatch(setIsOpen({ isOpen: flag }));

  return {
    showUserProfile,
    setShowUserProfile,
    fetchTasksLoading,
    fetchTasksError,
    tasks,
  };
};
