export default function AddFileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="1.5"
        transform="translate(1 1)"
      >
        <g strokeLinejoin="round">
          <path fill="currentColor" d="M6.857 0H0v16.286h12V5.143z" />
          <path fill="#FFF" d="M5.107-.81v7.703h7.704L5.107-.811z" />
        </g>
        <circle cx="11" cy="16" r="6" fill="#FFF" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M11 13v6m3-3H8" />
      </g>
    </svg>
  );
}
