import components from './components';
import styles from './styles';

const filterOption = (option, rawInput) =>
  rawInput
    .split(' ')
    .reduce(
      (acc, cur) => acc && option.label?.toLowerCase().includes(cur.toLowerCase()),
      true,
    );

const selectProps = {
  maxMenuHeight: 240,
  menuPlacement: 'auto',
  className: 'brain-select',
  components,
  styles,
  filterOption,
};

export default selectProps;
