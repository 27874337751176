import BrainButton from '@shared/components/BrainButton';
import history from '@shared/redux/history';

import UnauthorizedImage from './unauthorized.svg';
import classes from './styles.scss';

export default function UnauthorizedPage() {
  const goBack = () => {
    history.goBack();
  };
  return (
    <div className={classes.unauthorized}>
      <img className={classes.image} src={UnauthorizedImage} alt="Unauthorized" />
      <h3 className={classes.main}>You don’t have access to this page</h3>
      <span className={classes.subtitle}>
        If you need access, contact your team leader for support.
      </span>
      <BrainButton classNames={[classes.button]} variant="text" onClick={goBack}>
        BACK
      </BrainButton>
    </div>
  );
}
