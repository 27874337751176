/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import produce from 'immer';
import isEqual from 'lodash/isEqual';

import BrainButton from '@shared/components/BrainButton';
import history from '@shared/redux/history';

import { getProcessTypePrefix, processPluralType } from 'utils/processUtils/helpers';

import BreadcrumbsHeader from 'components/BreadcrumbsHeader';

import BrainCollapsible from '../../BrainCollapsible';
import TrashButton from '../../TrashButton';
import StatusSelector from '../StatusSelector';
import RemarksField from '../components/RemarksField';
import DescriptorTableHeader from '../components/DescriptorTableHeader';

import ItemStatus from './components/ItemStatus';

const descriptorCss = css`
  grid-column: 1 / -1;
`;

const tableRowCss = css`
  display: grid;
  height: 52px;
  grid-template-columns: 600px 200px 1fr;
  padding-left: 30px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
`;

const tableCellCss = css`
  display: flex;
  align-items: center;
  padding: 0px 10px;
  overflow: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
`;

const rowLabelCss = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const innerLabelCss = css`
  font-weight: bold;
`;

const statusCss = ({ hasStatus }) => css`
  width: 100%;
  ${hasStatus ? `opacity: 1` : `opacity: 0`};
`;

const conditionCss = css`
  ${tableCellCss}
  border-left: none;
`;

const maxWidth = css`
  width: 100%;
`;

// eslint-disable-next-line no-unused-vars
export default function PassFailDescriptor({
  id,
  label,
  descriptorState: {
    value: {
      items,
      showItemStatus = true,
      showAQLTable = true,
      showAddRow = true,
      showTableHeader = true,
      isExpandMore = true,
      conditionOptions,
      statusOptions,
    },
  },
  onChange,
  domain,
  className,
  isShowButton = false,
  isBreadcrumbsHeader,
  isEcoTypeDefinition,
}) {
  const [localItems, setItems] = useState(items ?? []);
  const [isRowHovered, setRowHovered] = useState(null);
  const [isCondHovered, setCondHovered] = useState(null);

  // const statusEco = localItems?.conditions.key();
  const statusConditions = localItems.flatMap(({ conditions }) =>
    Object.values(conditions)?.map((status) => status.status),
  );
  const isCompleted = statusConditions.includes(undefined);
  const setConditionAttr = (rowIdx, conditionIdx, attr, newVal) => {
    setItems(
      produce((itemsDraft) => {
        itemsDraft[rowIdx].conditions[conditionIdx][attr] = newVal;
      }),
    );
  };

  const check = statusConditions.includes('no');
  const processType = check ? 'eco' : 'short-eco';

  const handleClickSubmit = () => {
    history.replace(`/process/${getProcessTypePrefix(processType, { domain })}new`);
  };

  const onAddRow = () => {
    setItems(
      produce((itemsDraft) => {
        const conditions = {};
        conditionOptions.forEach((condition) => {
          conditions[condition] = {};
        });
        itemsDraft.push({ conditions, status: null });
      }),
    );
  };

  const onRemoveRow = (rowIdx) => {
    setItems(
      produce((itemsDraft) => {
        itemsDraft[rowIdx].deleted = true;
      }),
    );
  };

  const handleShowCond = (index, rowIdx) => {
    setCondHovered(index);
    setRowHovered(rowIdx);
  };

  const handleHideCond = (remarks, key, rowIdx) => {
    setCondHovered(null);
    setRowHovered(null);
    if (!remarks) {
      setConditionAttr(rowIdx, key, 'clicked', false);
    }
  };

  const handleClickCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    const newState = { value: { items: localItems } };
    onChange({
      descriptorId: id,
      descriptorState: newState,
      isInitialState: isEqual(localItems, items),
    });
  }, [localItems]);

  let rowDisplayIdx = 0;
  return (
    <>
      {isBreadcrumbsHeader && (
        <BreadcrumbsHeader
          breadCrumbs={[
            {
              label: 'QMS Processes',
              path: '/processes',
            },
            {
              label: processPluralType('ECO'),
              path: '/processes/eco',
            },
          ]}
          title="Engineering change request"
        />
      )}
      {showTableHeader && (
        <DescriptorTableHeader
          onAddRow={onAddRow}
          title={label}
          showAQLTable={showAQLTable}
          showAddRow={showAddRow}
        />
      )}
      {localItems.map((row, rowIdx) => {
        if (row.deleted) {
          return null;
        }
        rowDisplayIdx += 1;
        return (
          <div css={descriptorCss}>
            <BrainCollapsible
              isExpandMore={isExpandMore}
              hoverable
              key={rowIdx}
              togglePosition="right"
              isExpanded={rowIdx === 0}
              label={
                <div css={rowLabelCss}>
                  <span css={innerLabelCss}>
                    {`Test Item ${rowDisplayIdx}`}
                    {showItemStatus && <ItemStatus conditions={row.conditions} />}
                  </span>

                  <TrashButton
                    onClick={() => onRemoveRow(rowIdx)}
                    shouldConfirm
                    confirmationText={`Are you sure you want to delete ${`Test Item ${rowDisplayIdx}`}?`}
                  />
                </div>
              }
            >
              {Object.entries(row.conditions).map(
                ([key, { remarks, status, clicked }], index) => (
                  // TO DO: Find a way to remove onFocus and onBlur
                  <div
                    key={key}
                    css={tableRowCss}
                    onMouseOver={() => handleShowCond(index, rowIdx)}
                    onMouseLeave={() => handleHideCond(remarks, key, rowIdx)}
                    onFocus={() => {}}
                    onBlur={() => {}}
                  >
                    <div css={conditionCss}>{key}</div>
                    <div css={tableCellCss}>
                      {(status ||
                        (isRowHovered === rowIdx && isCondHovered === index)) && (
                        <StatusSelector
                          css={statusCss({ hasStatus: !!status })}
                          status={status}
                          isEcoTypeDefinition={isEcoTypeDefinition}
                          statusOptions={statusOptions}
                          onStatusChange={(newVal) => {
                            setConditionAttr(rowIdx, key, 'status', newVal);
                          }}
                        />
                      )}
                    </div>
                    {!remarks &&
                      !clicked &&
                      showAddRow &&
                      isRowHovered === rowIdx &&
                      isCondHovered === index && (
                        <div css={tableCellCss}>
                          <div css={maxWidth}>
                            <BrainButton
                              css={maxWidth}
                              variant="text"
                              onClick={() =>
                                setConditionAttr(rowIdx, key, 'clicked', true)
                              }
                            >
                              <span>&#43; ADD REMARK</span>
                            </BrainButton>
                          </div>
                        </div>
                      )}
                    {(remarks ||
                      (isRowHovered === rowIdx &&
                        isCondHovered === index &&
                        clicked)) && (
                      <div css={tableCellCss}>
                        <RemarksField
                          onChange={(newVal) =>
                            setConditionAttr(rowIdx, key, 'remarks', newVal)
                          }
                          remarks={remarks}
                        />
                      </div>
                    )}
                  </div>
                ),
              )}
            </BrainCollapsible>
          </div>
        );
      })}

      {isShowButton && (
        <div className={className}>
          <BrainButton variant="text" onClick={handleClickCancel}>
            Cancel
          </BrainButton>
          <BrainButton
            onClick={handleClickSubmit}
            disabled={isCompleted}
            data-testid="init-btn"
          >
            Next
          </BrainButton>
        </div>
      )}
    </>
  );
}

PassFailDescriptor.propTypes = {
  id: PropTypes.number.isRequired,
  descriptorState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isBreadcrumbsHeader: PropTypes.bool,
  domain: PropTypes.string,
  className: PropTypes.string,
  isShowButton: PropTypes.bool,
  isEcoTypeDefinition: PropTypes.bool,
};
