import ReactDOM from '@hot-loader/react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import HealthySnackbarProvider from '@shared/components/HealthySnackbarProvider';
import Toaster from '@shared/components/Toaster';
import generateMUITheme from '@shared/utils/generateMUITheme';
import history from '@shared/redux/history';
import configureStore from '@shared/redux/configureStore';
import '@shared/styles/global-styles.scss';
import '@shared/fonts/roboto/font-faces.scss';
import { reporter, ErrorBoundary } from '@shared/reporter';

import './global-styles.scss';
import './images/favicon.ico';
import themeOverrides from 'utils/theme';

import App from 'containers/App';

import ErrorBoundaryPage from './components/ErrorBoundaryPage';

const backendURL = process.env.PRODUCTION_RUN?.BACKEND_API_URL;

const store = configureStore();
const theme = generateMUITheme(themeOverrides);
const queryClient = new QueryClient();

reporter.init({ dsn: process.env.SENTRY_DSN });

const mandarinScripts = [
  '/mandarin/static/dayjs/dayjs.min.js',
  '/mandarin/static/dayjs/plugin/relativeTime.js',
  '/mandarin/static/htmx.org/dist/htmx.min.js',
  '/mandarin/static/alpinejs/dist/cdn.min.js',
];

ReactDOM.render(
  <ErrorBoundary fallbackComponent={ErrorBoundaryPage}>
    <Helmet titleTemplate="%s | BRAIN" defaultTitle="BRAIN">
      {mandarinScripts.map((s, idx) => (
        <script key={`mandarin-script-${idx}`} src={`${backendURL}${s}`} />
      ))}
      <link href={`${backendURL}/mandarin/static/style.css`} rel="stylesheet" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <StoreProvider store={store}>
        <HealthySnackbarProvider />
        <Toaster duration={6000} />
        <ConnectedRouter history={history}>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ConnectedRouter>
      </StoreProvider>
    </ThemeProvider>
  </ErrorBoundary>,
  document.getElementById('app'),
);
