import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  notification: null,
};

export const sliceKey = 'sharedNotifications';

const sharedNotificationsSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    triggerNotification(state, { payload }) {
      state.notification = payload.notification;
    },
  },
});

export const { triggerNotification } = sharedNotificationsSlice.actions;

export const { reducer } = sharedNotificationsSlice;
