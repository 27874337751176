import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clamp from 'lodash/clamp';
import isNull from 'lodash/isNull';
import cc from 'classcat';
import TextField from '@material-ui/core/TextField';

import useRandomString from '@shared/hooks/useRandomString';

import DescriptorLabel from '../DescriptorLabel';

import classes from './styles.scss';

export default function BaseTextField({
  type,
  id,
  name,
  label,
  descriptorState,
  descriptorState: { value, minValue, maxValue, info },
  settings: { underline = true, autofocus = false, placeholder } = {},
  onChange,
  onBlur,
  disabled = false,
  className,
}) {
  const [localValue, setLocalValue] = useState(value ?? '');

  useEffect(() => {
    setLocalValue(value ?? '');
  }, [descriptorState, value]);

  function onInputChange(e) {
    const newValue = e.target.value;
    setLocalValue(newValue.toString());

    if (onChange) {
      const isInitialState = Boolean(
        newValue === value || (isNull(newValue) && isNull(value)),
      );
      onChange({
        descriptorId: id,
        descriptorState: { value: newValue },
        isInitialState,
      });
    }
  }

  function validateInput(e) {
    let newValue = e.target.value;

    if (type === 'number' && newValue !== '') {
      const minVal = minValue ?? 0;
      const maxVal = maxValue ?? Number.MAX_SAFE_INTEGER;

      newValue = parseInt(newValue, 10); // clears leading zeros
      newValue = Math.abs(newValue); // makes number positive without losing value
      newValue = clamp(newValue, minVal, maxVal); // makes sure number is within boundary

      onInputChange({ target: { value: newValue } });
    }
  }

  const inputId = useRandomString();

  return (
    <TextField
      classes={{
        root: cc([classes.textField, className]),
      }}
      InputLabelProps={{
        htmlFor: `${name}-${inputId}`,
        shrink: false,
        classes: {
          root: classes.textLabel,
          focused: classes.focusedLabel,
          disabled: classes.disabled,
        },
        'data-testid': `${type}-descriptor-label`,
      }}
      inputProps={{
        id: `${name}-${inputId}`,
        'data-testid': `${type}-descriptor`,
        onBlur: validateInput,
        ...(Boolean(!!minValue || type === 'number') && { min: minValue ?? 0 }),
        ...(maxValue && { max: maxValue }),
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        classes: {
          root: cc([classes.textInput, { [classes.removeUnderline]: !underline }]),
          focused: classes.focusedInput,
          disabled: classes.disabled,
        },
      }}
      type={type}
      id={inputId}
      name={name}
      label={label && <DescriptorLabel label={label} info={info} />}
      margin="none"
      value={localValue}
      onChange={onInputChange}
      onBlur={onBlur}
      disabled={disabled}
      autoFocus={autofocus}
      placeholder={placeholder}
    />
  );
}

BaseTextField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  descriptorState: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    info: PropTypes.node,
  }).isRequired,
  settings: PropTypes.shape({
    underline: PropTypes.bool,
    autofocus: PropTypes.bool,
    placeholder: PropTypes.string,
  }),
  onChange: PropTypes.func,
  type: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
