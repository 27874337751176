import PropTypes from 'prop-types';
import cc from 'classcat';

import FormFieldRenderer from '../FormFieldRenderer';
import BrainBackdrop from '../BrainBackdrop';
import FieldLabel from '../FieldLabel';

import classes from './styles.scss';
import InputLabel from './InputLabel';

export default function FieldComponentsRenderer({
  fields = [],
  onChange,
  fetchOptions,
  getValue,
  disabled: groupDisabled = false,
  className,
}) {
  return (
    <div className={cc([classes.fields, className])}>
      {fields.map(
        (
          { name, label, disabled, type, info, isRequired, settings = {}, children = [] },
          index,
        ) =>
          type === 'group' ? (
            <BrainBackdrop
              key={index}
              classes={{ backdrop: classes.backdrop, body: classes.body }}
              data-testid="meta-descriptor"
              title={label && <FieldLabel label={label} info={info} />}
              withGrid={children.length > 0}
            >
              {children.map((child) => (
                <FormFieldRenderer
                  key={child.name}
                  type={child.type}
                  name={child.name}
                  data-testid={`form-component-${child.name}`}
                  label={
                    <InputLabel isOptional={isRequired === false} label={child.label} />
                  }
                  settings={child.settings}
                  info={child.info}
                  onChange={(childName, newValue) => {
                    onChange(childName, newValue);
                  }}
                  value={getValue && getValue(child.name)}
                  disabled={Boolean(child.disabled || groupDisabled)}
                  isRequired={isRequired}
                  fetchOptions={fetchOptions}
                />
              ))}
            </BrainBackdrop>
          ) : (
            <FormFieldRenderer
              key={index}
              type={type}
              name={name}
              data-testid={`form-component-${name}`}
              label={<InputLabel isOptional={isRequired === false} label={label} />}
              settings={settings}
              info={info}
              onChange={(fieldName, newValue) => {
                onChange(fieldName, newValue);
              }}
              value={getValue && getValue(name)}
              disabled={Boolean(disabled || groupDisabled)}
              isRequired={isRequired}
              fetchOptions={fetchOptions}
            />
          ),
      )}
    </div>
  );
}

FieldComponentsRenderer.propTypes = {
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  getValue: PropTypes.func,
  fetchOptions: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
