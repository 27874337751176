import { useState } from 'react';
import PropTypes from 'prop-types';

import TrashCanIcon from '@shared/components/Icons/TrashCanIcon';
import ConfirmationDialog from '@shared/components/BrainDialogs/ConfirmationDialog';

const TrashButton = ({
  onClick,
  shouldConfirm,
  confirmationText = 'Are you sure you want to delete this item?',
  className = '',
  tooltip = '',
}) => {
  const [showConfiramation, setShowConfirmation] = useState(false);
  const onTrashClicked = (e) => {
    e.stopPropagation();
    if (shouldConfirm) {
      setShowConfirmation(true);
    }
  };
  const onConfirm = (e) => {
    e.stopPropagation();
    setShowConfirmation(false);
    onClick();
  };
  const onCancel = (e) => {
    e.stopPropagation();
    setShowConfirmation(false);
  };
  return (
    <>
      <ConfirmationDialog
        isOpen={showConfiramation}
        onConfirm={onConfirm}
        onCancel={onCancel}
        title="Delete Item"
      >
        {confirmationText}
      </ConfirmationDialog>
      <div
        className={className}
        title={tooltip}
        aria-hidden="true"
        onClick={onTrashClicked}
      >
        <TrashCanIcon />
      </div>
    </>
  );
};
TrashButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  shouldConfirm: PropTypes.bool,
  confirmationText: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
};

export default TrashButton;
