export default function AddCopyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FFF" fillOpacity=".01" d="M0 0H38V38H0z" />
          <path
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth="1.6"
            d="M16.543 28.608c-4.426-.488-7.993-4.055-8.481-8.48-.309-2.788.543-5.463 2.396-7.533 1.655-1.848 3.96-2.983 6.414-3.18v9.582c0 .442.359.8.8.8h9.584c-.18 2.286-1.156 4.41-2.793 6.048-1.822 1.821-4.243 2.824-6.818 2.824-.366 0-.736-.02-1.102-.061z"
          />
          <path
            fill="currentColor"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth=".8"
            d="M19.572 16.04V8h0c4.703.373 8.467 4.137 8.84 8.84h-8.04c-.442 0-.8-.358-.8-.8z"
          />
        </g>
      </g>
    </svg>
  );
}
