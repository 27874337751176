import produce from 'immer';

import { defaultLocation } from './locoStructure';

/* eslint-disable no-param-reassign */
export const dbStoreToLocation = (dbStore) =>
  produce(defaultLocation, (draft) => {
    Object.keys(dbStore).forEach((key) => {
      if (key === 'schedulesByStoreId') {
        draft.openingHours = [];
        dbStore.schedulesByStoreId.forEach((sch) => {
          sch.open = sch.open.substr(0, 5); // Changing db's format of 09:00:00 to 09:00
          sch.close = sch.close.substr(0, 5);
          draft.openingHours.push(sch);
        });
        draft.openingHours.sort((sch1, sch2) => sch1.dayOfWeek - sch2.dayOfWeek); // Ordered 0...6
        draft.openingHours.push(draft.openingHours.shift()); // Now ordered 1...6,0
      } else if (key === 'storeProductsByStoreId') {
        draft.items = [];
        dbStore.storeProductsByStoreId.forEach((item) => {
          if (item.active) {
            draft.items.push({ value: item.productId, label: item.product.name });
          }
        });
      } else {
        draft[key] = dbStore[key];
      }
    });
  });
