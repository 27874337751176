// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "textarea.JKgMxVSn {\n  resize: none;\n  width: 100%;\n  color: var(--defaultBlack);\n  font-family: inherit;\n  font-size: 14px;\n  line-height: 22px;\n  letter-spacing: 0.1px;\n  padding: 10px 9px;\n  border-color: var(--greyText);\n  border-width: 1px;\n  border-style: inset;\n  box-shadow: inset -10px -10px 3px -10px var(--greyText);\n  outline: transparent;\n  background-color: var(--white)\n}\ntextarea.JKgMxVSn::placeholder {\n    color: var(--lightGrey);\n  }\ntextarea.JKgMxVSn:focus {\n    border-color: var(--brain-primary);\n  }\ntextarea.JKgMxVSn._1wSqBrcf {\n    border-color: var(--lightGrey);\n    box-shadow: none\n  }\ntextarea.JKgMxVSn._1wSqBrcf._1HwKB8p0 {\n      padding: 10px 0 0;\n      border-color: transparent;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "JKgMxVSn",
	"disabled": "_1wSqBrcf",
	"filled": "_1HwKB8p0"
};
export default ___CSS_LOADER_EXPORT___;
