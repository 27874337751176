// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.EbY7BEX- {\n  flex-direction: column-reverse;\n  display: flex;\n}\n\n  div.EbY7BEX- label._1jc_lm9T {\n    position: relative;\n    color: var(--defaultBlack);\n    font-size: 13px;\n    letter-spacing: 0.1px;\n    line-height: 16px;\n    transform: unset;\n    margin-top: 3px\n  }\n\n  div.EbY7BEX- label._1jc_lm9T._1m4zhH0y {\n      color: var(--lightGreyText);\n    }\n\n  div.EbY7BEX- label._1jc_lm9T._17J7hxnU {\n      color: var(--brain-primary);\n    }\n\n  div.EbY7BEX- div.sfRCQEoX {\n    color: var(--defaultBlack);\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    line-height: 17px;\n    margin: 0\n  }\n\n  div.EbY7BEX- div.sfRCQEoX::before,\n    div.EbY7BEX- div.sfRCQEoX:hover:not(.FO-B9CH-)::before,\n    div.EbY7BEX- div.sfRCQEoX:hover::before {\n      border-bottom: 1px solid var(--defaultBlack);\n    }\n\n  div.EbY7BEX- div.sfRCQEoX::after {\n      border-bottom: 1px solid var(--brain-primary);\n    }\n\n  div.EbY7BEX- div.sfRCQEoX._1m4zhH0y::before,\n      div.EbY7BEX- div.sfRCQEoX._1m4zhH0y:hover::before {\n        border-bottom: 1px solid var(--lightGrey);\n      }\n\n  div.EbY7BEX- div.sfRCQEoX._2WR0zVbp::before,\n    div.EbY7BEX- div.sfRCQEoX._2WR0zVbp:hover::before {\n      border-bottom: none;\n    }\n\n  div.EbY7BEX- div.sfRCQEoX._33eCb0hY {\n      color: var(--defaultBlack);\n    }\n\n  div.EbY7BEX- div.sfRCQEoX > input {\n      padding: 0 0 3px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textField": "EbY7BEX-",
	"textLabel": "_1jc_lm9T",
	"disabled": "_1m4zhH0y",
	"focusedLabel": "_17J7hxnU",
	"textInput": "sfRCQEoX",
	"Mui-disabled": "FO-B9CH-",
	"removeUnderline": "_2WR0zVbp",
	"focusedInput": "_33eCb0hY"
};
export default ___CSS_LOADER_EXPORT___;
