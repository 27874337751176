import { useState } from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import cc from 'classcat';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';

import { preParse } from '@shared/utils/dates';
import 'flatpickr/dist/themes/airbnb.css';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textField: {
    flexDirection: 'column-reverse',
  },
  dateLabel: {
    position: 'relative',
    color: 'var(--defaultBlack)',
    fontSize: 13,
    letterSpacing: 0.1,
    lineHeight: '16px',
    transform: 'unset',
    marginTop: 3,
    '&.Mui-disabled': {
      color: 'var(--lightGreyText)',
    },
  },
  focusedLabel: {
    color: 'var(--brain-primary) !important',
  },
  dateInput: {
    color: 'var(--defaultBlack)',
    fontSize: ({ fontSize }) => fontSize,
    letterSpacing: 0.1,
    lineHeight: 17,
    margin: '0 !important',
    '&::before, &:hover:not(.Mui-disabled)::before, &:hover::before': {
      borderBottom: '1px solid var(--defaultBlack)',
    },
    '&::after': {
      borderBottom: '1px solid var(--brain-primary)',
    },
    '& > input': {
      padding: '0 0 3px',
    },
    '&.Mui-disabled': {
      color: 'var(--defaultBlack)',
    },
    '&:hover.Mui-disabled::before, &.Mui-disabled::before': {
      borderBottom: '1px solid var(--lightGrey)',
    },
  },
  picker: {
    position: 'absolute',
    top: 'calc(100% + 5px)',
    left: 0,
    zIndex: 1,
    display: 'flex',
    padding: [[0, 0, 2]],
  },
  clearButton: {
    lineHeight: 'normal',
    padding: 0,
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    outline: 'none',
    transition: 'color 150ms',
    color: 'inherit',
    '& svg': {
      fill: 'currentColor',
    },
    '&:hover': { color: 'hsl(0,0%,60%)' },
  },
  calendarIcon: {
    cursor: 'pointer',
    display: 'inline-flex',
    padding: [[0, 1, 5]],
  },
  disabledCalendarIcon: {
    pointerEvents: 'none',
    color: 'var(--lightGrey)',
  },
}));

// eslint-disable-next-line react/prop-types
const ClearButton = ({ className, onClick }) => (
  <button onClick={onClick} className={className} type="button">
    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
    </svg>
  </button>
);

// eslint-disable-next-line react/prop-types
const CalendarIcon = ({ className }) => (
  <div className={className}>
    <svg width="12" height="14" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect fill="currentColor" y="2" width="12" height="12" rx="1" />
        <path fill="#FFF" d="M1 5h10v8H1z" />
        <path fill="currentColor" d="M2 0h1.3v3H2zM9 0h1.3v3H9z" />
      </g>
    </svg>
  </div>
);

const DateFilterBase = ({
  label,
  value = null,
  name,
  onChange,
  onClose,
  classArray = [],
  dateFormat = 'LLL do yyyy',
  fontSize = 14,
  placeholder = '',
  variant,
  disable = [],
  disabled = false,
  enableTime,
  isOpen = false,
}) => {
  const [pickerVisible, setPickerVisible] = useState(isOpen);
  const classes = useStyles({ fontSize });

  const togglePicker = () => setPickerVisible(!pickerVisible);
  const hidePicker = () => {
    setPickerVisible(false);
    if (onClose) {
      onClose();
    }
  };

  const handleChange = (newValue) => {
    const formattedValue = newValue[0] ? newValue[0].toISOString() : newValue[0];
    onChange({
      name,
      newValue: formattedValue,
    });
    if (newValue[0] && !enableTime) hidePicker();
  };

  const clearValue = () => handleChange([null]);

  const valueToDisplay = value ? format(preParse(value), dateFormat) : '';

  return (
    <ClickAwayListener onClickAway={hidePicker}>
      <div className={cc(classArray)} data-testid="date-filter">
        <div className={classes.wrapper}>
          <TextField
            className={classes.textField}
            label={label}
            value={valueToDisplay}
            fullWidth
            placeholder={placeholder}
            InputLabelProps={
              variant !== 'partners'
                ? {
                    shrink: false,
                    classes: {
                      root: classes.dateLabel,
                      focused: classes.focusedLabel,
                    },
                    'data-testid': 'date-field-label',
                  }
                : {}
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={togglePicker}>
                  {valueToDisplay && !disabled && (
                    <ClearButton onClick={clearValue} className={classes.clearButton} />
                  )}
                  <CalendarIcon
                    className={cc([
                      classes.calendarIcon,
                      { [classes.disabledCalendarIcon]: disabled },
                    ])}
                  />
                </InputAdornment>
              ),
              classes: variant !== 'partners' ? { root: classes.dateInput } : {},
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              onClick: togglePicker,
              readOnly: true,
            }}
            disabled={disabled}
          />

          {pickerVisible && (
            <Paper className={classes.picker}>
              <Flatpickr
                value={value}
                options={{
                  inline: true,
                  clickOpens: false,
                  enableTime,
                  disable,
                }}
                onChange={handleChange}
              />
            </Paper>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

DateFilterBase.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  // Value isn't required because it can be null or undefined
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  classArray: PropTypes.array,
  dateFormat: PropTypes.string,
  fontSize: PropTypes.number,
  placeholder: PropTypes.string,
  variant: PropTypes.string.isRequired,
  disable: PropTypes.array,
  disabled: PropTypes.bool,
  enableTime: PropTypes.bool,
  isOpen: PropTypes.bool,
};

export default DateFilterBase;
