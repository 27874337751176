import produce from 'immer';

import RemoveFileIcon from '@shared/components/BrainFileUploader/icon-components/RemoveFileIcon';

import UploadCloudIcon from './icon-components/UploadCloudIcon';
import AddFileIcon from './icon-components/AddFileIcon';
import VIcon from './icon-components/VIcon';

export const initialState = {
  hasFile: false,
  isPending: false,
  isHovering: false,
  textContent: 'Drop file or Browse',
  infoTextContent: '',
  Icon: UploadCloudIcon,
  isModalShown: false,
  file: null,
};

/* eslint-disable default-case, no-param-reassign */
export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_FILE':
        draft.hasFile = true;
        draft.isPending = false;
        draft.startEmpty = false;
        draft.textContent = action.fileName;
        draft.infoTextContent = `Uploaded by ${action.username} ${action.timeAgo}`;
        draft.Icon = VIcon;
        break;
      case 'UNSET_FILE':
        Object.keys(initialState).forEach((k) => {
          draft[k] = initialState[k];
        });
        draft.startEmpty = true;
        draft.textContent = action.textContent;
        break;
      case 'HOVER_START':
        draft.isHovering = true;
        break;
      case 'HOVER_END':
        draft.isHovering = false;
        break;
      case 'UPLOADING':
        draft.hasFile = false;
        draft.isPending = true;
        draft.textContent = `${action.fileName}`;
        draft.infoTextContent = 'File ready, save to approve';
        draft.Icon = AddFileIcon;
        break;
      case 'DELETING':
        draft.hasFile = false;
        draft.isPending = true;
        draft.textContent = `${action.fileName}`;
        draft.infoTextContent = 'File removed, save to approve';
        draft.Icon = RemoveFileIcon;
        break;
      case 'OPEN':
        draft.isModalShown = true;
        break;
      case 'CLOSE':
        draft.isModalShown = false;
        break;
      case 'REPLACE':
        draft.file = action.file;
        break;
      case 'DELETE':
        draft.file = null;
        break;
    }
  });
