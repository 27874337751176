const mockResponse = (url, body, status = 200) =>
  new Promise((resolve) => {
    const response = new Response(JSON.stringify(body), {
      status,
      headers: {
        'Content-type': 'application/json',
      },
    });
    console.log(`Mocked: ${url}`); // eslint-disable-line no-console
    console.log(body); // eslint-disable-line no-console
    setTimeout(() => resolve(response), 500);
  });

export const mockFetch = (oldFetch) => (request, params) => {
  let url;
  let method;
  if (params) {
    url = request;
    method = params.method ?? 'GET';
  } else {
    url = request.url;
    method = request.method;
  }

  switch (true) {
    case method === 'POST' &&
      url.startsWith('https://www.googleapis.com/upload/drive/v3/files'):
      return mockResponse(url, { id: 'a-mocked-id' });
    default:
      return oldFetch(request, params);
  }
};

export default mockFetch;
