const authorizationHeaders = new Headers();
/* from https://developer.atlassian.com/cloud/jira/platform/jira-rest-api-basic-authentication/ */
authorizationHeaders.append(
  'Authorization',
  `Basic ${process.env.MECOLORBOARD.AUTH_TOKEN}`,
);

const headersWithJson = new Headers();
/* from https://developer.atlassian.com/cloud/jira/platform/jira-rest-api-basic-authentication/ */
headersWithJson.append('Authorization', `Basic ${process.env.MECOLORBOARD.AUTH_TOKEN}`);
headersWithJson.append('Content-Type', 'application/json');

const mecolorboardRequest = async (endpoint, options) => {
  const response = await fetch(
    `${process.env.MECOLORBOARD.REST_API_URL}${endpoint}`,
    options,
  );
  const result = await response.json();
  if (response.ok) {
    return result;
  }
  console.error(result);
  throw new Error(result.message ?? 'Something went wrong');
};

const fieldNames = ['lot', 'amount', 'product', 'printerName', 'printerMark', 'userName'];

export const createSheetsRequest = (formData) => {
  const formattedForm = new FormData();
  formattedForm.append('original-sheet', formData.file);
  fieldNames.forEach((fieldName) => formattedForm.append(fieldName, formData[fieldName]));

  const options = {
    method: 'POST',
    body: formattedForm,
    headers: authorizationHeaders,
  };
  return mecolorboardRequest('/sheets', options);
};

export const filterLots = ({
  perPage = 50,
  currentPage = 0,
  colorboardProductType,
  search,
}) => {
  if (!colorboardProductType) {
    return null;
  }
  const options = {
    method: 'POST',
    headers: headersWithJson,
    body: JSON.stringify({
      perPage,
      currentPage,
      colorboardProductType,
      search,
    }),
  };
  return mecolorboardRequest('/lots/filter', options);
};

export const getColorboardTypes = () => {
  const options = {
    method: 'GET',
    headers: authorizationHeaders,
  };
  return mecolorboardRequest('/colorboard-types', options);
};

export const createLotRequest = (formData) => {
  const options = {
    method: 'POST',
    headers: headersWithJson,
    body: JSON.stringify(formData),
  };
  return mecolorboardRequest('/lots', options);
};

export const filterKitLots = ({ perPage = 50, currentPage = 0, filters }) => {
  const options = {
    method: 'POST',
    headers: headersWithJson,
    body: JSON.stringify({ perPage, currentPage, filters }),
  };
  return mecolorboardRequest(`/kit-lots/filter`, options);
};

export const createKitLotRequest = (payload) => {
  const options = {
    method: 'POST',
    headers: headersWithJson,
    body: JSON.stringify(payload),
  };
  return mecolorboardRequest('/kit-lots', options);
};

export const updateKitLotRequest = ({ kitLotId, payload }) => {
  const options = {
    method: 'PATCH',
    headers: headersWithJson,
    body: JSON.stringify(payload),
  };
  return mecolorboardRequest(`/kit-lots/${kitLotId}`, options);
};

export const deleteKitLotRequest = (payload) => {
  const options = {
    method: 'DELETE',
    headers: headersWithJson,
    body: JSON.stringify(payload),
  };
  return mecolorboardRequest(`/kit-lots`, options);
};

export const getLot = (id) => {
  const options = {
    method: 'GET',
    headers: authorizationHeaders,
  };
  return mecolorboardRequest(`/lots/${id}`, options);
};

export const getScan = (id) => {
  const options = {
    method: 'GET',
    headers: authorizationHeaders,
  };
  return mecolorboardRequest(`/scans/${id}`, options);
};

const scanFieldNames = ['colorboardType', 'lotName', 'lotId', 'uploaderName', 'rescan'];

export const createScan = (formData) => {
  const formattedForm = new FormData();
  formattedForm.append('scan-file', formData.file);
  scanFieldNames.forEach((fieldName) =>
    formattedForm.append(fieldName, formData[fieldName]),
  );

  const options = {
    method: 'POST',
    headers: authorizationHeaders,
    body: formattedForm,
  };
  return mecolorboardRequest('/scans', options);
};

export const filterLotScans = ({ lotId, perPage, currentPage, status }) => {
  const options = {
    method: 'POST',
    headers: headersWithJson,
    body: JSON.stringify({ perPage, currentPage, status }),
  };
  return mecolorboardRequest(`/lots/${lotId}/scans`, options);
};

export const updateColorboardRequest = ({ encodedId, updatedData }) => {
  const options = {
    method: 'POST',
    headers: headersWithJson,
    body: JSON.stringify(updatedData),
  };
  return mecolorboardRequest(`/color-boards/${encodedId}`, options);
};

export const updateLotRequest = ({ id, updatedData }) => {
  const options = {
    method: 'POST',
    headers: headersWithJson,
    body: JSON.stringify(updatedData),
  };
  return mecolorboardRequest(`/lots/${id}`, options);
};

export const createQrs = async (formData) => {
  const url = `${process.env.MECOLORBOARD.REST_API_URL}/qr-codes`;
  const options = {
    method: 'POST',
    headers: headersWithJson,
    body: JSON.stringify(formData),
  };
  const response = await fetch(url, options);
  const readableResponse = await response.text();
  if (response.status >= 200 && response.status < 300) {
    return readableResponse;
  }
  // so we can inform about 400, 401 status codes that don't throw an error
  throw new Error(readableResponse.error);
};

export const getColorboardByEncodedId = ({ encodedId }) => {
  const options = {
    method: 'GET',
    headers: authorizationHeaders,
  };
  return mecolorboardRequest(`/color-boards/${encodedId}`, options);
};

export const fetchFlows = () => {
  const options = {
    method: 'GET',
    headers: authorizationHeaders,
  };
  return mecolorboardRequest('/flows', options);
};

export const createCorrectionRequest = (correctionData) => {
  const options = {
    method: 'POST',
    headers: headersWithJson,
    body: JSON.stringify(correctionData),
  };
  return mecolorboardRequest('/lot-correction', options);
};
