import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import BrainButton from '@shared/components/BrainButton';

export default function ButtonRenderer({ buttons = [] }) {
  return (
    <>
      {buttons.map(
        ({ label, onClickCallback, to, classNames = [], ...otherButtonProps }) => {
          // a button should either have an onClickCallback or a be a link to somewhere
          const actionProps = {
            ...(onClickCallback && {
              onClick: onClickCallback,
            }),
            ...(to && { component: Link, to }),
          };

          return (
            <BrainButton
              key={label}
              classNames={classNames}
              {...actionProps}
              {...otherButtonProps}
            >
              {label}
            </BrainButton>
          );
        },
      )}
    </>
  );
}

export const buttonsShape = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClickCallback: PropTypes.func.isRequired,
      classNames: PropTypes.array,
    }),
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      classNames: PropTypes.array,
    }),
  ]),
);

ButtonRenderer.propTypes = {
  buttons: buttonsShape,
};
