import { useState, useEffect } from 'react';

/*
 * useNetworkStateHelper
 *
 * The network state helper helps coordinate 3 possible network states (loading, error, empty)
 * and provide a component for them.
 *
 * Inputs the hook expects:
 *  - 3 boolean values, one for each state: loading, error, empty. All states are not required, only one can be used.
 *  - 3 functions that return components which will be rendered for each state when it's true
 *    If no component functions are supplied, default components will be used
 *  - Dependencies: an optional array, see below.
 *
 * The hook returns:
 *  - `showFallback` - `true` when at least one of the states true
 *  - `fallbackComponent` - The right component to render for the current state
 *
 * Dependencies:
 * The `dependencies` array holds a list of props that can trigger re-rendering of the components returned by the hook.
 * This is useful for components that require props - once a prop in the `dependencies` array changes,
 * the component returned in `fallbackComponent` is updated to reflect the changes.
 * PLEASE NOTE: If a component has props, all props that can change should be passed to the `dependencies` array or the component will not work as expected.
 *
 * Example:
  const { showFallback, fallbackComponent } = useNetworkStateHelper(
    {
      loading: isLoading,
      loadingComponent: () => <LoadingMode loadingText={loadingText} />,
     },
      [loadingText],
    );
 *
 */

export function DefaultLoader() {
  return <div>Loading data...</div>;
}

export function DefaultError() {
  return <div>Something went wrong</div>;
}

export function DefaultEmpty() {
  return <div>No data to show</div>;
}

function useNetworkStateHelper(
  {
    loading = false,
    error = false,
    empty = false,
    loadingComponent: LoadingComponent = DefaultLoader,
    errorComponent: ErrorComponent = DefaultError,
    emptyComponent: EmptyComponent = DefaultEmpty,
  },
  dependencies = [],
) {
  const [showFallback, setShowFallback] = useState(Boolean(loading || error || empty));
  const [fallbackComponent, setFallbackComponent] = useState(null);

  useEffect(() => {
    setShowFallback(Boolean(loading || error || empty));
    if (loading) setFallbackComponent(<LoadingComponent />);
    else if (error) setFallbackComponent(<ErrorComponent />);
    else if (empty) setFallbackComponent(<EmptyComponent />);
  }, [loading, error, empty, ...dependencies]);

  return { showFallback, fallbackComponent };
}

export default useNetworkStateHelper;
