import { useCallback, useState, useEffect } from 'react';
import cc from 'classcat';

import { useLocalStorage } from '@shared/hooks/useLocalStorage';

import BrainTitle from './components/BrainTitle';
import UserDetails from './components/UserDetails';
import NavTree from './components/NavTree';
import SidebarFooter from './components/SidebarFooter';
import classes from './index.scss';

let timeout;
const TRANSITION_DELAY = 150;

export default function SideBar() {
  const [isPinned, setIsPinned] = useLocalStorage('sidebarIsPinned', true);
  const [isHovered, setIsHovered] = useState(false);

  const toggleSidebarPinning = useCallback(() => {
    setIsPinned(!isPinned);
  }, [isPinned]);

  const expandSidebar = useCallback(() => {
    if (isPinned) return;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setIsHovered(true);
    }, TRANSITION_DELAY);
  }, [isPinned]);

  const collapseSidebar = useCallback(() => {
    if (isPinned) return;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setIsHovered(false);
    }, TRANSITION_DELAY);
  }, [isPinned]);

  useEffect(() => {
    // When the side bar becomes pinned/unpinned, update a CSS var to tell the DrawerSidebar how to position itself
    document.documentElement.style.setProperty(
      '--currentSideBarWidth',
      isPinned ? 'var(--expandedSideBarWidth)' : 'var(--sideBarWidth)',
    );
  }, [isPinned]);

  const isExpanded = Boolean(isHovered || isPinned);

  return (
    <>
      <div
        className={cc([
          classes.sidebar,
          {
            [classes.expanded]: isExpanded,
          },
        ])}
        onMouseEnter={expandSidebar}
        onMouseLeave={collapseSidebar}
      >
        <div className={classes.inside}>
          <BrainTitle isPinned={isPinned} toggleSidebarPinning={toggleSidebarPinning} />
          <UserDetails isExpanded={isExpanded} />
          <NavTree isExpanded={isExpanded} />
          <SidebarFooter isExpanded={isExpanded} collapseSidebar={collapseSidebar} />
        </div>
      </div>
      <div
        className={cc([
          classes.ghost,
          {
            [classes.pinned]: isPinned,
          },
        ])}
      />
    </>
  );
}
