// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[dir=\"ltr\"] label._3H6GBcHG {\n  margin-left: 5px;\n}\n[dir=\"rtl\"] label._3H6GBcHG {\n  margin-right: 5px;\n}\nlabel._3H6GBcHG {\n  display: flex;\n  align-items: center;\n  margin-left: 5px;\n  margin-right: 0;\n  margin-top: 10px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  margin-bottom: 10px;\n}\nlabel._3H6GBcHG [dir=\"ltr\"] .bir54jwV {\n    margin-left: 10px;\n}\nlabel._3H6GBcHG [dir=\"rtl\"] .bir54jwV {\n    margin-right: 10px;\n}\nlabel._3H6GBcHG .bir54jwV {\n    color: var(--defaultBlack);\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    line-height: 14px;\n    margin-left: 10px;\n  }\nlabel._3H6GBcHG ._1d_Bo_dk {\n    padding: 0;\n  }\nlabel._3H6GBcHG ._1d_Bo_dk ._1zvqF6yE {\n      height: 18px;\n      width: 18px;\n      border: solid 1px var(--greyText);\n      border-radius: 3px;\n    }\nlabel._3H6GBcHG ._1d_Bo_dk:hover,\n    label._3H6GBcHG ._1d_Bo_dk._7Sfm0J-p {\n      color: var(--brain-primary, var(--greyText));\n      background-color: transparent;\n    }\nlabel._3H6GBcHG ._1d_Bo_dk:hover ._1zvqF6yE, label._3H6GBcHG ._1d_Bo_dk._7Sfm0J-p ._1zvqF6yE {\n        border: solid 2px var(--brain-primary, var(--greyText));\n      }\nlabel._3H6GBcHG:hover ._1zvqF6yE {\n    border: solid 2px var(--brain-primary, var(--greyText));\n  }\nlabel._3H6GBcHG._2Gnj8Xyo {\n    pointer-events: none;\n  }\nlabel._3H6GBcHG._2Gnj8Xyo .bir54jwV {\n      color: var(--lightGreyText);\n    }\nlabel._3H6GBcHG._2Gnj8Xyo ._1d_Bo_dk ._1zvqF6yE {\n        color: var(--lightGreyText);\n        background-color: var(--lightGrey);\n        border-color: var(--lightGrey);\n      }\nlabel._3H6GBcHG._2Gnj8Xyo ._1d_Bo_dk._7Sfm0J-p ._1zvqF6yE {\n          background-color: var(--white);\n          border-color: var(--lightGrey);\n          color: var(--lightGrey);\n        }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brain-checkbox": "_3H6GBcHG",
	"label": "bir54jwV",
	"checkbox-btn": "_1d_Bo_dk",
	"checkbox-icon": "_1zvqF6yE",
	"checked": "_7Sfm0J-p",
	"disabled": "_2Gnj8Xyo"
};
export default ___CSS_LOADER_EXPORT___;
