import PropTypes from 'prop-types';

import UserLabel from '../UserLabel';

import classes from './styles.scss';

export default function SingleValue({
  children,
  selectProps: { isUser, options },
  data: { value },
}) {
  return (
    <div className={classes['single-value']}>
      {isUser ? <UserLabel value={value} options={options} /> : children}
    </div>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object,
  selectProps: PropTypes.PropTypes.shape({
    isUser: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
  }).isRequired,
};
