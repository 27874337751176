import PropTypes from 'prop-types';
import cc from 'classcat';

import BrainTooltip from '@shared/components/BrainTooltip';

import BrainTooltipTriggerIcon from './BrainTooltipTriggerIcon';
import classes from './styles.scss';

export default function BrainTooltipTrigger({
  title,
  placement = 'left',
  className = '',
  ...otherProps
}) {
  return (
    <BrainTooltip placement={placement} title={title}>
      <div className={cc([classes['brain-tooltip-trigger'], className])} {...otherProps}>
        <BrainTooltipTriggerIcon />
      </div>
    </BrainTooltip>
  );
}

BrainTooltipTrigger.propTypes = {
  title: PropTypes.node.isRequired,
  placement: PropTypes.PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'left-start',
    'left',
    'left-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
  ]),
  className: PropTypes.string,
};
