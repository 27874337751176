import * as Sentry from '@sentry/browser';

const isProd = () =>
  process.env.NODE_ENV === 'production' && process.env.HEALTHY_ENV?.includes('prod');

/**
 * @see
 * [Sentry: Configuration](https://docs.sentry.io/clients/javascript/config/)
 *
 * @param {object} options
 * @param {string} options.dsn - Associated Sentry SDK
 * @param {(Array<string>|Array<RegExp>)} options.ignoreErrors - A list of messages to be filtered out before being sent to Sentry
 */
function init({ dsn, ignoreErrors }) {
  const shouldReportToSentry = Boolean(dsn);

  if (shouldReportToSentry) {
    Sentry.init({
      dsn,
      ignoreErrors,
      release: `${process.env.APP_VARIANT}-${process.env.APP_VERSION}`,
      environment: process.env.HEALTHY_ENV,
      attachStacktrace: true,
    });
    Sentry.configureScope((scope) => {
      scope.setTag('App name', process.env.APP_VARIANT);
      scope.setExtra('App version', process.env.APP_VERSION);
    });
  }
}

/**
 * Register a set of super properties (tags), which are included with all exceptions.
 *
 * @see
 * [Sentry: Customize Tags](https://docs.sentry.io/platforms/javascript/enriching-events/tags/)
 *
 * @param {object} tags
 */
function setTags(tags) {
  Object.entries(tags).forEach(([key, value]) => {
    Sentry.setTag(key, value);
  });
}

/**
 *
 * @see
 * [Sentry: Set level](https://docs.sentry.io/platforms/javascript/usage/set-level/)
 *
 */
function captureException({ level, exception, additionalInfo = null }) {
  const shouldReportToSentry = Boolean(process.env.SENTRY_DSN);
  const notProd = !isProd();

  if (shouldReportToSentry) {
    if (additionalInfo) {
      Sentry.setExtra('Additional Information', JSON.stringify(additionalInfo, null, 2));
    }

    if (exception?.meta) {
      Sentry.setExtra('Metadata', exception.meta);
    }

    if (level === 'error') {
      Sentry.captureException(exception);
    } else {
      Sentry.withScope((scope) => {
        scope.setLevel(level);
        Sentry.captureException(exception);
      });
    }
  }

  if (notProd) {
    // eslint-disable-next-line no-console
    console.error(exception, additionalInfo);
  }
}

function error(err, additionalInfo) {
  captureException({
    level: 'error',
    exception: err,
    additionalInfo,
  });
}

function warning(warningText, additionalInfo) {
  captureException({
    level: 'warning',
    exception: new Error(warningText),
    additionalInfo,
  });
}

function info(infoText, additionalInfo) {
  captureException({
    level: 'info',
    exception: new Error(infoText),
    additionalInfo,
  });
}

const reporter = { init, error, warning, info, setTags };

export default reporter;
