import { Route } from 'react-router-dom';

import UsersList from 'containers/UserManagement/UsersList/Loadable';
import UserPage from 'containers/UserManagement/UserPage/Loadable';

import PermissionRoute from 'components/PermissionRoute';

const UserManagementRoutes = (domain) => [
  <PermissionRoute
    key="um0"
    exact
    path={`/users/:productDomain(${domain})`}
    component={UsersList}
    scope={`${domain}-user:list`}
  />,
  <PermissionRoute
    exact
    key="um1"
    path={`/user/:productDomain(${domain})/new`}
    component={UserPage}
    scope={`${domain}-user:list`}
  />,
  <PermissionRoute
    exact
    key="um2"
    path={`/user/:productDomain(${domain})/:userId`}
    component={UserPage}
    scope={`${domain}-user:list`}
  />,
  <Route exact key="um3" path="/users" component={UsersList} />,
];

export default UserManagementRoutes;
