export default function PushPinIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <g fill="none" fillRule="evenodd">
        <g>
          <path d="M0 0H16.8V16.8H0z" transform="rotate(30 3.036 17.964)" />
          <path
            fill="currentColor"
            fillRule="nonzero"
            stroke="currentColor"
            strokeWidth=".5"
            d="M9.8 2.8v3.5c0 .784.259 1.512.7 2.1H6.3c.455-.602.7-1.33.7-2.1V2.8h2.8m2.1-1.4h-7c-.385 0-.7.315-.7.7 0 .385.315.7.7.7 0 0 0 0 0 0h.7v3.5c0 1.162-.938 2.1-2.1 2.1v1.4h4.179v4.9l.7.7.7-.7V9.8H13.3V8.4s0 0 0 0c-1.162 0-2.1-.938-2.1-2.1V2.8h.7s0 0 0 0c.385 0 .7-.315.7-.7 0-.385-.315-.7-.7-.7h0z"
            transform="rotate(30 3.036 17.964)"
          />
        </g>
      </g>
    </svg>
  );
}
