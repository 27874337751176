import PropTypes from 'prop-types';

import BrainButton from '@shared/components/BrainButton';

import GoogleDriveLogo from './GoogleDriveLogo';
import classes from './styles.scss';

export default function GoogleDriveLink({ gDriveFolderId }) {
  return (
    <BrainButton
      variant="text"
      classNames={[classes['google-drive-link']]}
      iconComponent={<GoogleDriveLogo />}
      target="_blank"
      href={`https://drive.google.com/drive/u/0/folders/${gDriveFolderId}`}
    >
      View In Google Drive
    </BrainButton>
  );
}

GoogleDriveLink.propTypes = {
  gDriveFolderId: PropTypes.string.isRequired,
};
