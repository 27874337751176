import copy from 'clipboard-copy';

import { toast } from '@shared/components/Toaster';

function useCopyToClipboard() {
  const onCopy = (toCopy) => {
    copy(toCopy);
    toast.info('Copied to clipboard');
  };
  return [onCopy];
}

export default useCopyToClipboard;
