import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cc from 'classcat';

import getRandomString from '@shared/utils/getRandomString';

import DropdownIndicator from './DropdownIndicator';
import classes from './styles.scss';

const textStyling = () => ({
  color: 'var(--defaultBlack)',
  fontSize: 14,
  letterSpacing: 0.1,
  lineHeight: '17px',
});

/* eslint-disable no-nested-ternary */
export default function BrainStyledSingleSelect({
  label,
  value = null,
  onChange,
  options,
  fetchOptions,
  disabled = false,
  ...otherProps
}) {
  // ------- Scrolling long menus to the needed options -----
  // - https://github.com/JedWatson/react-select/issues/3648#issuecomment-592622713
  const selectRef = useRef(null);
  // Feature of focusing selected option when menu is getting opened
  const onMenuOpen = useCallback(() => {
    // Getting a selected option
    const option = selectRef.current?.select?.state?.selectValue?.[0];
    if (option) {
      setTimeout(() => {
        // Setting a focused value as a selected one
        // References:
        // - https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/Select.js#L503
        // - https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/Select.js#L802
        if (selectRef.current?.select) {
          const selectedIndex =
            selectRef.current.select.state.menuOptions.focusable.findIndex(
              (op) => op.value === option.value,
            );
          if (selectedIndex >= 0) {
            // Focusing selected option only if it exists
            selectRef.current.select.scrollToFocusedOptionOnUpdate = true;
            selectRef.current.select.inputIsHiddenAfterUpdate = false;
            selectRef.current.select.setState({
              focusedValue: null,
              focusedOption:
                selectRef.current.select.state.menuOptions.focusable[selectedIndex],
            });
          }
        }
      });
    }
  }, [selectRef.current]);

  const inputId = getRandomString();

  return (
    <div className={classes['select-field']} {...otherProps}>
      <Select
        ref={selectRef}
        value={value}
        onChange={onChange}
        options={options}
        isClearable={false}
        placeholder=""
        name={inputId}
        inputId={inputId}
        onMenuOpen={onMenuOpen}
        components={{
          DropdownIndicator,
        }}
        styles={{
          valueContainer: () => ({
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
          }),
          control: (styles, { isFocused, isDisabled }) => ({
            borderWidth: '0 0 1px 0',
            height: 38,
            borderColor: isDisabled
              ? 'var(--lightGrey)'
              : isFocused
              ? 'var(--brain-primary)'
              : 'var(--defaultBlack)',
            borderStyle: 'solid',
            borderRadius: 0,
            display: 'flex',
            cursor: isDisabled ? 'not-allowed' : 'default',
          }),
          indicatorSeparator: () => ({
            backgroundColor: 'var(--veryLightGrey)',
            width: 1,
            height: 24,
          }),
          singleValue: (styles, { data }) => ({
            ...textStyling(),
            ...data?.style,
          }),
          input: textStyling,
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? 'var(--ultraLightGrey)'
              : isFocused
              ? 'var(--veryLightGrey)'
              : null,
            color: isDisabled
              ? 'var(--greyText)'
              : isSelected
              ? 'var(--defaultBlack)'
              : 'var(--defaultBlack)',
            cursor: isDisabled ? 'not-allowed' : 'default',
            ...data?.style,

            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled && 'var(--lightGrey)',
            },
            fontSize: 14,
            margin: 0,
            letterSpacing: 0.1,
            lineHeight: '32px',
            height: 48,
          }),
        }}
        isDisabled={disabled}
      />
      <label
        htmlFor={inputId}
        className={cc([classes.textLabel, { [classes.disabled]: disabled }])}
      >
        {label}
      </label>
    </div>
  );
}

BrainStyledSingleSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      style: PropTypes.object,
    }),
  ).isRequired,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fetchOptions: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
    ),
  ]),
};
