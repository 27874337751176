import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import BrainTooltipTrigger from '@shared/components/BrainTooltipTrigger';

import Linked from './linked.svg';
import classes from './styles.scss';

const hasEllipsis = (element) => element && element.offsetWidth < element.scrollWidth;

const LINK_INFO =
  'The content of a linked data is restricted for viewing only. Press the `Linked` button to redirect to the original page for editing capabilities';

export default function DescriptorLabel({ label, info, link }) {
  const [labelTitle, setLabelTitle] = useState(null);
  const labelRef = useRef(null);

  const descriptorInfo = link ? LINK_INFO : info;

  useEffect(() => {
    setLabelTitle(hasEllipsis(labelRef.current) ? labelRef.current.innerText : null);
  }, [labelRef]);

  return (
    <div className={classes['descriptor-label']}>
      <span className={classes.label} title={labelTitle} ref={labelRef}>
        {label}
      </span>
      {link && (
        <Link to={link} className={classes.link}>
          <img src={Linked} alt="linked" />
          Linked
        </Link>
      )}
      {descriptorInfo && (
        <BrainTooltipTrigger
          title={descriptorInfo}
          className={classes['descriptor-label-tooltip']}
        />
      )}
    </div>
  );
}

DescriptorLabel.propTypes = {
  label: PropTypes.node.isRequired,
  info: PropTypes.node,
  link: PropTypes.string,
};
