export default function XIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="10" height="10">
      <path
        d="M1 9l8-8M1 1l8 8"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
}
