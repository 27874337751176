/*
 *
 * App actions
 *
 */

export const UPDATE_USER_DATA = 'app/Auth/UPDATE_USER_DATA';
export const CLEAR_USER_DATA = 'app/Auth/CLEAR_USER_DATA';

export function updateUserData(userData) {
  return {
    type: UPDATE_USER_DATA,
    userData,
  };
}

export function clearUserData() {
  return {
    type: CLEAR_USER_DATA,
  };
}
