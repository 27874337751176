import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import FormLabel from '@material-ui/core/FormLabel';

import BrainCheckbox from '@shared/components/BrainCheckbox';
import FieldLabel from '@shared/components/BrainForm/FieldLabel';
import BrainStyledSingleSelect from '@shared/components/BrainStyledSingleSelect';

import classes from './styles.scss';

const times = [...Array((60 * 24) / 15).keys()].map((item, index) => index * 15);
const options = times.map((time) => {
  const textTime = `${`${Math.floor(time / 60)}`.padStart(2, '0')}:${`${
    time % 60
  }`.padStart(2, '0')}`;
  return { label: textTime, value: textTime };
});

export default function OpeningHoursField({
  value,
  label,
  onChange,
  info,
  disabled = false,
  ...otherProps
}) {
  const [open, setOpen] = useState('');
  const [close, setClose] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    setOpen(value?.open ?? options[0].value);
    setClose(value?.close ?? options[options.length - 1].value);
    setActive(value?.active ?? true);
  }, [value]);

  const onOpenChange = (newTime) => {
    setOpen(newTime.value);
    onChange({
      dayOfWeek: value?.dayOfWeek,
      open: newTime.value,
      close,
      active,
    });
  };
  const onCloseChange = (newTime) => {
    setClose(newTime.value);
    onChange({
      dayOfWeek: value?.dayOfWeek,
      open,
      close: newTime.value,
      active,
    });
  };

  const onActiveChange = (e, checked) => {
    setActive(checked);
    onChange({
      dayOfWeek: value?.dayOfWeek,
      open,
      close,
      active: checked,
    });
  };

  return (
    <div {...otherProps} className={classes.openingHours}>
      <FormLabel classes={{ root: classes.label }}>
        <BrainCheckbox
          onChange={onActiveChange}
          disabled={disabled}
          checked={active}
          value="active"
        />
        <FieldLabel label={label} info={info} />
      </FormLabel>
      <div className={classes.separator} />
      <div className={cc([classes.toFrom, { [classes.inactive]: !active }])}>
        <BrainStyledSingleSelect
          onChange={onOpenChange}
          data-testid="opening-hours-select-open"
          disabled={Boolean(!active || disabled)}
          label="From"
          options={options}
          value={{ label: open, value: open }}
        />
        <BrainStyledSingleSelect
          onChange={onCloseChange}
          data-testid="opening-hours-select-close"
          disabled={Boolean(!active || disabled)}
          label="To"
          options={options}
          value={{ label: close, value: close }}
        />
      </div>
    </div>
  );
}

OpeningHoursField.propTypes = {
  value: PropTypes.shape({
    dayOfWeek: PropTypes.number.isRequired,
    open: PropTypes.string.isRequired,
    close: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  info: PropTypes.string,
};
