/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';

const toggleRowCss = ({ togglePosition }) => css`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  color: var(--defaultBlack);
  align-items: center;
  width: 100%;
  height: max-content;
  font-size: 13px;
  padding: 15px;
  svg {
    font-size: 1.1em;
  }

  ${togglePosition === 'right' && 'flex-direction: row-reverse;'}
  &:hover {
    color: var(--brain-primary);
  }
`;

const expandButtonCss = ({ isExpanded }) => css`
  transition: 300ms;
  margin-right: 7px;

  ${isExpanded && 'transform: rotate(180deg);'}
`;
const hoverableCss = css`
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
`;

const labelCss = css`
  width: 100%;
  margin-left: 20px;
`;
const collapsibleCss = ({ hoverable }) => css`
  background-color: white;
  border-bottom: 1px solid var(--veryLightGrey);
  ${hoverable && hoverableCss}
  font-size: 14px;
`;

const BrainCollapsible = ({
  children,
  label,
  isExpanded = true,
  onToggleExpanded = () => {},
  togglePosition = 'left',
  hoverable,
  isExpandMore = true,
}) => {
  const [localExpanded, setLocalExpanded] = useState(isExpanded);

  const onLocalExpandedChange = () => {
    const newExpanded = !localExpanded;
    setLocalExpanded(newExpanded);
    onToggleExpanded(newExpanded);
  };

  return (
    <div css={collapsibleCss({ hoverable })}>
      {isExpandMore && (
        <div
          variant="text"
          onClick={onLocalExpandedChange}
          data-testid="collapsible-expand-btn"
          css={toggleRowCss({ togglePosition })}
        >
          <span css={labelCss}>{label}</span>

          <ExpandMore css={expandButtonCss({ isExpanded: localExpanded })} />
        </div>
      )}
      <Collapse in={localExpanded}>{children}</Collapse>
    </div>
  );
};

BrainCollapsible.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isExpanded: PropTypes.bool,
  isExpandMore: PropTypes.bool,
  onToggleExpanded: PropTypes.func,
  hoverable: PropTypes.bool,
  togglePosition: PropTypes.oneOf(['right', 'left']),
};
export default BrainCollapsible;
