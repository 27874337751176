import PropTypes from 'prop-types';

import logo from 'images/side-bar/logo.svg';
import PushPinIcon from 'images/icon-components/PushPinIcon';

import classes from './BrainTitle.scss';

export default function BrainTitle({ isPinned, toggleSidebarPinning }) {
  return (
    <div className={classes.brainTitle}>
      <div className={classes.logoContainer}>
        <img src={logo} alt="BRAIN" />
      </div>
      <div className={classes.title}>BRAIN</div>
      <button
        type="button"
        onClick={toggleSidebarPinning}
        className={isPinned ? classes.active : ''}
      >
        <PushPinIcon />
      </button>
    </div>
  );
}

BrainTitle.propTypes = {
  isPinned: PropTypes.bool.isRequired,
  toggleSidebarPinning: PropTypes.func.isRequired,
};
