// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form._3An_n3x8 {\n  margin-bottom: 50px;\n}\n\n  form._3An_n3x8 div.ti-zlCpH {\n    display: flex;\n    width: 1150px;\n    align-items: center;\n    justify-content: flex-end;\n    margin: 20px 0;\n  }\n\n  form._3An_n3x8 div.ti-zlCpH button:first-of-type {\n      margin-right: 22px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field-grid": "_3An_n3x8",
	"action-buttons": "ti-zlCpH"
};
export default ___CSS_LOADER_EXPORT___;
