import PropTypes from 'prop-types';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import cc from 'classcat';

import classes from './styles.scss';

export default function DropdownIndicator({ isDisabled = false, innerRef, innerProps }) {
  return (
    <ArrowDropDown
      ref={innerRef}
      disabled={isDisabled}
      className={cc([classes.arrow, { [classes.disabled]: isDisabled }])}
      {...innerProps}
    />
  );
}

DropdownIndicator.propTypes = {
  isDisabled: PropTypes.bool,
  innerRef: PropTypes.func,
  innerProps: PropTypes.object.isRequired,
};
