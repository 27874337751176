import { gqlRequest } from './graphQlClient';

/**
 * This functions returns one batched mutation created from the given mutations array
 * @param {[]} mutations - an array of mutation description objects that should conform to:
 * {
 *  mutation: inputName => mutationString,
 *  inputType: 'TheGraphQLInputType!',
 *  variables: {}
 * }
 * @param {function} [requestFunc=gqlRequest] - the actual function that runs the request.
 */
function batchedMutations(mutations, requestFunc = gqlRequest) {
  let batchedMutation = 'mutation BatchedMutation(';
  let mutationBody = '';
  const input = {};
  mutations.forEach(({ mutation, inputType, variables }, index) => {
    // Name the input according to the index
    const inputName = `input${index}`;

    // Add the argument declaration
    batchedMutation = batchedMutation.concat(`$${inputName}: ${inputType}, `);

    // Add an aliased mutation
    mutationBody = mutationBody.concat(`mutation${index}: ${mutation(inputName)}`);

    // Add the input
    input[inputName] = variables;
  });

  // Replace trailing comma with the proper mutation declaration end
  batchedMutation = batchedMutation.replace(/, $/, ') {');

  // Add the body and close the request
  batchedMutation = batchedMutation.concat(mutationBody).concat('}');

  // Return the promised request
  return requestFunc(batchedMutation, input);
}

export default batchedMutations;
