import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import selectProps from './props';

/**
 * Use `BrainSelect` component when you need one of the following input types:
 *
 * 1. Single select.
 * 2. Async single select.
 * 3. Multi-select.
 * 4. Async multi-select.
 *
 * Based on [react-select](https://react-select.com).
 *
 * NOTE: The menu placement automatically flips when there isn't enough space below the control.
 */
export default function BrainSelect({
  label,
  value = null,
  onChange,
  options = [],
  isMulti = false,
  isAsync = false,
  isClearable = false,
  fetchOptions,
  onOptionsReady,
  disabled = false,
  isUser = false,
}) {
  const sharedProps = {
    label,
    value,
    onChange,
    isMulti,
    isClearable,
    isDisabled: disabled,
    isUser,
    closeMenuOnSelect: !isMulti,
    ...selectProps,
  };

  const [asyncOptions, setAsyncOptions] = useState(null);

  const isMounted = useRef(null);

  useEffect(() => {
    /* Need this to avoid scenario where options are fetched after component is unloaded. */
    // noinspection JSValidateTypes
    isMounted.current = true;
    return () => {
      // noinspection JSValidateTypes
      isMounted.current = false;
    };
  });

  const loadOptions = async () => {
    if (asyncOptions) return asyncOptions;
    const fetchedOptions = await fetchOptions();
    if (isMounted.current) {
      setAsyncOptions(fetchedOptions);
      if (onOptionsReady) {
        onOptionsReady(fetchedOptions);
      }
    }
    return fetchedOptions;
  };

  return isAsync ? (
    <AsyncSelect {...sharedProps} loadOptions={loadOptions} defaultOptions cacheOptions />
  ) : (
    <Select {...sharedProps} options={options} />
  );
}

BrainSelect.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
    ),
  ]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  isAsync: PropTypes.bool,
  isClearable: PropTypes.bool,
  fetchOptions: PropTypes.func,
  onOptionsReady: PropTypes.func,
  disabled: PropTypes.bool,
  isUser: PropTypes.bool,
};
