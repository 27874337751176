import { Route } from 'react-router-dom';

import ProcessListPage from 'containers/ProcessListPage/Loadable';
import ProcessInitPage from 'containers/ProcessInitPage/Loadable';
import ProcessUpdatePage from 'containers/ProcessUpdatePage/Loadable';
import ProcessInstancePage from 'containers/ProcessInstancePage/Loadable';
import StepPage from 'containers/StepPage/Loadable';

import ProcessInitiationFormEco from 'components/ProcessInitiationFormEco';
import PermissionRoute from 'components/PermissionRoute';

const controlledDocsPrefix = ':processTypeShortname(controlled-documents)/:domain';

const ProcessRoutes = (processType) => [
  /** Contolled Docs */
  <PermissionRoute
    exact
    path={`/process/${controlledDocsPrefix}/new`}
    component={ProcessInitPage}
    scope={`${processType}:create`}
    key="controlled-docs-new"
  />,
  <PermissionRoute
    exact
    path={`/process/${controlledDocsPrefix}/edit/:processId`}
    component={ProcessUpdatePage}
    scope={`${processType}:update`}
    key="controlled-docs-update"
  />,
  <Route
    exact
    path={`/processes/${controlledDocsPrefix}`}
    component={ProcessListPage}
    key="controlled-docs-list"
  />,
  <Route
    exact
    path={`/process/${controlledDocsPrefix}/:processId/stage/:stageId/step/:stepId`}
    component={StepPage}
    key="controlled-docs-step"
  />,
  /* Process Metadata update - restricted */
  <PermissionRoute
    exact
    path="/process/:processTypeShortname/edit/:processId"
    component={ProcessUpdatePage}
    scope={`${processType}:update`}
    key="process-update"
  />,
  /* Process Initiation - restricted */
  <PermissionRoute
    exact
    path="/process/:processTypeShortname/typeDefinition"
    component={ProcessInitiationFormEco}
    scope={`${processType}:create`}
    key="process"
  />,
  <PermissionRoute
    exact
    path="/process/:processTypeShortname/new"
    component={ProcessInitPage}
    scope={`${processType}:create`}
    key="process1"
  />,
  /* Incident reports - viewing restricted */
  <PermissionRoute
    scope="infosec-incident-reports:list"
    exact
    path="/processes/:processTypeShortname(infosec-incident-reports)"
    component={ProcessListPage}
    key="process2"
  />,
  <PermissionRoute
    exact
    scope="infosec-incident-reports:list"
    path="/process/:processTypeShortname(infosec-incident-reports)/:processId/(pdf)?"
    component={ProcessInstancePage}
    key="process3"
  />,
  <PermissionRoute
    exact
    scope="infosec-incident-reports:list"
    path="/process/:processTypeShortname(infosec-incident-reports)/:processId/stage/:stageId/step/:stepId"
    component={StepPage}
    key="process4"
  />,
  <Route
    exact
    path="/processes/:processTypeShortname(controlled-documents)/:domain?"
    component={ProcessListPage}
    key="controlled-documents"
  />,
  /* Other processes - anyone can view */
  <Route
    exact
    path="/processes/:processTypeShortname?"
    component={ProcessListPage}
    key="process4"
  />,
  <Route
    exact
    path="/process/:processTypeShortname/:processId/(pdf)?"
    component={ProcessInstancePage}
    key="process5"
  />,
  <Route
    exact
    path="/process/:processTypeShortname/:processId/stage/:stageId/step/:stepId"
    component={StepPage}
    key="process6"
  />,
];

export default ProcessRoutes;
