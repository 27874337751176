import { makeFlattenGqlResponse } from '@shared/utils/graphQl';

const replacementKeys = {
  stagesByProductionRunId: 'stages',
  descriptorsByStepId: 'descriptors',
  stepsByStageId: 'steps',
  partnerProductsByPartnerId: 'partnerProducts',
  partnerProductSpecsByPartnerId: 'partnerProductSpecs',
  stagesByProcessId: 'stages',
};
const flattenGqlResponse = makeFlattenGqlResponse({ replacementKeys });

export function brainFlattenGqlRequest(input) {
  return flattenGqlResponse(input);
}
