import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import Dialog from '@material-ui/core/Dialog';

import BrainButton from '@shared/components/BrainButton';
import XIcon from '@shared/components/Icons/XIcon';
import ButtonRenderer, { buttonsShape } from '@shared/components/ButtonRenderer';

import localClasses from './styles.scss';

function BaseDialog({
  dialogTitle = '',
  buttons = [],
  isOpen,
  onClose,
  dialogDataTestId = 'brain-dialog',
  children,
  buttonsClassName = '',
  classes = {},
  ...otherProps
}) {
  const [open, setOpen] = useState(false);
  const localButtons = useMemo(
    () =>
      buttons.map((button) => ({
        ...button,
        classNames: [localClasses['dialog-button'], ...(button.classNames ?? [])],
      })),
    [buttons],
  );

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  function onCloseClick(e) {
    onClose(e);
  }

  return (
    <Dialog
      classes={{ paper: cc([localClasses.modal, classes.modal]) }}
      open={open}
      onClose={onCloseClick}
      PaperProps={{ 'data-testid': dialogDataTestId }}
      {...otherProps}
    >
      <BrainButton
        variant="secondary"
        classNames={[localClasses.closeButton]}
        isIconButton
        iconComponent={<XIcon />}
        onClick={onCloseClick}
      />

      <div className={cc([localClasses['dialog-content'], classes.content])}>
        <h3 className={cc([localClasses.modalTitle, classes.title])}>{dialogTitle}</h3>
        {children}
      </div>

      {localButtons?.length > 0 && (
        <div className={cc([localClasses.buttonsContainer, buttonsClassName])}>
          <ButtonRenderer buttons={localButtons} />
        </div>
      )}
    </Dialog>
  );
}

BaseDialog.propTypes = {
  dialogTitle: PropTypes.string,
  buttons: buttonsShape,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogDataTestId: PropTypes.string,
  buttonsClassName: PropTypes.string,
  classes: PropTypes.shape({
    modal: PropTypes.string,
    content: PropTypes.string,
    title: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default BaseDialog;
