// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1VztAhve {\n  margin-top: 15px;\n  border: 1px solid var(--lightGrey);\n}\n\n  ._1VztAhve ._3FfbOisy {\n    color: var(--defaultBlack);\n    display: flex;\n    font-size: 14px;\n    line-height: 16px;\n    font-weight: bold;\n    letter-spacing: 0.1px;\n  }\n\n  ._1VztAhve ._3bxg0gmA {\n    margin-top: 3px;\n    border-bottom: 1px solid var(--lightGrey);\n  }\n\n  ._1VztAhve ._1SjFfMIt {\n    display: flex;\n    padding: 10px;\n  }\n\n  ._1VztAhve ._1SjFfMIt > * {\n      flex-grow: 1\n    }\n\n  ._1VztAhve ._1SjFfMIt > *:first-child {\n        margin-right: 20px;\n      }\n\n  ._1VztAhve ._1SjFfMIt.Cy7zDi0h {\n      color: var(--greyText);\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"openingHours": "_1VztAhve",
	"label": "_3FfbOisy",
	"separator": "_3bxg0gmA",
	"toFrom": "_1SjFfMIt",
	"inactive": "Cy7zDi0h"
};
export default ___CSS_LOADER_EXPORT___;
