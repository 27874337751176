// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._1rD52ja6 {\n  display: flex;\n  align-items: center;\n  padding: 1px 0;\n  border-bottom: 1px solid var(--sideBarBorderColor);\n}\n\n  div._1rD52ja6 > button {\n    border: none;\n    margin: 0 12px;\n    border-radius: 50%;\n    min-height: initial !important\n  }\n\n  div._1rD52ja6 > button._3LVjYyHE {\n      color: var(--sideBarBlue);\n    }\n\n  div._1rD52ja6 > button:hover {\n      background-color: var(--sideBarBgColor);\n    }\n\n  div._1rD52ja6 > button > svg {\n      width: 24px;\n      height: 24px;\n      display: block;\n    }\n\n  div._1rD52ja6 div._3apLUT9t {\n    width: var(--sideBarWidth);\n    flex-shrink: 0;\n    display: flex;\n    justify-content: center;\n  }\n\n  div._1rD52ja6 div._3apLUT9t img {\n      width: 85%;\n    }\n\n  div._1rD52ja6 div._3EovrYA5 {\n    flex-grow: 1;\n    font-size: 27px;\n    font-weight: 300;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brainTitle": "_1rD52ja6",
	"active": "_3LVjYyHE",
	"logoContainer": "_3apLUT9t",
	"title": "_3EovrYA5"
};
export default ___CSS_LOADER_EXPORT___;
