import PropTypes from 'prop-types';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import history from '@shared/redux/history';
import BrainButton from '@shared/components/BrainButton';

export default function PdfLink({ pdfLink }) {
  const onClick = () => history.push(pdfLink);

  return (
    <BrainButton variant="text" onClick={onClick}>
      <InsertDriveFileOutlinedIcon />
      &nbsp;VIEW AS PDF
    </BrainButton>
  );
}

PdfLink.propTypes = {
  pdfLink: PropTypes.string.isRequired,
};
