// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._2leD0aT3 {\n  width: var(--size);\n  height: var(--size);\n  color: var(--color);\n  text-align: center;\n  font-size: 18px;\n  font-weight: bold;\n  letter-spacing: 0.13px;\n}\n\n  div._2leD0aT3 p {\n    max-width: 350px;\n    min-height: 38px;\n  }\n\ndiv._2xc-iETY {\n  color: var(--color);\n  stroke-linecap: round;\n}\n\nsvg._28K2d_oR {\n  width: 50px;\n  height: 50px;\n}\n\ndiv._25skezFk {\n  z-index: 1\n}\n\ndiv._25skezFk:not(._1cLeZyVU) {\n    background-color: rgba(255, 255, 255, 0.8);\n  }\n\nbutton._17mtRO4Y {\n  color: var(--color);\n  border: 1px solid var(--color)\n}\n\nbutton._17mtRO4Y:hover {\n    background-color: rgba(255, 255, 255, 0.9);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-status": "_2leD0aT3",
	"circular-progress": "_2xc-iETY",
	"error-icon": "_28K2d_oR",
	"backdrop": "_25skezFk",
	"invisibleBackdrop": "_1cLeZyVU",
	"dismiss-error-button": "_17mtRO4Y"
};
export default ___CSS_LOADER_EXPORT___;
