// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3Dg9TQn4 {\n  color: var(--defaultBlack)\n}\n._3Dg9TQn4.Axf3HrWf {\n    color: var(--lightGrey);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": "_3Dg9TQn4",
	"disabled": "Axf3HrWf"
};
export default ___CSS_LOADER_EXPORT___;
