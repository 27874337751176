export default function ProductionRunIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
      <g fill="none" fillRule="evenodd">
        <rect width="46" height="46" fill="#FFF" fillOpacity=".01" rx="3" />
        <g stroke="currentColor" strokeWidth="2" transform="translate(12 11)">
          <rect width="4" height="12" x="1" y="1" rx="2" transform="rotate(-180 3 7)" />
          <rect width="4" height="12" x="9" y="1" rx="2" transform="rotate(-180 11 7)" />
          <rect
            width="4"
            height="12"
            x="17"
            y="1"
            fill="currentColor"
            rx="2"
            transform="rotate(-180 19 7)"
          />
          <g strokeLinecap="round">
            <path strokeLinejoin="round" d="M.5 20.5l20 .369" />
            <path d="M18.612 23.598l3-2.486-3-2.485" />
          </g>
        </g>
      </g>
    </svg>
  );
}
