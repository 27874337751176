// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._2sHL3a3S {\n  display: flex;\n  align-items: stretch;\n  height: 100%;\n}\n\n  div._2sHL3a3S div._1ISgmbrd {\n    display: flex;\n    flex-direction: column;\n    overflow: auto;\n    flex-grow: 1\n  }\n\n  div._2sHL3a3S div._1ISgmbrd::-webkit-scrollbar {\n    background-color: transparent;\n    width: 9px;\n    height: 9px;\n  }\n\n  div._2sHL3a3S div._1ISgmbrd::-webkit-scrollbar-thumb {\n    background-color: rgba(0, 0, 0, 0.15);\n    border-radius: 4px;\n    border: 2px solid transparent;\n    background-clip: padding-box\n  }\n\n  div._2sHL3a3S div._1ISgmbrd::-webkit-scrollbar-thumb:hover {\n      background-color: rgba(0, 0, 0, 0.2);\n      border-radius: 4px;\n      border: 2px solid transparent;\n      background-clip: padding-box;\n    }\n\n  div._2sHL3a3S div._1ISgmbrd._1ARD_d7Z {\n      margin-right: var(--log-drawer-closed-width);\n    }\n\n  div._2sHL3a3S div._1ISgmbrd div._1FP-uRcZ {\n      margin: 0 auto;\n      padding: 28px 40px 37px;\n      min-width: var(--layout-content-min-width);\n      width: 100%;\n      max-width: var(--layout-content-width);\n      flex-grow: 1;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "_2sHL3a3S",
	"main": "_1ISgmbrd",
	"extra-margin": "_1ARD_d7Z",
	"content-wrapper": "_1FP-uRcZ"
};
export default ___CSS_LOADER_EXPORT___;
