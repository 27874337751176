import PropTypes from 'prop-types';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { css } from '@emotion/react';

import useCopyToClipboard from '@shared/hooks/useCopyToClipboard';

export default function BraincCopyIcon({ text }) {
  const [onCopy] = useCopyToClipboard();
  const buttonCss = css`
   
    border: 0;
    padding:0px;
    color: var(--brain-primary);   
    background: none;
    cursor: pointer;
    height: 20px;
    .MuiSvgIcon-root {
      height: 20px;   
    }
      }
    `;
  return (
    <button
      css={buttonCss}
      type="button"
      aria-label="Copy to Clipboard Button"
      onClick={() => {
        onCopy(text);
      }}
    >
      <FileCopyIcon />
    </button>
  );
}

BraincCopyIcon.propTypes = {
  text: PropTypes.string,
};
