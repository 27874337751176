export default function BrainTooltipTriggerIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
      <g fill="none" fillRule="evenodd" transform="translate(0 .5)">
        <circle cx="8" cy="8" r="8" fill="currentColor" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M8.724 9.808l.024-.37c.045-.397.221-.744.529-1.04l.492-.467c.385-.373.654-.712.808-1.018.154-.306.231-.631.231-.976 0-.757-.237-1.343-.71-1.759-.475-.415-1.141-.622-2-.622-.85 0-1.522.218-2.014.656-.492.437-.743 1.043-.75 1.817h1.761c.009-.324.103-.579.283-.763.18-.184.42-.276.72-.276.632 0 .948.342.948 1.026 0 .227-.06.443-.182.648-.122.204-.366.47-.732.796-.367.326-.62.657-.757.993-.138.336-.207.788-.207 1.355h1.556zm-.747 2.79c.295 0 .533-.088.714-.262a.882.882 0 00.27-.662.889.889 0 00-.277-.672.983.983 0 00-.707-.264.983.983 0 00-.708.264.889.889 0 00-.277.672c0 .267.09.488.27.662.18.174.419.261.715.261z"
        />
      </g>
    </svg>
  );
}
