import PropTypes from 'prop-types';

import classes from './styles.scss';

export default function InputLabel({ isOptional = false, label }) {
  return isOptional ? (
    <>
      {label} <span className={classes['optional-label']}>(optional)</span>
    </>
  ) : (
    label
  );
}

InputLabel.propTypes = {
  label: PropTypes.node.isRequired,
  isOptional: PropTypes.bool,
};
