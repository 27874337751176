import PropTypes from 'prop-types';
import cc from 'classcat';
import { Helmet } from 'react-helmet';

import classes from './styles.scss';

export default function MainPageHeader({ title = '', className, children }) {
  return (
    <div className={cc([className, classes['main-page-header']])}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <h1 className={classes.title}>{title}</h1>
      {children && <div>{children}</div>}
    </div>
  );
}

MainPageHeader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
