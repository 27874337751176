import ClearIndicator from './ClearIndicator';
import Control from './Control';
import SingleValue from './SingleValue';
import MultiValue from './MultiValue';
import Option from './Option';
import DropdownIndicator from './DropdownIndicator';

const components = {
  Placeholder: () => null,
  DropdownIndicator,
  ClearIndicator,
  Control,
  SingleValue,
  MultiValue,
  Option,
};

export default components;
