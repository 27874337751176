import PropTypes from 'prop-types';
import cc from 'classcat';

import SideBar from 'components/SideBar';
import BugReportFooter from 'components/BugReportFooter';

import classes from './styles.scss';

export default function Layout({ children, hasLogDrawer }) {
  return (
    <div className={classes.layout}>
      <SideBar />
      <div
        data-testid="main-content"
        className={cc([classes.main, { [classes['extra-margin']]: hasLogDrawer }])}
      >
        <div className={classes['content-wrapper']}>{children}</div>
        <BugReportFooter />
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hasLogDrawer: PropTypes.bool.isRequired,
};
