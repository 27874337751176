// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "svg._3sHhkRQV {\n  margin: 7px;\n  cursor: pointer;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clearIndicator": "_3sHhkRQV"
};
export default ___CSS_LOADER_EXPORT___;
