import jwtDecode from 'jwt-decode';
/* {
  "tags": {"role": "admin"},
  "email": "brain-test@healthy.io",
  "fullName": "BRAIN Testing GENERIC",
  "iat": 1608458100,
  "exp": 1608461700,
  "iss": "c9c60d27ceffb9f03b2899e8f32c9ad8",
  "sub": "Z29vZ2xlOjpzaHVsYW1pdC5mZXJiZXJAaGVhbHRoeS5pbw=="
} */
export const testUserUMToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0YWdzIjp7InJvbGUiOiJhZG1pbiJ9LCJlbWFpbCI6ImJyYWluLXRlc3RAaGVhbHRoeS5pbyIsImZ1bGxOYW1lIjoiQlJBSU4gVGVzdGluZyBHRU5FUklDIiwiaWF0IjoxNjA4NDU4MTAwLCJleHAiOjE2MDg0NjIwMjIsImlzcyI6ImM5YzYwZDI3Y2VmZmI5ZjAzYjI4OTllOGYzMmM5YWQ4Iiwic3ViIjoiWjI5dloyeGxPanB6YUhWc1lXMXBkQzVtWlhKaVpYSkFhR1ZoYkhSb2VTNXBidz09IiwianRpIjoiNjU2M2VmMmQtMjlhYS00N2M5LTlhYjktOTA3YjViNTc3ZDU5In0.Cd92CU-Ro32YFDZ9LZmMzqVy2K5LLH5dyF2doijhcuE';
const decodeToken = (token) => jwtDecode(token).tags;

export const exchangeUMToken = () => ({
  umToken: testUserUMToken,
  umPermissions: decodeToken(testUserUMToken),
});

export const extractRefreshToken = () => ({
  umToken: testUserUMToken,
  umPermissions: decodeToken(testUserUMToken),
});
