import { css, Global } from '@emotion/react';
import PropTypes from 'prop-types';
import cc from 'classcat';

import CloseIcon from './CloseIcon';

const toasterCss = css`
  .Toast__root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px;
    padding-inline-end: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }

  .Toast__message {
    margin-top: 0;
    margin-bottom: 0;
  }

  .Toast__close-btn {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: currentColor;
    margin-inline-start: 4px;
  }

  .Toast__error {
    background-color: #e74d5f;
    color: #ffffff;
  }

  .Toast__info {
    background-color: #538aea;
    color: #ffffff;
  }

  .Toast__success {
    background-color: #51bd7a;
    color: #ffffff;
  }

  .Toast__warning {
    background-color: #f5bc33;
    color: #ffffff;
  }
`;

function Toast({ message, onClose, skin, visible }) {
  return (
    <>
      <Global styles={toasterCss} />
      <div
        aria-live="polite"
        className={cc([
          'Toast__root',
          `Toast__${skin}`,
          {
            'Toast--visible': visible,
          },
        ])}
        role="status"
      >
        <p className="Toast__message">{message}</p>
        <button
          aria-label="Close notification"
          className="Toast__close-btn"
          onClick={onClose}
          type="button"
        >
          <CloseIcon />
        </button>
      </div>
    </>
  );
}

Toast.propTypes = {
  /** The message to display. */
  message: PropTypes.node,
  /** Callback that dismisses the toast. */
  onClose: PropTypes.func,
  /** The toaster's skin. */
  skin: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  /** If `true`, the toast is shown. */
  visible: PropTypes.bool,
};

export default Toast;
