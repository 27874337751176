import { useMemo } from 'react';
import PropTypes from 'prop-types';

import BrainButton from '@shared/components/BrainButton';

import ErrorRobot from 'images/error-robot.svg';

import classes from './styles.scss';

const ErrorBoundaryPage = ({ componentStack, error, resetErrorBoundary }) => {
  const parsedStack = useMemo(
    () => componentStack && componentStack.trim().replace(/\n\s+/g, '\n'),
    [componentStack],
  );

  return (
    <div className={classes.container}>
      <img className={classes.robot} src={ErrorRobot} alt="Error Robot" />
      <h1>Oops! Something went wrong!</h1>
      <div className={classes['inner-text']}>
        <div className={classes['error-title']}>{error.toString()}</div>
        <div className={classes.stack}>{parsedStack}</div>
      </div>
      <BrainButton classNames={[classes.button]} onClick={resetErrorBoundary}>
        Refresh Page
      </BrainButton>
      <hr />
      <div className={classes['bottom-text']}>
        Please help us fix this by{' '}
        <a href="https://healthyio.monday.com/boards/3185863348" target="_blank">
          opening a bug report
        </a>{' '}
        and including a screenshot of this page.
      </div>
    </div>
  );
};

export default ErrorBoundaryPage;

ErrorBoundaryPage.propTypes = {
  componentStack: PropTypes.node.isRequired,
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};
