import { useState, useEffect } from 'react';

import useLoadScript from '@shared/hooks/useLoadScript';
import { isIntegrationTest } from '@shared/utils/integrationTestUtils';

import FullPageLoader from 'components/FullPageLoader';

if (isIntegrationTest()) {
  /* eslint-disable-next-line global-require */
  require('./testing/GoogleAuthProviderMock');
}

// We can disable this rule here because isIntegrationTest is constant.
/* eslint-disable react-hooks/rules-of-hooks */

const useGoogleApi = () => {
  if (isIntegrationTest()) return { displayIfNotReady: null };

  const [isInitialized, setIsInitialized] = useState(false);
  const [initError, setInitError] = useState(false);

  // Will add `gapi` on the `window`
  // https://github.com/google/google-api-javascript-client
  const { isLoadingScript: gapiLoading, errorLoadingScript: gapiError } = useLoadScript(
    process.env.GOOGLE_API_CLIENT_URL,
  );

  const { isLoadingScript: gsiLoading, errorLoadingScript: gsiError } = useLoadScript(
    process.env.GOOGLE_GSI_CLIENT_URL,
  );

  useEffect(() => {
    if (!gapiLoading && !gapiError) {
      gapi.load('client', async () => {
        try {
          await gapi.client.init({
            apiKey: process.env.GOOGLE_API_KEY,
            discoveryDocs: process.env.GOOGLE_API_DISCOVERY_DOCS,
          });
          setIsInitialized(true);
        } catch (e) {
          setInitError(true);
        }
      });
    }
  }, [gapiLoading]);

  let displayIfNotReady = false;

  if (gapiLoading || gsiLoading)
    displayIfNotReady = (
      <FullPageLoader isLoading invisibleBackdrop loadingText="Loading APIs" />
    );
  else if (gapiError || gsiError)
    displayIfNotReady = <div>Error loading Google APIs</div>;
  else if (initError) displayIfNotReady = <div>Error initializing Google APIs</div>;
  else if (!isInitialized)
    displayIfNotReady = (
      <FullPageLoader isLoading invisibleBackdrop loadingText="Initializing APIs" />
    );

  return { displayIfNotReady };
};

export default useGoogleApi;
