import { memo, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import ErrorIcon from '@material-ui/icons/Error';

import BaseDialog from '@shared/components/BrainDialogs/BaseDialog';

import classes from './styles.scss';

function ErrorDialog({
  errorLevel = 'error',
  dialogTitle,
  confirmationText = 'ok',
  isOpen,
  onConfirm,
  className,
  children,
  dialogDataTestId = 'brain-error-dialog',
}) {
  const [open, setOpen] = useState(false);
  const localDialogTitle = useMemo(() => dialogTitle ?? errorLevel, [dialogTitle]);

  function onDialogConfirm() {
    setOpen(false);
    if (onConfirm) onConfirm();
  }

  const modalButtons = [
    {
      label: confirmationText,
      onClickCallback: onDialogConfirm,
      classNames: classes['dialog-button'],
      variant: 'apply',
      autoFocus: true,
      'data-testid': 'error-confirm-btn',
    },
  ];

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <BaseDialog
        onClose={onDialogConfirm}
        isOpen={open}
        dialogTitle={localDialogTitle}
        buttons={modalButtons}
        dialogDataTestId={dialogDataTestId}
      >
        <div
          className={cc([
            classes['error-dialog'],
            classes[`level-${errorLevel}`],
            className,
          ])}
        >
          <ErrorIcon className={classes['error-icon']} />
          <div>{children}</div>
        </div>
      </BaseDialog>
    </>
  );
}

ErrorDialog.propTypes = {
  errorLevel: PropTypes.oneOf(['error', 'warning']),
  dialogTitle: PropTypes.string,
  confirmationText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  dialogDataTestId: PropTypes.string,
};

export default memo(ErrorDialog);
