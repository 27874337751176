/**
 *
 * LocationNewEditPage
 *
 */
/* eslint-disable no-param-reassign */

import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import set from 'lodash/set';
import produce from 'immer';

import { injectReducerAndSaga } from '@shared/redux/injectors/injectReducerAndSaga';
import BrainForm from '@shared/components/BrainForm';
import ErrorDialog from '@shared/components/BrainDialogs/ErrorDialog';

import {
  resetError,
  reducer,
  sliceName,
  fetchLocation,
  createLocation,
  updateLocation,
  resetLocationPage,
} from 'containers/Loco/LocoLocationPage/slice';

import FullPageLoader from 'components/FullPageLoader';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';

import { locoFormFields } from '../locoStructure';
import locoFetchOptions from '../locoQueries';

import { selectLocationPageDomain } from './selectors';
import saga from './saga';
import classes from './styles.scss';

function LOCOLocationPage() {
  const [localLocation, setLocalLocation] = useState();
  const [validationWarningsDialogText, setValidationWarningsDialogText] = useState(null);

  const path = useLocation().pathname;
  const isNew = path.includes('new');

  const { locationId } = useParams();

  const dispatch = useDispatch();
  const { location, breadcrumbs, isLoading, error } = useSelector(
    selectLocationPageDomain,
  );

  useEffect(() => {
    setLocalLocation(location);
  }, [location]);

  useEffect(() => {
    if (isNew) {
      dispatch(resetLocationPage());
    } else {
      dispatch(fetchLocation({ locationId }));
    }
    return () => {
      dispatch(resetLocationPage());
    };
  }, []);

  /* eslint-disable local/prefer-nullish-coalescing-operator */
  const validate = (newLocation) =>
    (!newLocation.name && 'Name') ||
    (!newLocation.type && 'Type') ||
    ((!newLocation.items || newLocation.items.length === 0) && 'Item List') ||
    (!newLocation.country && 'Country') ||
    (!newLocation.city && 'City') ||
    (!newLocation.address?.street && 'Address') ||
    (!newLocation.postcode && 'Postcode') ||
    (!newLocation.referenceNumber && 'Ref Id') ||
    (!newLocation.originalId && 'Store/Location Id') ||
    (!newLocation.courierNote && 'Courier note') ||
    (!newLocation.contactInformation.name && 'Contact name') ||
    (!newLocation.contactInformation.position && 'Contact name') ||
    (!newLocation.contactInformation.phonenumber && 'Contact phone') ||
    (!newLocation.contactInformation.emailaddress && 'Contact email');

  const onSubmit = (changes) => {
    const newLocation = produce(location, (draft) => {
      Object.keys(changes).forEach((name) => {
        set(draft, name, changes[name]);
      });
    });
    const errField = validate(newLocation);
    setLocalLocation(newLocation);
    if (errField) {
      return setValidationWarningsDialogText(`${errField} is missing.`);
    }
    if (isNew) {
      return dispatch(createLocation({ location: newLocation }));
    }
    return dispatch(updateLocation({ location: newLocation, changes }));
  };

  const onErrorReset = () => {
    dispatch(resetError());
  };

  return location ? (
    <>
      {Boolean(isLoading || error) && (
        <FullPageLoader
          isLoading={isLoading}
          hasError={error !== false}
          errorText={`An error occurred. ${error}`}
          onErrorDismiss={onErrorReset}
          errorDismissButtonText="close"
        />
      )}
      <div className={classes['location-page']} data-testid="loco-location-page">
        <div className={classes.header} data-testid="location-title">
          <BreadcrumbsHeader
            breadCrumbs={breadcrumbs}
            title={location?.name ?? 'New Location'}
          />
        </div>
        {validationWarningsDialogText && (
          <ErrorDialog
            errorLevel="warning"
            dialogTitle="Validation Failed"
            isOpen
            onConfirm={() => setValidationWarningsDialogText(null)}
          >
            {validationWarningsDialogText}
          </ErrorDialog>
        )}
        <BrainForm
          fields={locoFormFields}
          cancelUrl="/loco"
          onSubmit={onSubmit}
          fetchOptions={locoFetchOptions}
          getValue={(name) => localLocation && get(localLocation, name)}
          isNew={isNew}
        />
      </div>
    </>
  ) : (
    <div />
  );
}

export default injectReducerAndSaga({ key: sliceName, reducer, saga })(LOCOLocationPage);
