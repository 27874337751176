import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import BaseDialog from '@shared/components/BrainDialogs/BaseDialog';

function ConfirmationDialog({
  dialogTitle = '',
  confirmationText = 'yes',
  cancellationText = 'cancel',
  isOpen,
  onConfirm,
  onCancel,
  disableConfirm = false,
  disableCancel = false,
  children,
  className,
}) {
  const [open, setOpen] = useState(false);

  function onDialogCancel(e) {
    setOpen(false);
    if (onCancel) onCancel(e);
  }

  function onDialogConfirm(e) {
    setOpen(false);
    if (onConfirm) onConfirm(e);
  }

  const modalButtons = [
    {
      label: cancellationText,
      onClickCallback: onDialogCancel,
      variant: 'cancel',
      disabled: disableCancel,
      'data-testid': 'confirmation-cancel-btn',
    },
    {
      label: confirmationText,
      onClickCallback: onDialogConfirm,
      variant: 'apply',
      autoFocus: true,
      disabled: disableConfirm,
      'data-testid': 'confirmation-confirm-btn',
    },
  ];

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <BaseDialog
        dialogDataTestId="confirmation-dialog"
        classes={{ modal: className }}
        onClose={onDialogCancel}
        isOpen={open}
        dialogTitle={dialogTitle}
        buttons={modalButtons}
      >
        {children}
      </BaseDialog>
    </>
  );
}

ConfirmationDialog.propTypes = {
  dialogTitle: PropTypes.string,
  confirmationText: PropTypes.string,
  cancellationText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  disableConfirm: PropTypes.bool,
  disableCancel: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default memo(ConfirmationDialog);
