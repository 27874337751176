import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';

/**
 * Makes a flattenGqlResponse function for usage when you want flatten graphQl responses
 * @param {*} options configuration
 * replacementKeys: Object comports to:
 *  - key: what name to replace
 *  - value: what new name to assign
 */
export default function makeFlattenGqlResponse(options) {
  return function flattenGqlResponse(input) {
    if (input) {
      // First, we check for and remove the keys we don't want
      if (input.edges) return flattenGqlResponse(input.edges);
      if (input.nodes) return flattenGqlResponse(input.nodes);
      if (input.node) return flattenGqlResponse(input.node);
    }

    switch (true) {
      case isArray(input):
        // If array, flatten all the items inside
        return input.map((item) => flattenGqlResponse(item));
      case isObject(input): {
        // If object, flatten all the keys inside
        const output = {};
        Object.entries(input).forEach(([key, value]) => {
          const newKey = options?.replacementKeys?.[key] ?? key;
          output[newKey] = flattenGqlResponse(value);
        });
        return output;
      }
      default:
        // If not array or object, simply return the input
        return input;
    }
  };
}
