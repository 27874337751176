import PropTypes from 'prop-types';
import cc from 'classcat';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import classes from './styles.scss';

export default function BrainButton({
  isIconButton = false,
  iconComponent = null,
  children,
  variant = 'primary',
  classNames = [],
  ...otherProps
}) {
  return isIconButton ? (
    <IconButton
      classes={{
        root: cc([
          classes['brain-button'],
          classes['brain-icon-button'],
          classes[variant],
          ...classNames,
        ]),
        disabled: classes.disabled,
      }}
      focusVisibleClassName={classes.focus}
      TouchRippleProps={{ classes: { root: classes.ripple } }}
      {...otherProps}
    >
      {iconComponent}
    </IconButton>
  ) : (
    <Button
      classes={{
        root: cc([classes['brain-button'], classes[variant], ...classNames]),
        disabled: classes.disabled,
        startIcon: classes.icon,
      }}
      focusVisibleClassName={classes.focus}
      TouchRippleProps={{ classes: { root: classes.ripple } }}
      // Disable icon in popup buttons
      startIcon={!['apply', 'cancel'].includes(variant) && iconComponent}
      {...otherProps}
    >
      {children}
    </Button>
  );
}

BrainButton.propTypes = {
  isIconButton: PropTypes.bool,
  iconComponent: PropTypes.node,
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'text',
    'apply',
    'cancel',
    'warning',
  ]),
  classNames: PropTypes.array,
  muiClasses: PropTypes.object,
};
