/* eslint-disable max-classes-per-file */
export const BrainElementTypes = {
  PPS: 'PPS',
  PR: 'Production Run',
  PR_STEP: 'Production Run Step',
  PROCESS: 'Process',
  PROCESS_STEP: 'Process Step',
};

export const PR_TYPE = {
  shortname: 'production-runs',
  type: 'Production Run',
  label: 'Production Runs',
};

export const ControlledDocsDomains = [
  'All',
  'QMS',
  'DHF',
  'TF',
  'DMR',
  'DHR',
  'Software',
  'Marketing',
  'RA',
  'Lab',
];

export const QMS_LABELS = [
  'CAPA',
  'ECO',
  'ECO (US)',
  'Short ECO',
  'Complaints',
  'Infosec Incident Reports',
];
const qmsLabelToShortname = (label) =>
  label.toLowerCase().replaceAll(' ', '-').replaceAll(/\(|\)/g, '');

export const QMS_TYPES = QMS_LABELS.map((label) => ({
  label,
  shortname: qmsLabelToShortname(label),
}));
