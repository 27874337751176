import { useEffect, useRef, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import cc from 'classcat';
import { Link, useLocation } from 'react-router-dom';
import produce from 'immer';

import { useLocalStorage } from '@shared/hooks/useLocalStorage';

import classes from './NavTree.scss';
import { TREE } from './navTreeData';

const LAST_OPENED_GROUP_INDEX = 'lastOpenedGroupIndex';

/* eslint-disable no-param-reassign */
const initTree = (tree) => {
  const lastOpenedGroupIndex = parseInt(
    localStorage.getItem(LAST_OPENED_GROUP_INDEX),
    10,
  );
  return tree.map((group, index) => ({ ...group, open: index === lastOpenedGroupIndex }));
};

const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'TOGGLE_GROUP':
        draft.forEach((group, index) => {
          group.open = action.payload.index === index ? !group.open : false;
        });
        break;
      default:
        break;
    }
  });
/* eslint-enable */

export default function NavTree({ isExpanded }) {
  const ref = useRef(null);
  const timeout = useRef(100);
  const location = useLocation();
  const [tree, dispatch] = useReducer(reducer, TREE, initTree);
  const [, setLastOpenedGroupIndex] = useLocalStorage(LAST_OPENED_GROUP_INDEX, false);

  useEffect(() => {
    if (!isExpanded) ref.current.scrollTo(0, 0);
  }, [isExpanded]);

  useEffect(() => {
    timeout.current = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--sideBarExpansionTime',
      ),
      10,
    );
  }, []);

  const toggleGroup = useCallback(
    (index) => {
      dispatch({ type: 'TOGGLE_GROUP', payload: { index } });
      setLastOpenedGroupIndex(!tree[index].open ? index : false);
    },
    [dispatch, tree],
  );

  const checkActive = (item) => {
    if (item.activeRouteRegexp) {
      return !!location.pathname.match(item.activeRouteRegexp);
    }
    return location.pathname.startsWith(item.activeRouteCheck ?? item.to);
  };

  return (
    <div
      ref={ref}
      className={cc([classes.navTree, { [classes.collapsed]: !isExpanded }])}
    >
      {tree.map(({ icon: GroupIcon, ...group }, index) => (
        <div key={group.name}>
          <button
            type="button"
            onClick={() => {
              toggleGroup(index);
            }}
            className={cc([
              classes.button,
              { [classes.active]: group.isActive(location.pathname) },
            ])}
          >
            <GroupIcon />
            <span className={classes.text}>{group.name}</span>
            {group.open ? (
              <ExpandLess className={classes.expand} />
            ) : (
              <ExpandMore className={classes.expand} />
            )}
          </button>
          <Collapse in={group.open && isExpanded} timeout={timeout.current}>
            {group.items.map((item) => (
              <Link
                key={item.name}
                className={cc([
                  classes.link,
                  {
                    [classes.active]: checkActive(item),
                  },
                ])}
                to={item.to}
              >
                {item.name}
              </Link>
            ))}
          </Collapse>
        </div>
      ))}
    </div>
  );
}

NavTree.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
};
