// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2fIPE1ON {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n  ._2fIPE1ON ._1DVUhcCu {\n    flex: 1;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    line-height: 1.15em;\n  }\n\n  ._2fIPE1ON ._25Fxyd0i {\n    margin-left: 5px;\n  }\n\n  ._2fIPE1ON ._3UVKVliw {\n    color: #e74d5f;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field-label": "_2fIPE1ON",
	"label": "_1DVUhcCu",
	"field-label-tooltip": "_25Fxyd0i",
	"tooltipError": "_3UVKVliw"
};
export default ___CSS_LOADER_EXPORT___;
