export default function VIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.439 11.455L.396 7.395a1.42 1.42 0 01-.064-1.898 1.18 1.18 0 011.758-.07l3.558 3.574L11.768.53c.416-.591 1.194-.704 1.741-.256.547.45.653 1.292.237 1.882l-6.52 9.04a1.861 1.861 0 01-1.5.804 1.82 1.82 0 01-1.287-.545z"
      />
    </svg>
  );
}
