export default function RemoveFileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22">
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <g stroke="currentColor" strokeLinejoin="round" strokeWidth="1.5">
          <path d="M6.857 0H0v16.286h12V5.143z" />
          <path fill="#FFF" d="M6.857 0v5.143H12z" />
        </g>
        <circle cx="11" cy="15" r="6" fill="currentColor" />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M13 15H9"
        />
      </g>
    </svg>
  );
}
