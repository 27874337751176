export const calculateStatus = (conditions) => {
  const vals = Object.values(conditions);
  const anySet = vals.reduce(
    // eslint-disable-next-line local/prefer-nullish-coalescing-operator
    (prev, curr) => prev || curr.status,
    false,
  );
  const allSet = vals.reduce((prev, curr) => prev && curr.status, true);
  const allPassed = vals.reduce((prev, curr) => prev && curr.status === 'pass', true);
  if (!anySet) {
    return null;
  }
  if (!allSet) {
    return 'ongoing';
  }
  if (allPassed) {
    return 'pass';
  }
  return 'fail';
};
