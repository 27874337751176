const brainTheme = {
  palette: {
    lightRed: '#fce5e7',
    white: 'white',
    dividerGrey: '#eeeeef',
    dividerRed: 'rgba(231, 77, 95, 0.3)',
    red: '#e74d5f',
    darkGrey: '#7b7b7b',
    bluePrimary: '#538aea',
    lightBlue: '#e6eefc',
  },
};

export default brainTheme;
