import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import ClearRounded from '@material-ui/icons/ClearRounded';
import TextField from '@material-ui/core/TextField';
import debounce from 'lodash/debounce';

const remarksCss = ({ hasRemarks }) => css`
  width: 100%;
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    width: 95%;
  }
  .MuiInputBase-root {
    color: var(--defaultBlack);
    border: 1px dotted rgba(0, 0, 0, 0);
  }

  &:hover {
    .MuiInputBase-root {
      visibility: visible;
      border-color: var(--brain-primary);
      border-radius: 4px;
    }
  }
  .MuiSvgIcon-root {
    cursor: pointer;
    height: 15px;
    color: var(--lightGrey);
    display: ${hasRemarks ? 'block' : 'none'};
  }
`;

export default function RemarksField({
  remarks,
  onChange,
  placeholder,
  disabled,
  ...restProps
}) {
  const [localRemarks, setLocalRemarks] = useState(remarks);

  const setVal = (e) => {
    setLocalRemarks(e.target.value);
  };
  const clearVal = () => {
    setLocalRemarks('');
  };

  const delayedOnChangeFn = useCallback(
    debounce((value) => onChange(value), 300),
    [],
  );

  useEffect(() => {
    if (onChange && remarks !== localRemarks) {
      delayedOnChangeFn(localRemarks, onChange);
    }
  }, [localRemarks]);

  useEffect(() => {
    setLocalRemarks(remarks);
  }, [remarks]);

  return (
    <span css={remarksCss({ hasRemarks: remarks?.length })}>
      <TextField
        autoFocus
        placeholder={placeholder}
        InputProps={{ disableUnderline: true }}
        value={localRemarks}
        onChange={setVal}
        {...restProps}
        disabled={disabled}
      />
      <ClearRounded onClick={clearVal} />
    </span>
  );
}

RemarksField.propTypes = {
  onChange: PropTypes.func,
  remarks: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};
