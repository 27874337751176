/* eslint-disable jsx-a11y/click-events-have-key-events */
import { css } from '@emotion/react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

const backdropCss = ({ showTopBar, customCss, collapsible }) => css`
  padding: 25px;
  min-width: min-content;
  border-radius: 3px;
  background-color: var(--white);
  grid-column-end: span 4;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  &:not(:last-of-type) {
    border-bottom: solid 1px var(--ultraLightGrey);
  }

  ${showTopBar && 'padding-top: 0'};
  ${!collapsible && 'margin-bottom: 20px'};
  ${customCss}
`;

const gridCss = css`
  display: grid;
  grid-template-columns: repeat(4, minmax(180px, 240px));
  grid-auto-rows: minmax(76px, max-content);
  gap: 29px 63px;
`;

const bodyCss = ({ withGrid, customCss }) => css`
  ${withGrid && gridCss}
  ${customCss}
`;

const collapsibleTitleCss = css`
  cursor: pointer;
  border-bottom: 1px solid var(--ultraLightGrey);
`;
const titleCss = ({ collapsible, customCss }) => css`
  height: 55px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.13px;
  line-height: 1em;
  padding: 16px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  ${collapsible && collapsibleTitleCss}
  ${customCss}
`;
export default function BrainBackdrop({
  children,
  title,
  withGrid,
  collapsible,
  classes = {},
  isOpen,
}) {
  const showTopBar = Boolean(title ?? collapsible);
  const defaultOpen = Boolean(!collapsible || isOpen);
  const [isExpanded, setExpanded] = useState(defaultOpen);

  const onToggle = () => {
    if (!collapsible) {
      return;
    }
    setExpanded(!isExpanded);
  };

  return (
    <div css={backdropCss({ collapsible, showTopBar, customCss: classes.backdrop })}>
      {showTopBar && (
        <div onClick={onToggle} css={titleCss({ collapsible, customCss: classes.title })}>
          {collapsible && (isExpanded ? <ExpandLess /> : <ExpandMore />)}
          {title}
        </div>
      )}
      <Collapse in={isExpanded} timeout={300}>
        <div css={bodyCss({ withGrid, customCss: classes.body })}>{children}</div>
      </Collapse>
    </div>
  );
}

BrainBackdrop.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  withGrid: PropTypes.bool,
  classes: PropTypes.object,
  collapsible: PropTypes.bool,
  isOpen: PropTypes.bool,
};
