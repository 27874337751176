const styledScrollbar = {
  '&::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: 9,
    height: 9,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: 4,
    border: '2px solid transparent',
    backgroundClip: 'padding-box',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderRadius: 4,
      border: '2px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
};

const styles = {
  container: (provided) => ({
    ...provided,
    height: 'max-content',
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    alignItems: 'flex-end',
    flexShrink: 0,
    marginBottom: 2,
  }),
  indicatorSeparator: () => ({
    alignSelf: 'flex-end',
    backgroundColor: 'var(--veryLightGrey)',
    width: 1,
    height: 24,
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--defaultBlack)',
    minHeight: 14,
    margin: 0,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 3,
    zIndex: 1,
  }),
  menuList: (provided) => ({
    ...provided,
    ...styledScrollbar,
    padding: 0,
  }),
  valueContainer: (provided, { selectProps }) => ({
    ...styledScrollbar,
    display: 'flex',
    flexWrap: selectProps.isMulti ? 'wrap' : 'nowrap',
    alignItems: 'flex-end',
    width: '100%',
    minHeight: 35,
    padding: 0,
    overflowY: 'auto',
    pointerEvents: 'auto',
  }),
};

export default styles;
