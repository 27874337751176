import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';

import BrainButton from '@shared/components/BrainButton';
import BeforeUnloadBrainDialog from '@shared/components/BrainDialogs/BeforeUnloadBrainDialog';

import classes from './styles.scss';
import FieldComponentsRenderer from './FieldComponentsRenderer';

export default function BrainForm({
  fields,
  fetchOptions = () => [],
  getValue,
  cancelUrl,
  onSubmit,
  demoMode = false,
  isNew = false,
}) {
  const [changes, setChanges] = useState({});
  const formRef = useRef();

  const onComponentChange = (name, newValue) => {
    const originalValue = getValue && getValue(name);
    const newChanges = produce(changes, (draft) => {
      if (originalValue && originalValue === newValue) {
        // eslint-disable-next-line no-param-reassign
        delete draft[name];
      } else {
        // eslint-disable-next-line no-param-reassign
        draft[name] = newValue;
      }
    });
    setChanges(newChanges);
  };

  const history = useHistory();
  const goToCancelUrl = () => {
    if (cancelUrl) history.push(cancelUrl);
  };

  const performSubmit = (e) => {
    e.preventDefault();
    if (formRef.current.reportValidity()) {
      onSubmit(changes);
    }
  };

  function onKeyDown(e) {
    const { target, key, ctrlKey, metaKey } = e;
    const enterKey = key === 'Enter';

    if (enterKey && target.tagName !== 'TEXTAREA') {
      e.preventDefault();
    }

    if (!isEmpty(changes) && ((ctrlKey && enterKey) || (metaKey && enterKey))) {
      performSubmit(e);
    }
  }

  return (
    <>
      <form ref={formRef} className={classes['field-grid']}>
        <div data-testid="field-grid-container" onKeyDown={onKeyDown}>
          <FieldComponentsRenderer
            fields={fields}
            getValue={getValue}
            onChange={onComponentChange}
            fetchOptions={fetchOptions}
          />
        </div>
        <div className={classes['action-buttons']}>
          <BrainButton
            variant="text"
            onClick={goToCancelUrl}
            data-testid="form-cancel-button"
          >
            Cancel
          </BrainButton>
          <BrainButton
            type="submit"
            onClick={performSubmit}
            disabled={isEmpty(changes)}
            data-testid={isNew ? 'form-add-button' : 'form-apply-button'}
          >
            {isNew ? 'Add' : 'Apply'}
          </BrainButton>
        </div>
      </form>

      <BeforeUnloadBrainDialog
        when={!isEmpty(changes) && !demoMode}
        navigate={(location) => history.push(location)}
      />
    </>
  );
}

BrainForm.propTypes = {
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cancelUrl: PropTypes.string,
  fetchOptions: PropTypes.func,
  getValue: PropTypes.func,
  demoMode: PropTypes.bool,
  isNew: PropTypes.bool,
};
