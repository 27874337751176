import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import PassFailDescriptor from 'components/Descriptors/PassFail';

import classes from './styles.scss';

const divCss = css`
  margin-bottom: 30px;
`;

export default function ProcessInitiationFormEco({
  domain,
  isShowButton = true,
  status = true,
  templateType,
  shortName,
  isBreadcrumbsHeader = true,
}) {
  const handleChange = (state) => {
    const array = JSON.stringify(state);
    sessionStorage.setItem('typeDef', array);
  };

  return (
    <form className={classes.form}>
      <div css={divCss}>
        <PassFailDescriptor
          id={12}
          label="ECO type definition"
          descriptorState={{
            value: {
              conditionOptions: [
                'All components present',
                'Packaging box closes firmly',
                'All labels visible on and consistent with information on the Client Assembly Request Form (LOT and REF #)',
              ],
              items: [
                {
                  conditions: {
                    'Is this a minor enhancement to the FE/BE?': {},
                  },
                },
              ],
              showItemStatus: false,
              showAQLTable: false,
              showAddRow: false,
              showTableHeader: true,
              statusOptions: ['yes', 'no'],
              isExpandMore: false,
            },
          }}
          onChange={handleChange}
          domain={domain}
          className={classes.buttons}
          isShowButton={isShowButton}
          status={status}
          templateType={templateType}
          shortName={shortName}
          isBreadcrumbsHeader={isBreadcrumbsHeader}
        />
      </div>
    </form>
  );
}

ProcessInitiationFormEco.propTypes = {
  domain: PropTypes.string,
  isShowButton: PropTypes.bool,
  status: PropTypes.bool,
  isBreadcrumbsHeader: PropTypes.bool,
  templateType: PropTypes.string,
  shortName: PropTypes.string,
};
