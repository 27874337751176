import loadable from '@shared/utils/loadable';

import LoadingStateIcon from 'components/LoadingStateIcon';

import { initialState, resetFilters } from './ProcessTableManager/slice';

export default loadable(
  () => {
    // remove filters
    resetFilters(initialState);
    return import('./index');
  },
  { Fallback: LoadingStateIcon },
);
