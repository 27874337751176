import OpeningHoursField from '@shared/components/BrainForm/Fields/OpeningHoursField';

import TextField from './Fields/TextField';
import NumberField from './Fields/NumberField';
import AsyncMultiSelectField from './Fields/AsyncMultiselectField';
import AsyncSelectField from './Fields/AsyncSelectField';
import SelectField from './Fields/SelectField';
import TextareaField from './Fields/TextareaField';
import Checkboxfield from './Fields/CheckboxField';
import MultiSelectField from './Fields/MultiSelectField';

export const fieldDictionary = {
  text: TextField,
  number: NumberField,
  asyncSelect: AsyncSelectField,
  asyncMultiselect: AsyncMultiSelectField,
  Multiselect: MultiSelectField,
  select: SelectField,
  textarea: TextareaField,
  openingHours: OpeningHoursField,
  checkbox: Checkboxfield,
};
