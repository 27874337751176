import PropTypes from 'prop-types';

import classes from './styles.scss';

export default function UserLabel({ value, options }) {
  const user = options.find((o) => o.value === value);
  return (
    <div className={classes['user-label']}>
      <span>{user?.fullName}</span>
      <span>{user?.title ?? 'N/A'}</span>
    </div>
  );
}

UserLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};
