import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

import TrashCanIcon from '@shared/components/Icons/TrashCanIcon';
import BrainButton from '@shared/components/BrainButton';
import ConfirmationDialog from '@shared/components/BrainDialogs/ConfirmationDialog';

import BaseTextField from '../../Base/BaseTextField';

import classes from './styles.scss';

export const DEFAULT_DYNAMIC_LABEL = 'Related file';

const DynamicFileLabel = ({
  onDelete,
  onLabelChange,
  onDeleteMouseOver,
  onDeleteMouseOut,
  filename,
  label,
  disabled = false,
  isNew = false,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isOverTrash, setIsOverTrash] = useState(false);

  const labelState = useMemo(
    () => ({
      value: label,
    }),
    [label],
  );

  const onRequestDelete = () => {
    setOpenDialog(false);
    onDelete();
  };

  const onLabelBlur = (e) => {
    let newValue = e.target.value;
    if (newValue === '') {
      newValue = DEFAULT_DYNAMIC_LABEL;
    }
    onLabelChange(newValue);
  };

  return (
    <div className={classes.container}>
      <BaseTextField
        id="file-label"
        name="file-label"
        type="text"
        className={cc([classes.label, { [classes.labelDelete]: isOverTrash }])}
        disabled={disabled}
        settings={{
          underline: false,
          autofocus: isNew,
          placeholder: DEFAULT_DYNAMIC_LABEL,
        }}
        onBlur={onLabelBlur}
        descriptorState={labelState}
      />
      <BrainButton
        classNames={[classes.trash]}
        data-testid="delete-file-descriptor-button"
        aria-label="Remove File Slot"
        variant="text"
        isIconButton
        disabled={disabled}
        iconComponent={<TrashCanIcon />}
        onMouseOver={() => {
          if (!disabled) {
            setIsOverTrash(true);
            onDeleteMouseOver();
          }
        }}
        onMouseOut={() => {
          setIsOverTrash(false);
          onDeleteMouseOut();
        }}
        onClick={() => setOpenDialog(true)}
      />
      <ConfirmationDialog
        isOpen={openDialog}
        onCancel={() => setOpenDialog(false)}
        onConfirm={onRequestDelete}
        dialogTitle="Remove file slot"
        confirmationText="yes"
        cancellationText="no"
      >
        {filename
          ? `You are about to remove “${label}” file slot and archive the attached file “${filename}”. Are you sure? `
          : `You are about to remove an empty file slot “${label}”. Are you sure?`}
      </ConfirmationDialog>
    </div>
  );
};

DynamicFileLabel.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onLabelChange: PropTypes.func.isRequired,
  onDeleteMouseOver: PropTypes.func,
  onDeleteMouseOut: PropTypes.func,
  label: PropTypes.string,
  filename: PropTypes.string,
  disabled: PropTypes.bool,
  isNew: PropTypes.bool,
};

export default DynamicFileLabel;
