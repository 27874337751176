export const createPartnerProductMutation = /* GraphQL */ `
  mutation createPartnerProduct($partnerId: Int!, $productId: Int!, $folderId: String) {
    createPartnerProduct(
      input: {
        partnerProduct: {
          partnerId: $partnerId
          productId: $productId
          gDriveFolderId: $folderId
        }
      }
    ) {
      partnerProduct {
        partnerId
        productId
      }
    }
  }
`;

export const createPartnerProductSpecMutation = /* GraphQL */ `
  mutation createPartnerProductSpec(
    $partnerId: Int!
    $productId: Int!
    $createdBy: String!
  ) {
    createPartnerProductSpec(
      input: {
        partnerProductSpec: {
          partnerId: $partnerId
          productId: $productId
          createdBy: $createdBy
        }
      }
    ) {
      partnerProductSpec {
        id
        revision
        groups
        productSpec {
          version
        }
      }
    }
  }
`;

export const updatePartnerProductSpecMutation = /* GraphQL */ `
  mutation updatePartnerProductSpecById(
    $partnerProductSpecId: Int!
    $updatedPartnerProductSpec: JSON!
    $gDriveFolderId: String
    $specStatus: String
  ) {
    updatePartnerProductSpec(
      input: {
        id: $partnerProductSpecId
        patch: {
          groups: $updatedPartnerProductSpec
          gDriveFolderId: $gDriveFolderId
          status: $specStatus
        }
      }
    ) {
      partnerProductSpec {
        productSpec {
          version
        }
        id
        groups
        status
      }
    }
  }
`;

export const updateDescriptorMutationCreator = (inputName) => /* GraphQL */ `
  updateDescriptor(
    input: $${inputName}
  ) {
    descriptor {
      id
      name
      state
    }
  }
`;

// TODO: remove the request for `state` and only use `descriptorState: state`
export const fetchDescriptorsByPathQuery = /* GraphQL */ `
  query getProcesses(
    $processFilter: ProcessFilter
    $stageFilter: StageFilter
    $stepFilter: StepFilter
    $descriptorFilter: DescriptorFilter
    $first: Int
    $offset: Int
  ) {
    processes(
      filter: $processFilter
      first: $first
      offset: $offset
      orderBy: CREATED_AT_DESC
    ) {
      totalCount
      edges {
        node {
          id
          processNumber
          docName
          createdAt
          metadata
          template {
            shortname
          }
          stagesByProcessId(filter: $stageFilter) {
            edges {
              node {
                name
                id
                stepsByStageId(filter: $stepFilter) {
                  edges {
                    node {
                      name
                      id
                      descriptorsByStepId(filter: $descriptorFilter) {
                        edges {
                          node {
                            id
                            type
                            state
                            updatedAt
                            date
                            descriptorState: state
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const updateDescriptorMutation = ({
  descriptorId,
  descriptorState,
  date,
  options,
}) => ({
  mutation: updateDescriptorMutationCreator,
  inputType: 'UpdateDescriptorInput!',
  variables: {
    id: parseInt(descriptorId, 10),
    patch: {
      state: descriptorState,
      date,
      options: { strategy: 'merge', ...options },
    },
  },
});
