import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import classes from './styles.scss';

export default function BrainTooltip({
  title,
  children,
  placement = 'right',
  className,
  ...otherProps
}) {
  return (
    <Tooltip
      title={title}
      arrow
      placement={placement}
      classes={{
        popper: classes['brain-popper'],
        tooltip: classes['brain-tooltip'],
        arrow: classes['brain-tooltip-arrow'],
        tooltipPlacementLeft: classes.left,
        tooltipPlacementRight: classes.right,
        tooltipPlacementTop: classes.top,
        tooltipPlacementBottom: classes.bottom,
      }}
      {...otherProps}
    >
      <div className={className}>{children}</div>
    </Tooltip>
  );
}

BrainTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'left-start',
    'left',
    'left-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
  ]),
};
