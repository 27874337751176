import PropTypes from 'prop-types';

import XIcon from '@shared/components/Icons/XIcon';

import classes from './styles.scss';

export default function ClearIndicator({ innerProps }) {
  return <XIcon className={classes.clearIndicator} {...innerProps} />;
}

ClearIndicator.propTypes = {
  innerProps: PropTypes.object.isRequired,
};
