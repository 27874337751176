import { memo, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import invariant from 'invariant';
import conformsTo from 'lodash/conformsTo';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
import { useDropzone } from 'react-dropzone';

import { formatDistanceToNow } from '@shared/utils/dates';
import ConfirmationDialog from '@shared/components/BrainDialogs/ConfirmationDialog';
import BrainButton from '@shared/components/BrainButton';
import { triggerNotification } from '@shared/components/HealthySnackbarProvider';

import { reducer, initialState } from './reducer';
import TrashCanIcon from './icon-components/TrashCanIcon';
import UploadCloudIcon from './icon-components/UploadCloudIcon';
import ViewFileIcon from './icon-components/ViewFileIcon';
import ReplaceFileIcon from './icon-components/ReplaceFileIcon';
import classes from './styles.scss';

const shapeOfValueWithoutFile = {
  gDriveFolderId: isString,
  gDriveOldFilesFolderId: isString,
};

const shapeOfValueWithTempFolder = {
  isTempFile: isBoolean,
};

const shapeOfValueWithFile = {
  fileName: isString,
  uploadedBy: isString,
  gDriveId: isString,
  gDriveFolderId: isString,
  gDriveOldFilesFolderId: isString,
};

export function BrainFileUploader({
  label,
  value,
  uploadFile,
  deleteFile,
  clearFile,
  downloadFile,
  updatedAt,
  disabled = false,
  fileTypes,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // On mount and on value update, check validity and update state if needed
  useEffect(() => {
    const hasFile = conformsTo(value, shapeOfValueWithFile);
    const hasNoFile = conformsTo(value, shapeOfValueWithoutFile);
    const hasTempFolder = conformsTo(value, shapeOfValueWithTempFolder);

    if (hasFile) {
      dispatch({
        type: 'SET_FILE',
        fileName: value.fileName,
        username: value.uploadedBy.split(' ')[0],
        timeAgo: formatDistanceToNow(updatedAt),
      });
    } else if (hasNoFile || hasTempFolder) {
      const textContent = fileTypes ? `Upload  ${fileTypes} file` : 'Drop file or Browse';
      dispatch({ type: 'UNSET_FILE', textContent });
    } else {
      invariant(false, 'BrainFileUploader: Expected valid value');
    }
  }, [value]);

  const askToConfirm = (file) => {
    dispatch({ type: 'OPEN' });

    if (file.name) {
      dispatch({ type: 'REPLACE', file });
    } else {
      dispatch({ type: 'DELETE' });
    }
  };

  const onDrop = async (acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];

    if (!acceptedFile) {
      dispatch(
        triggerNotification({
          notification: {
            message: 'Invalid file type!',
            type: 'error',
          },
        }),
      );
      return;
    }

    if (state.hasFile) {
      askToConfirm(acceptedFile);
    } else {
      dispatch({ type: 'HOVER_END' });
      dispatch({ type: 'UPLOADING', fileName: acceptedFile.name });
      await uploadFile(acceptedFile);
    }
  };

  const onCancel = () => {
    dispatch({ type: 'CLOSE' });
  };

  const onConfirm = async () => {
    dispatch({ type: 'CLOSE' });
    if (state.file) {
      dispatch({ type: 'UPLOADING', fileName: state.file.name });
      await uploadFile(state.file);
    } else if (state.startEmpty) {
      dispatch({ type: 'UNSET_FILE' });
      clearFile();
    } else {
      dispatch({ type: 'DELETING', fileName: value.fileName });
      deleteFile();
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    multiple: false,
    disabled,
    accept: fileTypes, // '.PDF,image/jpeg, image/png,.txt'
  });

  const { Icon: StateIcon } = state;

  const showFileOptions = Boolean(
    state.hasFile || Boolean(state.startEmpty && state.isPending),
  );

  const replaceFileDialog = `Are you sure you want to replace ${value.fileName}?`;

  const removeFileDialog = `Are you sure you want to remove ${
    state.startEmpty ? 'unsaved file' : value.fileName
  }?`;

  return (
    <div
      className={cc([
        classes.BrainFileUploader,
        {
          [classes.disabled]: disabled,
          [classes.hasFile]: state.hasFile,
          [classes.hasPending]: state.isPending,
        },
      ])}
      data-testid="brain-file-uploader"
    >
      <div
        onMouseEnter={() => dispatch({ type: 'HOVER_START' })}
        onDragEnter={() => dispatch({ type: 'HOVER_START' })}
        onMouseLeave={() => dispatch({ type: 'HOVER_END' })}
        onDragLeave={() => dispatch({ type: 'HOVER_END' })}
      >
        <div
          data-testid="file-uploader-drag-n-drop"
          {...getRootProps({
            title: state.textContent,
            className: cc([
              classes.contentWrapper,
              {
                [classes.hovering]: state.isHovering,
                [classes.empty]: !state.isPending && !state.hasFile,
                [classes.dragging]: isDragActive,
                [classes.pending]: state.isPending,
              },
            ]),
          })}
        >
          <input {...getInputProps()} />
          <span className={classes.statusIcon}>
            {state.isHovering || isDragActive ? <UploadCloudIcon /> : <StateIcon />}
          </span>
          <div className={classes.textContent}>
            <span className={classes.text}>{state.textContent}</span>
            <span className={classes.infoText}>{state.infoTextContent}</span>
          </div>
        </div>
        {showFileOptions && (
          <div
            title={`${state.textContent}\n${state.infoTextContent}`}
            className={cc([
              classes.fileOptions,
              { [classes.hovering]: state.isHovering },
            ])}
          >
            <BrainButton
              {...getRootProps({
                refKey: 'innerRef',
                'aria-label': 'Replace',
                'data-testid': 'replace-file',
                title: 'Replace file',
                classNames: [classes.optionBtn, classes.replaceBtn],
                isIconButton: true,
                iconComponent: <ReplaceFileIcon />,
              })}
            />
            <BrainButton
              aria-label="Download"
              data-testid="download-file"
              title="View file"
              classNames={[classes.optionBtn, classes.viewBtn]}
              onClick={downloadFile}
              isIconButton
              iconComponent={<ViewFileIcon />}
            />
            <BrainButton
              aria-label="Remove file"
              data-testid="remove-file"
              title="Remove file"
              classNames={[classes.optionBtn, classes.trashBtn]}
              onClick={askToConfirm}
              isIconButton
              iconComponent={<TrashCanIcon />}
            />
          </div>
        )}
      </div>
      <span className={cc([classes.label, { [classes.invalidFile]: isDragReject }])}>
        {label}
      </span>
      <ConfirmationDialog
        isOpen={state.isModalShown}
        onCancel={onCancel}
        onConfirm={onConfirm}
        dialogTitle={`${state.file ? 'Replace' : 'Remove'} file`}
        confirmationText="yes"
        cancellationText="no"
      >
        {state.file ? replaceFileDialog : removeFileDialog}
      </ConfirmationDialog>
    </div>
  );
}

BrainFileUploader.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.object.isRequired,
  uploadFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  clearFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  disabled: PropTypes.bool,
  fileTypes: PropTypes.string,
  info: PropTypes.node,
};

export default memo(BrainFileUploader);
