import PropTypes from 'prop-types';
import cc from 'classcat';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import ClearRounded from '@material-ui/icons/ClearRounded';

import classes from './styles.scss';

const STATUSES = {
  fail: 'fail',
  pass: 'pass',
  na: 'N/A',
  yes: 'yes',
  no: 'no',
};

const checkStatuses = (statuses) => {
  if (some(statuses, (status) => !STATUSES[status])) {
    console.warn("One of the statuses you provided doesn't exist");
  }
};
const StatusSelector = ({
  onStatusChange,
  classNames = [],
  status,
  statusOptions = [STATUSES.pass, STATUSES.fail],
  isEcoTypeDefinition = false,
}) => {
  const isDefined = !isNil(status);
  const onClearSelected = () => onStatusChange(null);
  checkStatuses(statusOptions);
  return (
    <div
      className={cc([classes.footer, { [classes.cleared]: !isDefined }, ...classNames])}
    >
      {statusOptions.map((option) => (
        <button
          key={option}
          type="button"
          className={cc([
            classes.button,
            classes[`${option.replace('/', '')}Button`],
            {
              [classes.selected]: isDefined && option === status,
              [classes.hidden]: isDefined && option !== status,
            },
          ])}
          onClick={isDefined ? onClearSelected : () => onStatusChange(option)}
        >
          <span className={isEcoTypeDefinition ? classes.darkColor : classes.text}>
            {option}
          </span>
          {isDefined && option === status && !isEcoTypeDefinition && (
            <span className={classes.icon}>
              <ClearRounded />
            </span>
          )}
        </button>
      ))}
    </div>
  );
};

StatusSelector.propTypes = {
  onStatusChange: PropTypes.func.isRequired,
  classNames: PropTypes.array,
  status: PropTypes.string,
  statusOptions: PropTypes.array,
  isEcoTypeDefinition: PropTypes.bool,
};

export default StatusSelector;
