// https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
// https://stackoverflow.com/questions/6965107/converting-between-strings-and-arraybuffers/
export const arrayBufferToString = (buf) => {
  let str = '';
  const uintArray = new Uint8Array(buf);
  // Looping to avoid the RangeError and failing on long arrays
  const callstackLimit = 1024;
  for (let i = 0; i < uintArray.length; i += callstackLimit) {
    str += String.fromCharCode.apply(null, uintArray.slice(i, i + callstackLimit));
  }
  return str;
};

export const stringToArrayBuffer = (str) => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < str.length; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};
