import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import ClearIcon from '@shared/components/Icons/XIcon';
import useRandomString from '@shared/hooks/useRandomString';

import FieldLabel from '../../FieldLabel';

import classes from './styles.scss';

export const TYPE_NUMBER = 'number';
export const TYPE_TEXT = 'text';
export const TYPE_EMAIL = 'email';
export const TYPE_TEL = 'tel';

export default function BaseInputField({
  settings: { inputType, labelSettings },
  disabled = false,
  value,
  label,
  info,
  onChange,
  validate,
  extraInputProps,
  isRequired,
  fetchOptions,
  clearable,
  ...otherProps
}) {
  const [localValue, setLocalValue] = useState(value ?? '');

  useEffect(() => {
    setLocalValue(value ?? '');
  }, [value]);

  const [errMsg, setErrMsg] = useState();

  const localValidate = (newValue) => setErrMsg(validate?.(newValue));

  useEffect(() => {
    localValidate(localValue);
  }, [localValue]);

  const onLocalChange = (newValue) => {
    const typedValue = [TYPE_NUMBER, TYPE_TEL].includes(inputType)
      ? +newValue
      : newValue.toString();
    setLocalValue(typedValue);
    onChange(typedValue);
  };

  const inputId = useRandomString();
  return (
    <TextField
      classes={{
        root: classes.textField,
      }}
      InputLabelProps={{
        htmlFor: `${inputId}`,
        shrink: false,
        required: false,
        error: errMsg,
        classes: {
          root: classes.textLabel,
          focused: classes.focusedLabel,
          disabled: classes.disabled,
          error: classes.error,
        },
        'data-testid': `${inputType}-field-label`,
      }}
      inputProps={{
        id: `${inputId}`,
        'data-testid': `${inputType}-field`,
        ...extraInputProps,
        ...otherProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        classes: {
          root: classes.textInput,
          focused: classes.focusedInput,
          disabled: classes.disabled,
        },
        endAdornment: clearable && localValue && (
          <InputAdornment position="end">
            <IconButton
              onClick={() => onLocalChange('')}
              classes={{ root: classes.clearIcon }}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={inputType}
      id={inputId}
      label={
        <FieldLabel
          label={label}
          info={info}
          hasError={!!errMsg}
          settings={labelSettings}
        />
      }
      margin="none"
      value={localValue}
      onChange={(e) => {
        onLocalChange(e.target.value);
      }}
      error={errMsg}
      helperText={errMsg}
      onBlur={(e) => {
        localValidate(e.target.value);
      }}
      disabled={disabled}
      required={isRequired}
    />
  );
}

BaseInputField.propTypes = {
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    inputType: PropTypes.oneOf([TYPE_NUMBER, TYPE_TEXT, TYPE_EMAIL, TYPE_TEL]),
    labelSettings: PropTypes.object,
  }),
  extraInputProps: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.node,
  info: PropTypes.string,
  validate: PropTypes.func,
  isRequired: PropTypes.bool,
  fetchOptions: PropTypes.func,
  clearable: PropTypes.bool,
};
