export default function QmsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
      <g fill="none" fillRule="evenodd">
        <rect width="46" height="46" fill="#FFF" fillOpacity=".01" rx="3" />
        <g stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <path
            strokeLinecap="round"
            d="M24.907 8.5H12a1 1 0 0 0-1 1v27.989a1 1 0 0 0 1 1h23a1 1 0 0 0 1-1V18.421a1 1 0 0 0-.338-.75"
          />
          <path fill="currentColor" d="M25.286 8.5v8.47a1 1 0 0 0 1 1H36L25.286 8.5z" />
        </g>
        <text
          fill="currentColor"
          fontFamily="Roboto-Black, Roboto"
          fontSize="9"
          fontWeight="700"
          letterSpacing=".064"
          transform="translate(11 8)"
        >
          <tspan x="2.536" y="23">
            QMS
          </tspan>
        </text>
      </g>
    </svg>
  );
}
