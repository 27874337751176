import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import classes from './styles.scss';

export default function BrainCheckbox({
  label = '',
  value,
  checked = false,
  disabled = false,
  isRequired,
  onChange,
  ...otherProps
}) {
  return (
    <FormControlLabel
      classes={{
        root: classes['brain-checkbox'],
        label: classes.label,
        disabled: classes.disabled,
      }}
      label={label}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      {...otherProps}
      control={
        <Checkbox
          classes={{ root: classes['checkbox-btn'], checked: classes.checked }}
          icon={<div className={classes['checkbox-icon']} />}
          checkedIcon={
            <svg className={classes['checkbox-icon']}>
              <path
                fill="currentColor"
                transform="translate(-5, -5)"
                d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
              />
            </svg>
          }
          disableRipple
        />
      }
    />
  );
}

BrainCheckbox.propTypes = {
  label: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};
