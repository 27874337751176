import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import BrainSelect from '@shared/components/BrainSelect';

import DescriptorLabel from '../DescriptorLabel';

import classes from './styles.scss';

export default function SelectDescriptor({
  id,
  label,
  descriptorState,
  descriptorState: { value, options, info },
  onChange,
  disabled = false,
}) {
  const [selectedOption, setSelectedOption] = useState(
    value ? { value, label: value } : null,
  );

  useEffect(() => {
    setSelectedOption(value ? { value, label: value } : null);
  }, [descriptorState, value]);

  function handleChange(newSelectedOption) {
    setSelectedOption(newSelectedOption);

    const isInitialState = Boolean(
      newSelectedOption?.value === value || (!newSelectedOption && !value),
    );

    onChange({
      descriptorId: id,
      descriptorState: { value: newSelectedOption?.value },
      isInitialState,
    });
  }

  return (
    <div className={classes['select-descriptor']}>
      <BrainSelect
        label={<DescriptorLabel label={label} info={info} />}
        value={selectedOption}
        onChange={handleChange}
        options={options.map((o) => ({ label: o, value: o }))}
        disabled={disabled}
        isClearable
      />
    </div>
  );
}

SelectDescriptor.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.node.isRequired,
  descriptorState: PropTypes.shape({
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    info: PropTypes.node,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
