import PropTypes from 'prop-types';
import cc from 'classcat';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import classes from './styles.scss';

export default function BrainTextarea({
  value = '',
  classNames = [],
  disabled = false,
  ...otherProps
}) {
  return (
    <TextareaAutosize
      {...otherProps}
      value={value}
      className={cc([
        classes.textarea,
        {
          [classes.disabled]: disabled,
          [classes.filled]: value && value.trim(),
        },
        ...classNames,
      ])}
      rowsMin={2}
      disabled={disabled}
    />
  );
}

BrainTextarea.propTypes = {
  value: PropTypes.string,
  classNames: PropTypes.array,
  disabled: PropTypes.bool,
};
