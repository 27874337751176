import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';

import classes from './styles.scss';

export default function MultiValue({ children, removeProps, isDisabled }) {
  return (
    <Chip
      label={children}
      classes={{ root: classes.multiValue, label: classes.chipLabel }}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon />}
      disabled={isDisabled}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node.isRequired,
  removeProps: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
