import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';

import BaseInputField, { TYPE_NUMBER } from '../BaseInputField';

export default function NumberField({ settings = {}, ...restProps }) {
  const validate = (newValue) => {
    if (isNumber(settings.min) && newValue < settings.min) {
      return `Minimum ${settings.min}`;
    }
    if (isNumber(settings.min) && newValue > settings.max) {
      return `Maximum ${settings.max}`;
    }
    return null;
  };
  return (
    <BaseInputField
      settings={{ inputType: TYPE_NUMBER }}
      validate={validate}
      extraInputProps={{
        ...(isNumber(settings.min) && { min: settings.min }),
        ...(isNumber(settings.min) && { max: settings.max }),
      }}
      {...restProps}
    />
  );
}

NumberField.propTypes = {
  settings: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  value: PropTypes.number,
  label: PropTypes.node.isRequired,
  info: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
