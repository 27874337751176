import { useState } from 'react';

import BaseDialog from '@shared/components/BrainDialogs/BaseDialog';
import BrainButton from '@shared/components/BrainButton';

import classes from './styles.scss';

export default function AQLTable(props) {
  const [isOpen, setIsOpen] = useState(false);
  const openTable = () => setIsOpen(true);
  const closeTable = () => setIsOpen(false);
  return (
    <div {...props}>
      <BrainButton classNames={[classes.button]} variant="text" onClick={openTable}>
        <span>AQL Table</span>
      </BrainButton>
      <BaseDialog
        dialogTitle="AQL chart"
        isOpen={isOpen}
        onClose={closeTable}
        classes={{
          modal: classes.modal,
          content: classes.content,
          title: classes.title,
        }}
      >
        <table className={classes.table}>
          <tr className={classes.firstRow}>
            <td colSpan="3">Special inspection S-2, AQL 1% </td>
          </tr>
          <tr>
            <td>Lot size</td>
            <td>Quantity</td>
            <td>Ac-Re</td>
          </tr>
          <tr>
            <td>281-500</td>
            <td>13</td>
            <td>0-1</td>
          </tr>
          <tr>
            <td>501-1200</td>
            <td>13</td>
            <td>0-1</td>
          </tr>
          <tr>
            <td>1201-3200</td>
            <td>13</td>
            <td>0-1</td>
          </tr>
          <tr>
            <td>3201-10,000</td>
            <td>13</td>
            <td>0-1</td>
          </tr>
          <tr>
            <td>10,001-35,000</td>
            <td>13</td>
            <td>0-1</td>
          </tr>
          <tr>
            <td>35,001-150,000</td>
            <td>13</td>
            <td>0-1</td>
          </tr>
          <tr>
            <td>150,001-500,000</td>
            <td>13</td>
            <td>0-1</td>
          </tr>
          <tr>
            <td>500,001 and over</td>
            <td>13</td>
            <td>0-1</td>
          </tr>
        </table>
        <table className={classes.table}>
          <tr className={classes.firstRow}>
            <td colSpan="3">*If lot size is &#60;280</td>
          </tr>
          <tr>
            <td>Lot size</td>
            <td>Quantity</td>
            <td>Ac-Re</td>
          </tr>
          <tr>
            <td>1-280</td>
            <td>13</td>
            <td>0-1</td>
          </tr>
        </table>
      </BaseDialog>
    </div>
  );
}
