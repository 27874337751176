import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import BrainButton from '@shared/components/BrainButton';

import AQLTable from '../AQLTable';

const headerCss = css`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--veryLightGrey);
  padding-left: 20px;
  height: 53px;
  background-color: white;
  margin-top: 40px;
`;

const buttonCss = css`
  width: 170px;
  border-radius: 0;
  border-left: 1px solid var(--veryLightGrey);

  span {
    padding: 0 20px;
  }
`;
const spanCss = css`
  padding: 0 20px;
`;

const buttonsCss = css`
  width: 340px;
  display: flex;
  justify-content: flex-end;
`;
const DescriptorTableHeader = ({
  title,
  showAQLTable,
  showAddRow,
  addRowDisabled = false,
  addRowLabel = 'Add Item',
  onAddRow,
}) => (
  <div css={headerCss}>
    <span css={spanCss}>{title}</span>
    <span css={buttonsCss}>
      {showAQLTable && <AQLTable css={buttonCss} variant="text" />}
      {showAddRow && (
        <div css={buttonCss}>
          <BrainButton variant="text" onClick={onAddRow} disabled={addRowDisabled}>
            <span>&#43; {addRowLabel}</span>
          </BrainButton>
        </div>
      )}
    </span>
  </div>
);

DescriptorTableHeader.propTypes = {
  title: PropTypes.string,
  showAQLTable: PropTypes.bool,
  showAddRow: PropTypes.bool,
  addRowDisabled: PropTypes.bool,
  addRowLabel: PropTypes.string,
  onAddRow: PropTypes.func.isRequired,
};

export default DescriptorTableHeader;
