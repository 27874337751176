import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

/*
 * collectDeepNested
 *
 * Takes an object/array and deep collects all values under the supplied prop
 * Then returns a flat array of them
 *
 * For example, if you enter this:

  collectDeepNested(
    {
      wow: [1, 2, 3],
      lolz: {
        wow: [4, 5, 6],
      },
      cool: {
        lit: {
          wow: [7, 8, 9],
        },
      },
    },
    'wow',
  );

 * the expected result is this:
  [
    [4, 5, 6],
    [7, 8, 9],
    [1, 2, 3]
  ]

 * Please note that once this algorithm finds the prop it is looking for it collects it
 * and stops looking for child props inside it,
 * so if you have this structure:

  descriptors: [
    {
      type: "meta",
      state: {
        descriptors: [
          {},
          {},
        ]
      }
    },
  ]

 * only the top 'descriptors' prop will be collected,
 * the child 'descriptors' prop will not be added to the final array
 * (but will be reachable under the top 'descriptors' prop)
 *
 */
export default function collectDeepNested(input, prop) {
  let arr;
  let foundProp;

  switch (true) {
    case isArray(input):
      arr = input
        .map((item) => collectDeepNested(item, prop))
        .filter((item) => !!item)
        .flat(1);
      return arr.length > 0 ? arr : null;

    case isObject(input):
      if (input[prop]) {
        foundProp = input[prop];
      }

      arr = Object.keys(input)
        .map((item) => {
          if (item !== prop) return collectDeepNested(input[item], prop);
          return null;
        })
        .filter((item) => !!item)
        .flat(1);

      if (foundProp) {
        arr = [...arr, foundProp];
      }
      return arr.length > 0 ? arr : null;

    default:
      return null;
  }
}

export const flatCollectDeepNested = (structure, prop) =>
  collectDeepNested(structure, prop)?.flat(1);
