import { useState, useEffect } from 'react';

function useImageWithFallback({ imagePath, fallbackPath = '' }) {
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    const img = document.createElement('img');
    img.onload = () => mounted && setImageLoading(false);
    img.onerror = () => mounted && setImageError(true);
    img.src = imagePath;
    return () => {
      mounted = false;
    };
  }, [imagePath]);

  return {
    imageLoading,
    imageError,
    imagePath: imageLoading || imageError ? fallbackPath : imagePath,
  };
}

export default useImageWithFallback;
