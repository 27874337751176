export default function GoogleDriveLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20.571428"
      viewBox="0 0 24 20.571429"
    >
      <defs />
      <path fill="#ffc107" strokeWidth=".5714286" d="M8 0h8l8 13.714285h-8z" />
      <path
        fill="#1976d2"
        strokeWidth=".5714286"
        d="M3.9285714 20.571428l4.0360001-6.857143H24l-4 6.857143z"
      />
      <path
        fill="#4caf50"
        strokeWidth=".5714286"
        d="M0 13.785714l3.9285714 6.785714L12 6.8571432 8 0z"
      />
    </svg>
  );
}
