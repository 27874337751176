import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import BrainSelect from '@shared/components/BrainSelect';

import FieldLabel from '../../FieldLabel';

import classes from './styles.scss';

export default function AsyncMultiSelectField({
  label,
  info,
  value: values = [],
  settings: { queryName, valueOnly = true },
  onChange,
  disabled = false,
  fetchOptions,
  ...otherProps
}) {
  const [localValues, setLocalValues] = useState([]);
  useEffect(() => {
    setLocalValues(
      valueOnly ? values.map((item) => ({ label: '...', value: item })) : values,
    );
  }, [values]);

  const localOnChange = (newValue) => {
    setLocalValues(newValue);
    onChange(valueOnly ? newValue?.map((item) => item.value) : newValue);
  };

  const onOptionsReady = (options) => {
    if (valueOnly) {
      setLocalValues(options && options.filter((op) => values.includes(op.value)));
    }
  };

  return (
    <div className={classes['async-multi-select-descriptor']}>
      <BrainSelect
        label={<FieldLabel label={label} info={info} />}
        value={localValues}
        onChange={localOnChange}
        isMulti
        isAsync
        isClearable
        onOptionsReady={onOptionsReady}
        fetchOptions={() => fetchOptions(queryName)}
        disabled={disabled}
        {...otherProps}
      />
    </div>
  );
}

AsyncMultiSelectField.propTypes = {
  fetchOptions: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    queryName: PropTypes.string.isRequired,
    valueOnly: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.node.isRequired,
      }),
    ),
  ]),
  label: PropTypes.node.isRequired,
  info: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
