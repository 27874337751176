import { brainFlattenGqlRequest } from 'utils/gqlUtils';

import { locoRequest } from './locoGQlClient';

export const getLocationsQuery = /* GraphQL */ `
  query getLocations($first: Int, $offset: Int) {
    stores(orderBy: ID_DESC, first: $first, offset: $offset) {
      totalCount
      nodes {
        id
        name
        type
        country
        city
        postcode
        address
        active
        referenceNumber
        contactInformation
        courierNote
        comment
        schedulesByStoreId {
          nodes {
            id
            active
            dayOfWeek
            open
            close
            storeId
          }
        }
        storeProductsByStoreId {
          nodes {
            productId
            active
            amount
            product {
              name
            }
          }
        }
      }
    }
  }
`;

export const getLocationDataQuery = /* GraphQL */ `
  query GetStore($locationId: Int!) {
    store(id: $locationId) {
      id
      name
      type
      country
      city
      postcode
      address
      contactInformation
      active
      originalId
      courierNote
      referenceNumber
      comment
      schedulesByStoreId {
        nodes {
          active
          dayOfWeek
          open
          close
        }
      }
      storeProductsByStoreId {
        nodes {
          id
          productId
          active
          amount
          product {
            name
          }
        }
      }
    }
  }
`;

export const createLocationMutation = /* GraphQL */ `
  mutation createStore($storeInput: CreateStoreInput!) {
    createStore(input: $storeInput) {
      store {
        id
      }
    }
  }
`;

export const createSchedulesMutation = /* GraphQL */ `
  fragment createScheduleFragment on CreateSchedulePayload {
    schedule {
      id
    }
  }
  mutation createSchedules(
    $sunday: CreateScheduleInput!
    $monday: CreateScheduleInput!
    $tuesday: CreateScheduleInput!
    $wednesday: CreateScheduleInput!
    $thursday: CreateScheduleInput!
    $friday: CreateScheduleInput!
    $saturday: CreateScheduleInput!
  ) {
    sunday: createSchedule(input: $sunday) {
      ...createScheduleFragment
    }
    monday: createSchedule(input: $monday) {
      ...createScheduleFragment
    }
    tuesday: createSchedule(input: $tuesday) {
      ...createScheduleFragment
    }
    wednesday: createSchedule(input: $wednesday) {
      ...createScheduleFragment
    }
    thursday: createSchedule(input: $thursday) {
      ...createScheduleFragment
    }
    friday: createSchedule(input: $friday) {
      ...createScheduleFragment
    }
    saturday: createSchedule(input: $saturday) {
      ...createScheduleFragment
    }
  }
`;

export const createStoreProductMutation = /* GraphQL */ `
  mutation createStoreProduct(
    $item1: CreateStoreProductInput!
    $item2: CreateStoreProductInput!
  ) {
    item1: createStoreProduct(input: $item1) {
      storeProduct {
        id
      }
    }
    item2: createStoreProduct(input: $item2) {
      storeProduct {
        id
      }
    }
  }
`;

export const updateLocationMutationCreator = (inputName) => /* GraphQL */ `
    updateStore(input: $${inputName}) {
      store {
        id
      }
    }
`;

export const updateScheduleMutationCreator = (inputName) => /* GraphQL */ `
  updateScheduleByStoreIdAndDayOfWeek(input: $${inputName}) {
    clientMutationId
  }
`;

export const updateStoreProductMutationCreator = (inputName) => /* GraphQL */ `
  updateStoreProductByStoreIdAndProductId(input: $${inputName}) {
    storeProduct {
      id
    }
  }
`;

const asyncSelectQueries = {
  locoItems: /* GraphQL */ `
    query getItems {
      options: products {
        nodes {
          id
          name
        }
      }
    }
  `,
};

/**
 * Fetches options for different asyncSelect fields.
 * @param inputName
 * @param params
 * @returns {Promise<*[]|*>}
 */
export default async function locoFetchOptions(inputName, params = {}) {
  try {
    const queryName = params.sourceQuery ?? inputName;
    const response = await locoRequest(asyncSelectQueries[queryName]);
    const { options } = brainFlattenGqlRequest(response);
    return options.map((o) => ({
      label: o.name,
      value: o.id,
    }));
  } catch (err) {
    console.error(err);
    return [];
  }
}
