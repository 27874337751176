import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import ErrorOutlineRounded from '@material-ui/icons/ErrorOutlineRounded';
import CircularProgress from '@material-ui/core/CircularProgress';

import useNetworkStateHelper from '@shared/hooks/useNetworkStateHelper';

import classes from './styles.scss';

function ErrorMode({
  errorText = '',
  errorDismissButtonText = 'close',
  onErrorDismiss = () => {},
}) {
  return (
    <>
      <ErrorOutlineRounded
        fontSize="large"
        classes={{ fontSizeLarge: classes['error-icon'] }}
        viewBox="2 2 20 20"
      />
      {errorText && <p>{errorText}</p>}
      <Button
        type="button"
        className={classes['dismiss-error-button']}
        onClick={onErrorDismiss}
        autoFocus
        variant="outlined"
      >
        {errorDismissButtonText}
      </Button>
    </>
  );
}

function LoadingMode({ loadingText = '' }) {
  return (
    <>
      <CircularProgress size={64} classes={{ root: classes['circular-progress'] }} />
      {loadingText && <p>{loadingText}</p>}
    </>
  );
}

export default function FullPageLoader({
  isLoading = false,
  hasError = false,
  invisibleBackdrop = false,
  color = 'var(--brain-primary)',
  loadingText = '',
  errorText = '',
  errorDismissButtonText = 'close',
  onErrorDismiss = () => {},
}) {
  const { showFallback, fallbackComponent } = useNetworkStateHelper(
    {
      loading: isLoading && !hasError,
      error: hasError,
      loadingComponent: () => <LoadingMode loadingText={loadingText} />,
      errorComponent: () => (
        <ErrorMode
          errorText={errorText}
          onErrorDismiss={onErrorDismiss}
          errorDismissButtonText={errorDismissButtonText}
        />
      ),
    },
    [loadingText, errorText],
  );

  return (
    <Backdrop
      classes={{ root: classes.backdrop, invisible: classes.invisibleBackdrop }}
      open={showFallback}
      invisible={invisibleBackdrop}
      style={{ '--color': color }}
      data-testid="full-page-loader-backdrop"
    >
      <div className={classes['loading-status']}>{fallbackComponent}</div>
    </Backdrop>
  );
}

FullPageLoader.propTypes = {
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  invisibleBackdrop: PropTypes.bool,
  color: PropTypes.string,
  loadingText: PropTypes.string,
  errorText: PropTypes.string,
  errorDismissButtonText: PropTypes.string,
  onErrorDismiss: PropTypes.func,
};

LoadingMode.propTypes = {
  loadingText: PropTypes.string,
};

ErrorMode.propTypes = {
  errorText: PropTypes.string,
  errorDismissButtonText: PropTypes.string,
  onErrorDismiss: PropTypes.func,
};
