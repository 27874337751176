// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.hNtoGAb1 {\n  position: relative;\n  display: flex;\n  flex-direction: column-reverse;\n}\n\n  div.hNtoGAb1 .N-Yz3ych {\n    position: relative;\n    color: var(--defaultBlack);\n    font-size: 13px;\n    letter-spacing: 0.1px;\n    line-height: 16px;\n    transform: unset;\n    margin-top: 3px\n  }\n\n  div.hNtoGAb1 .N-Yz3ych._1m597dBU {\n      color: var(--brain-primary);\n    }\n\n  div.hNtoGAb1 .N-Yz3ych._3yVuE7Lw {\n      color: var(--lightGreyText);\n    }\n\n  div.hNtoGAb1 ._3apYrFCf {\n    color: var(--defaultBlack);\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    line-height: 17px;\n    margin: 0\n  }\n\n  div.hNtoGAb1 ._3apYrFCf::before,\n    div.hNtoGAb1 ._3apYrFCf:hover::before {\n      border-bottom: 1px solid var(--defaultBlack);\n    }\n\n  div.hNtoGAb1 ._3apYrFCf::after {\n      border-bottom: 1px solid var(--brain-primary);\n      transition: none;\n    }\n\n  div.hNtoGAb1 ._3apYrFCf._1m597dBU::before {\n        border-bottom: 1px solid var(--brain-primary);\n      }\n\n  div.hNtoGAb1 ._3apYrFCf._1m597dBU::after {\n        transform: unset;\n      }\n\n  div.hNtoGAb1 ._3apYrFCf._3yVuE7Lw::before {\n      border-bottom: 1px solid var(--lightGrey);\n    }\n\n  div.hNtoGAb1 ._3apYrFCf ._24VWO6M- {\n      display: flex;\n      height: max-content;\n      max-height: 68px;\n      padding: 0 0 3px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textField": "hNtoGAb1",
	"label": "N-Yz3ych",
	"focused": "_1m597dBU",
	"disabled": "_3yVuE7Lw",
	"inputField": "_3apYrFCf",
	"input": "_24VWO6M-"
};
export default ___CSS_LOADER_EXPORT___;
