import { GraphQLClient } from 'graphql-request';

let client;

export function initLocoGqlClient(idToken) {
  client = new GraphQLClient(process.env.LOCO.GRAPHQL_API_URL, {
    headers: { ...(idToken && { authorization: `Bearer ${idToken}` }) },
  });
}

export function locoRequest(query, ...args) {
  return client.request(query, ...args);
}
