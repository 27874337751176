export default function OpenTasksIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FFF" fillOpacity=".01" d="M0 0H37V37H0z" />
          <rect
            width="19"
            height="18"
            x="9"
            y="10"
            stroke="currentColor"
            strokeWidth="2"
            rx="1.6"
          />
          <circle cx="14" cy="15" r="1" fill="currentColor" />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
            d="M24 15L18 15"
          />
          <circle cx="14" cy="19" r="1" fill="currentColor" />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
            d="M24 19L18 19"
          />
          <circle cx="14" cy="23" r="1" fill="currentColor" />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
            d="M24 23L18 23"
          />
        </g>
      </g>
    </svg>
  );
}
