import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import GoogleDriveLink from 'components/GoogleDriveLink';
import PdfLink from 'components/PdfLink';

import BraincCopyIcon from '../BraincCopyIcon';

import classes from './styles.scss';

export default function BreadcrumbsHeader({
  breadCrumbs = [],
  title = '',
  subtitle = '',
  gDriveFolderId,
  pdfLink,
  trashComponent,
  showBraincCopyIcon,
}) {
  return (
    <div className={classes['breadcrumbs-header']}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <h2 className={classes.breadcrumbs} data-testid="process-breadcrumbs">
        {breadCrumbs.map((crumb, idx) => (
          <span key={idx}>
            {crumb.path ? (
              <>
                <Link to={crumb.path}>{crumb.label}</Link>
                {` / `}
              </>
            ) : (
              <>
                {crumb.label}
                {` / `}
              </>
            )}
          </span>
        ))}
      </h2>
      <div className={classes.title}>
        <h1 data-testid="breadcrumbs-title">
          {title}
          {trashComponent}
          {showBraincCopyIcon && <BraincCopyIcon text={title} />}
        </h1>

        <div className={classes.links}>
          {gDriveFolderId && <GoogleDriveLink gDriveFolderId={gDriveFolderId} />}
          {pdfLink && <PdfLink pdfLink={pdfLink} />}
        </div>
      </div>
      {subtitle && <h2>{subtitle}</h2>}
    </div>
  );
}

BreadcrumbsHeader.propTypes = {
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  gDriveFolderId: PropTypes.string,
  pdfLink: PropTypes.string,
  trashComponent: PropTypes.node,
  showBraincCopyIcon: PropTypes.bool,
};
