import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'date-fns/isEqual';
import parseISO from 'date-fns/parseISO';

import DateFilter from '@shared/components/Filters/DateFilter';
import { invertedDateNoTime, invertedDateFormatWithTime } from '@shared/utils/dates';

import DescriptorLabel from '../DescriptorLabel';

import classes from './styles.scss';

const formatDate = (unformattedDate) =>
  unformattedDate ? new Date(unformattedDate) : null;

const dateFieldsToUpdate = [
  'Lot Expiration date',
  'Assembly completion estimation',
  'Expiration date',
  'L2 Expiration date',
];

/**
 * Based on `DateFilter` component with custom usage for BRAIN
 *
 * Based on [react-flatpicker](https://github.com/haoxins/react-flatpickr#readme)
 */
export default function DateDescriptor({
  id,
  name,
  label,
  descriptorState,
  descriptorState: { value, info, saveDateOnMeta, enableTime },
  onChange,
  disabled = false,
}) {
  const [date, setDate] = useState(formatDate(value));

  const dateFormat = enableTime ? invertedDateFormatWithTime : invertedDateNoTime;

  useEffect(() => {
    setDate(formatDate(value) ?? null);
  }, [descriptorState, value]);

  const getNewDate = (newDate, fieldName, fieldList) => {
    const needToChangeDate = fieldList.find((field) => field === fieldName);
    if (!needToChangeDate) {
      return newDate;
    }
    const formattedNewDate = formatDate(newDate);
    const year = formattedNewDate.getFullYear();
    const month = formattedNewDate.getMonth();
    const day = formattedNewDate.getDate();
    return new Date(year, month, day, 23, 59, 0).toISOString();
  };

  function onInputChange(e) {
    setDate(formatDate(e.newValue));
    const isInitialState = Boolean(
      (!e.newValue && !value) ||
        (!!e.newValue && !!value && isEqual(parseISO(e.newValue), parseISO(value))),
    );

    onChange({
      descriptorId: id,
      descriptorState: {
        value: getNewDate(e.newValue, name, dateFieldsToUpdate),
        saveDateOnMeta,
      },
      isInitialState,
    });
  }

  return (
    <DateFilter
      value={date}
      label={<DescriptorLabel label={label} info={info} />}
      name={name}
      onChange={onInputChange}
      classArray={[classes.dateWrapper]}
      dateFormat={dateFormat}
      enableTime={enableTime}
      disabled={disabled}
    />
  );
}

DateDescriptor.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  descriptorState: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    info: PropTypes.node,
    saveDateOnMeta: PropTypes.bool,
    enableTime: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
