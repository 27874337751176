// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3pr0AdRq {\n  display: flex;\n  flex-direction: row;\n}\n\n  ._3pr0AdRq ._41Y8Z8Wp {\n    flex: 1\n  }\n\n  ._3pr0AdRq ._41Y8Z8Wp._7UCGJtaz input {\n      color: var(--red);\n    }\n\n  ._3pr0AdRq ._41Y8Z8Wp input {\n      font-size: 13px;\n    }\n\n  ._3pr0AdRq ._3o3wPrhA input {\n    color: var(--lightGrey);\n  }\n\n  ._3pr0AdRq ._3KZ55svR svg {\n    visibility: hidden;\n    width: 15px;\n    height: 15px\n  }\n\n  ._3pr0AdRq ._3KZ55svR svg:hover {\n      color: var(--red);\n    }\n\n._3pr0AdRq:hover ._3KZ55svR svg {\n      visibility: visible;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3pr0AdRq",
	"label": "_41Y8Z8Wp",
	"labelDelete": "_7UCGJtaz",
	"disabled": "_3o3wPrhA",
	"trash": "_3KZ55svR"
};
export default ___CSS_LOADER_EXPORT___;
