/*
* These claims were signed and base64-encoded into a complete JSON Web Token, using this: http://jwtbuilder.jamiekurtz.com/
{
    "iss": "healthy.io",
    "iat": 1679411135,
    "exp": 1900249535,
    "aud": "healthy.io",
    "sub": "brain-test@healthy.io",
    "name": "BRAIN Testing GENERIC",
    "email": "brain-test@healthy.io"
}

* The signed JWT is saved as 'testIdToken'
*/
export const testIdToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJoZWFsdGh5LmlvIiwiaWF0IjoxNjc5NDExMTM1LCJleHAiOjE5MDAyNDk1MzUsImF1ZCI6ImhlYWx0aHkuaW8iLCJzdWIiOiJicmFpbi10ZXN0QGhlYWx0aHkuaW8iLCJuYW1lIjoiQlJBSU4gVGVzdGluZyBHRU5FUklDIiwiZW1haWwiOiJicmFpbi10ZXN0QGhlYWx0aHkuaW8ifQ.Few7ZlSjM1I0Oo-ADDkwoMcmHK_TQPxOjsk45lBjxFM';
export const testUserEmail = 'brain-test@healthy.io';
export const testAccessTokenDecoded = {
  access_token:
    'ya29.a0AVvZVsoeuWmRkBftjz7zkSIjf1_eCE4g-qQAcGv0C3pJm8ySIRMAB3Xr6jTqsX65gaYPWrwNCw3cs67U-tyYbI3yQE-g6DKYqUXhup_wiZ44xyTA5jQB2n-9hq_eCxvZsIlsoHXF2qfGthApVmYjLmVS3fN4TQaCgYKAfoSARMSFQGbdwaIYFdeBUfm13LnNll9R7he6A0165',
  authuser: '0',
  expires_in: 3599,
  hd: 'healthy.io',
  prompt: 'none',
  scope:
    'email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/drive',
  token_type: 'Bearer',
};
