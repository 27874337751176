// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2_syj9K3 {\n  position: relative;\n  width: 388px;\n  border-radius: 3px;\n  color: var(--defaultBlack);\n  font-size: 14px;\n}\n\n  ._2_syj9K3 button._1pJlOXxc {\n    --btn-size: 20px;\n\n    position: absolute;\n    right: 20px;\n    top: 20px;\n    color: var(--defaultBlack)\n  }\n\n  ._2_syj9K3 button._1pJlOXxc:hover {\n      color: var(--darkGrey);\n      background-color: var(--veryLightGrey);\n    }\n\n  ._2_syj9K3 button._1pJlOXxc:active,\n    ._2_syj9K3 button._1pJlOXxc:focus {\n      color: var(--darkGrey);\n      background-color: var(--lightGrey);\n    }\n\n  ._2_syj9K3 div._2akGplqR {\n    padding: 20px;\n  }\n\n  ._2_syj9K3 div._2akGplqR h3._1DVAE6Hg {\n      margin: 0 0 14px;\n      color: var(--defaultBlack);\n      font-size: 20px;\n      font-weight: 500;\n      line-height: 1;\n      letter-spacing: 0.14px\n    }\n\n  ._2_syj9K3 div._2akGplqR h3._1DVAE6Hg::first-letter {\n        text-transform: uppercase;\n      }\n\n  ._2_syj9K3 div._2akGplqR p._2TjNO4Hm {\n      margin: 0;\n      color: var(--darkGrey);\n      font-size: 14px;\n      letter-spacing: 0.1px;\n      line-height: 1.71;\n      word-wrap: break-word;\n      word-break: break-word;\n    }\n\n  ._2_syj9K3 div.yF7Jq5FJ {\n    display: flex;\n    justify-content: flex-end;\n    padding: 9px;\n    border-top: 1px solid var(--veryLightGrey);\n  }\n\n  ._2_syj9K3 div.yF7Jq5FJ ._11AIaeq3 {\n      height: 33px\n    }\n\n  ._2_syj9K3 div.yF7Jq5FJ ._11AIaeq3:not(:first-of-type) {\n        margin-left: 9px;\n      }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "_2_syj9K3",
	"closeButton": "_1pJlOXxc",
	"dialog-content": "_2akGplqR",
	"modalTitle": "_1DVAE6Hg",
	"modalText": "_2TjNO4Hm",
	"buttonsContainer": "yF7Jq5FJ",
	"dialog-button": "_11AIaeq3"
};
export default ___CSS_LOADER_EXPORT___;
