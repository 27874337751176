// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a._2LR9Zf5K {\n  --btn-primary-color: var(--brain-primary)\n}\na._2LR9Zf5K:hover {\n    --btn-dark-color: var(--brain-dark);\n  }\na._2LR9Zf5K:active {\n    --btn-very-light-color: var(--brain-very-light);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"google-drive-link": "_2LR9Zf5K"
};
export default ___CSS_LOADER_EXPORT___;
