// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1DhjZM3_ {\n  width: 388px;\n  border-collapse: collapse;\n  border-bottom: 1px solid var(--veryLightGrey);\n  margin-top: 17px;\n}\n\n  ._1DhjZM3_ tr {\n    height: 35px;\n    font-size: 13px;\n    color: var(--darkGrey);\n  }\n\n  ._1DhjZM3_ tr:nth-child(odd) {\n    background: var(--veryLightGrey);\n  }\n\n  ._1DhjZM3_ tr:nth-child(even) {\n    background: var(--white);\n  }\n\n  ._1DhjZM3_ tr._1jhKEhbk {\n    font-weight: bold;\n    background: #fff;\n  }\n\n  ._1DhjZM3_ td {\n    padding: 0 20px;\n  }\n\n._1xfNMgk9 div._2kOFIvFd {\n    padding: 0;\n  }\n\n._1xfNMgk9 div._2kOFIvFd h3._13RmhlML {\n      padding: 15px 20px;\n      margin: 0;\n      border-bottom: 1px solid var(--veryLightGrey);\n    }\n\n._3MHwxBfD {\n  width: 100%;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "_1DhjZM3_",
	"firstRow": "_1jhKEhbk",
	"modal": "_1xfNMgk9",
	"content": "_2kOFIvFd",
	"title": "_13RmhlML",
	"button": "_3MHwxBfD"
};
export default ___CSS_LOADER_EXPORT___;
