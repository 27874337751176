/**
 *
 * Asynchronously loads the component for PRListPage
 *
 */

import loadable from '@shared/utils/loadable';

import LoadingStateIcon from '../../components/LoadingStateIcon';

export default loadable(() => import('./index'), { Fallback: LoadingStateIcon });
