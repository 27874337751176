import PropTypes from 'prop-types';
import cc from 'classcat';
import MenuItem from '@material-ui/core/MenuItem';

import UserLabel from '../UserLabel';

import classes from './styles.scss';

export default function Option({
  children,
  isFocused = false,
  isSelected = false,
  innerRef,
  innerProps,
  selectProps: { isUser, options },
  data: { value },
}) {
  return (
    <MenuItem
      ref={innerRef}
      classes={{
        root: cc([classes.option, { [classes.focused]: isFocused }]),
        selected: classes.selected,
      }}
      component="div"
      selected={isSelected}
      disableRipple
      {...innerProps}
    >
      {isUser ? <UserLabel value={value} options={options} /> : children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node.isRequired,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerRef: PropTypes.func,
  innerProps: PropTypes.object.isRequired,
  data: PropTypes.object,
  selectProps: PropTypes.shape({
    isUser: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
  }).isRequired,
};
