import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

export const userDataSelector = createSelector(
  (store) => store.auth.userData,
  (userData) => userData,
);

export default function useUserData() {
  const userData = useSelector(userDataSelector);
  return userData;
}
