// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2qmBpAYS {\n  width: 16px;\n  height: 17px;\n  line-height: 17px;\n  cursor: help;\n  color: #538aea;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brain-tooltip-trigger": "_2qmBpAYS"
};
export default ___CSS_LOADER_EXPORT___;
