// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2U9vwv6z {\n  padding: 200px 0;\n  width: 764px;\n  text-align: center;\n  margin: 0 auto;\n}\n\n  ._2U9vwv6z ._1hlcFHc6 {\n    width: 235px;\n    height: 337px;\n  }\n\n  ._2U9vwv6z ._11ctVVKo {\n    font-size: 14px;\n    line-height: 1.57;\n    letter-spacing: 0.1px;\n    margin: 0 auto;\n    width: -moz-fit-content;\n    width: fit-content;\n    max-width: 764px;\n    overflow-x: hidden;\n    text-align: left\n  }\n\n  ._2U9vwv6z ._11ctVVKo > * {\n      width: -moz-fit-content;\n      width: fit-content;\n    }\n\n  ._2U9vwv6z ._11ctVVKo ._2En5-0Jg {\n      font-weight: bold;\n    }\n\n  ._2U9vwv6z ._11ctVVKo ._3hfd7kSE {\n      font-size: 0.9em;\n      white-space: pre;\n      max-height: 150px;\n      overflow: scroll;\n    }\n\n  ._2U9vwv6z ._3CgObwex {\n    margin: 22px 0 47px 0;\n    font-size: 13px;\n    letter-spacing: 0.5px;\n  }\n\n  ._2U9vwv6z hr {\n    width: 100%;\n    height: 1px;\n    border: solid 1px var(--lightGrey);\n  }\n\n  ._2U9vwv6z ._1wIp9waq {\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    font-weight: bold;\n    line-height: 1.56px;\n    margin-top: 19px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2U9vwv6z",
	"robot": "_1hlcFHc6",
	"inner-text": "_11ctVVKo",
	"error-title": "_2En5-0Jg",
	"stack": "_3hfd7kSE",
	"button": "_3CgObwex",
	"bottom-text": "_1wIp9waq"
};
export default ___CSS_LOADER_EXPORT___;
