/*
 *
 * App reducer
 *
 */

import produce from 'immer';

import { UPDATE_USER_DATA, CLEAR_USER_DATA } from './actions';

export const initialState = {
  userData: {},
};

const authReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      draft.userData = { ...draft.userData, ...action.userData };
      break;
    case CLEAR_USER_DATA:
      draft.userData = {};
      break;
  }
}, initialState);

export default authReducer;
