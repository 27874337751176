import { useDispatch } from 'react-redux';

import { downloadFileFromGDrive } from '@shared/utils/googleDriveHelpers';
import { triggerNotification } from '@shared/components/HealthySnackbarProvider';

export default function useDownloadFromGDrive() {
  const dispatch = useDispatch();

  return async (state) => {
    const url = await downloadFileFromGDrive({
      fileId: state.gDriveId,
      fileName: state.fileName,
    });

    if (!url) {
      dispatch(
        triggerNotification({
          notification: {
            message: 'Failed to download file',
            type: 'error',
          },
        }),
      );
    }
  };
}
