export const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const defaultDay = (dayOfWeek, active = false) => ({
  dayOfWeek,
  open: '08:00',
  close: '20:00',
  active,
});

export const defaultLocation = {
  active: true,
  address: {},
  contactInformation: {},
  country: 'UK',
  city: 'London',
  items: [],
  openingHours: [
    defaultDay(1),
    defaultDay(2),
    defaultDay(3),
    defaultDay(4),
    defaultDay(5),
    defaultDay(6),
    defaultDay(0),
  ],
};

export const tableFields = [
  {
    label: 'Location Name',
    name: 'name',
    type: 'text',
  },
  {
    label: 'Location Type',
    name: 'type',
    type: 'text',
  },
  {
    label: 'Items',
    name: 'items',
    type: 'list',
    subField: 'label',
  },
  {
    label: 'Address',
    name: 'address.street',
    type: 'text',
  },
  {
    label: 'Opening Hours',
    name: 'openingHours',
    type: 'openingHours',
  },
  {
    label: 'Comment',
    name: 'comment',
    type: 'text',
    leaveEmpty: true,
    useTooltip: true,
  },
  {
    label: 'Status',
    name: 'active',
    type: 'status',
  },
];

export const locoFormFields = [
  {
    label: 'Location Status',
    type: 'group',
    children: [
      {
        name: 'active',
        label: 'Status',
        type: 'select',
        settings: {
          boolSupport: true,
          options: [
            {
              label: 'Active',
              value: true,
              style: {
                fontWeight: 'bold',
                color: 'var(--green)',
                textTransform: 'uppercase',
              },
            },
            {
              label: 'Inactive',
              value: false,
              style: {
                fontWeight: 'bold',
                color: 'var(--darkGrey)',
                textTransform: 'uppercase',
              },
            },
          ],
        },
      },
    ],
  },
  {
    label: 'Pick-up details',
    type: 'group',
    children: [
      {
        name: 'name',
        label: 'Location name',
        type: 'text',
      },
      {
        name: 'type',
        label: 'Location type',
        type: 'select',
        settings: {
          options: [
            { label: 'Pharmacy', value: 'Pharmacy' },
            { label: 'Business', value: 'Business' },
            { label: 'Private', value: 'Private' },
          ],
        },
      },
      {
        name: 'items',
        label: 'Item',
        type: 'asyncMultiselect',
        settings: {
          valueOnly: false,
          queryName: 'locoItems',
        },
      },
    ],
  },
  {
    label: 'Pick-up address',
    type: 'group',
    children: [
      {
        name: 'country',
        label: 'Country',
        type: 'select',
        settings: {
          options: [{ label: 'UK', value: 'UK' }],
        },
      },
      {
        name: 'city',
        label: 'City',
        type: 'select',
        settings: {
          options: [{ label: 'London', value: 'London' }],
        },
      },
      {
        name: 'address.street',
        label: 'Address',
        type: 'text',
      },
      {
        name: 'postcode',
        label: 'Zip Code',
        type: 'text',
      },
      {
        name: 'address.additionalInfo',
        label: 'Additional info',
        type: 'text',
        isRequired: false,
      },
      {
        name: 'referenceNumber',
        label: 'Ref ID',
        type: 'text',
      },
      {
        name: 'originalId',
        label: 'Store / location ID',
        type: 'text',
      },
    ],
  },
  {
    label: 'Courier communication',
    type: 'group',
    children: [
      {
        name: 'courierNote',
        label: 'Courier note',
        type: 'textarea',
      },
    ],
  },
  {
    label: 'Contact details',
    type: 'group',
    children: [
      {
        name: 'contactInformation.name',
        label: 'Contact name',
        type: 'text',
      },
      {
        name: 'contactInformation.position',
        label: 'Contact position',
        type: 'text',
      },
      {
        name: 'contactInformation.phonenumber',
        label: 'Contact phone',
        type: 'text',
      },
      {
        name: 'contactInformation.emailaddress',
        label: 'Contact email',
        type: 'text',
      },
    ],
  },
  {
    label: 'Opening hours',
    type: 'group',
    info: 'The item will be picked up from the location up to 2 hours before closing time.',
    children: [
      {
        name: 'openingHours[0]',
        label: WEEK_DAYS[1],
        type: 'openingHours',
      },
      {
        name: 'openingHours[1]',
        label: WEEK_DAYS[2],
        type: 'openingHours',
      },
      {
        name: 'openingHours[2]',
        label: WEEK_DAYS[3],
        type: 'openingHours',
      },
      {
        name: 'openingHours[3]',
        label: WEEK_DAYS[4],
        type: 'openingHours',
      },
      {
        name: 'openingHours[4]',
        label: WEEK_DAYS[5],
        type: 'openingHours',
      },
      {
        name: 'openingHours[5]',
        label: WEEK_DAYS[6],
        type: 'openingHours',
      },
      {
        name: 'openingHours[6]',
        label: WEEK_DAYS[0],
        type: 'openingHours',
      },
    ],
  },
  {
    label: 'Other',
    info: 'This comment is for Healthy.io internal use and is not visible to the courier',
    type: 'group',
    children: [
      {
        name: 'comment',
        label: 'Comment',
        type: 'textarea',
        isRequired: false,
      },
    ],
  },
];
