import PropTypes from 'prop-types';

import DateFilterBase from './DateFilterBase';

/**
 * Use the `DateFilter` component when you need to include a date picker
 * Based on [react-flatpicker](https://github.com/haoxins/react-flatpickr#readme)
 *
 * Uses internally the following custom options:
 * `{ inline: true, clickOpens: false }`
 *
 * Find out more [options](https://flatpickr.js.org/options/)
 */
const DateFilter = ({
  label,
  value = null,
  name,
  onChange,
  classArray = [],
  dateFormat = 'LLL do yyyy',
  fontSize = 14,
  placeholder = '',
  disabled = false,
  enableTime,
}) => (
  <DateFilterBase
    label={label}
    value={value}
    name={name}
    onChange={onChange}
    classArray={classArray}
    dateFormat={dateFormat}
    fontSize={fontSize}
    placeholder={placeholder}
    variant="default"
    disabled={disabled}
    enableTime={enableTime}
  />
);
DateFilter.propTypes = {
  /** Sets label & placeholder attribute for the input */
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  /** Consists of 1 unix based timestamp - can also be null or undefined */
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  /** Handler for changing the dates. ({ name, newValue }) => void  */
  onChange: PropTypes.func.isRequired,
  /** Sets extra CSS classes for your needs */
  classArray: PropTypes.array,
  dateFormat: PropTypes.string,
  fontSize: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  enableTime: PropTypes.bool,
};

export default DateFilter;
