// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n  ._3yAMT9M4[focus-within] ._3yTLCVkz {\n      color: var(--brain-primary);\n    }\n\n._3yAMT9M4[focus-within] ._3yTLCVkz {\n      color: var(--brain-primary);\n    }\n\n._3yAMT9M4:focus-within ._3yTLCVkz {\n      color: var(--brain-primary);\n    }\n\nlabel._3yTLCVkz {\n  color: var(--defaultBlack);\n  display: block;\n  font-size: 13px;\n  letter-spacing: 0.1px;\n  line-height: 16px;\n  transform: unset;\n  margin-top: 3px\n}\n\nlabel._3yTLCVkz._2D4yvaYh {\n    color: var(--lightGreyText);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-field": "_3yAMT9M4",
	"textLabel": "_3yTLCVkz",
	"disabled": "_2D4yvaYh"
};
export default ___CSS_LOADER_EXPORT___;
