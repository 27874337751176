/*
 *
 * LocationPage reducer
 *
 */

import { createSlice } from '@reduxjs/toolkit';

import { defaultLocation } from '../locoStructure';

export const initialState = {
  location: defaultLocation,
  breadcrumbs: [
    {
      label: 'Velieve Logistics Optimization Console',
      path: '/loco',
    },
    {
      label: 'New Location',
    },
  ],
  isLoading: false,
  error: false,
};

const getBreadcrumbs = (location) => [
  {
    label: 'Velieve Logistics Optimization Console',
    path: '/loco',
  },
  {
    label: location.name,
  },
];

export const sliceName = 'locoLocationPage';

const locoNewEditSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    fetchLocation(draft) {
      draft.isLoading = true;
      draft.error = false;
    },
    fetchLocationSuccess(draft, { payload: { location } }) {
      draft.location = location;
      draft.breadcrumbs = getBreadcrumbs(location);
      draft.isLoading = false;
      draft.error = false;
    },
    mutationQuerySuccess(draft) {
      draft.location = null;
      draft.isLoading = false;
      draft.error = false;
    },
    locationQueryError(draft, { payload: { message } }) {
      draft.isLoading = false;
      draft.error = message;
    },
    updateLocation(draft) {
      draft.isLoading = true;
      draft.error = false;
    },
    createLocation(draft) {
      draft.isLoading = true;
      draft.error = false;
    },
    resetLocationPage(draft) {
      Object.keys(initialState).forEach((k) => {
        draft[k] = initialState[k];
      });
    },
    resetError(draft) {
      draft.error = false;
    },
  },
});

export const {
  fetchLocation,
  fetchLocationSuccess,
  locationQueryError,
  updateLocation,
  mutationQuerySuccess,
  createLocation,
  resetError,
  resetLocationPage,
} = locoNewEditSlice.actions;

export const { reducer } = locoNewEditSlice;
