import { css, Global } from '@emotion/react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import { Toaster as ReactHotToaster, toast as _toast } from 'react-hot-toast';

import Toast from './Toast';

const toastContainerCss = css`
  @keyframes Toast--fadeIn {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes Toast--fadeOut {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    to {
      transform: scale(0.9);
      opacity: 0;
    }
  }

  .Toast--animateEnter {
    animation: Toast--fadeIn 200ms ease-out;
  }

  .Toast--animateExit {
    animation: Toast--fadeOut 150ms ease-in forwards;
  }
`;

/**
 * Show an error toast.
 *
 * @param {string} message - The message to display
 * @param {object} options
 * @param {number} options.duration - Delay in ms to close the toast
 */
function error(message, { duration } = {}) {
  _toast(message, { duration, skin: 'error' });
}

/**
 * Show an info toast.
 *
 * @param {string} message - The message to display
 * @param {object} options
 * @param {number} options.duration - Delay in ms to close the toast
 */
function info(message, { duration } = {}) {
  _toast(message, { duration, skin: 'info' });
}

/**
 * Show a success toast.
 *
 * @param {string} message - The message to display
 * @param {object} options
 * @param {number} options.duration - Delay in ms to close the toast
 */
function success(message, { duration } = {}) {
  _toast(message, { duration, skin: 'success' });
}

/**
 * Show a warning toast.
 *
 * @param {string} message - The message to display
 * @param {object} options
 * @param {number} options.duration - Delay in ms to close the toast
 */
function warning(message, { duration } = {}) {
  _toast(message, { duration, skin: 'warning' });
}

/**
 * Toaster provides brief notifications. The component is also known as a snackbar.
 * Toasts inform users of a process that an app has performed. They appear temporarily and
 * shouldn't interrupt the user experience.
 *
 * The `<Toaster>` component needs to be added at the root of your component tree and it will render all toasts.
 *
 * **How to Style**
 *
 * Overriding styles can be done by overriding existing CSS classes.
 * You can also entirely customize the toast by passing a custom component to `toastComponent`.
 *
 * @see
 * [react-hot-toast](https://react-hot-toast.com/)
 */
export function Toaster({ duration = 4000, toastComponent: Component = Toast }) {
  return (
    <>
      <Global styles={toastContainerCss} />
      <ReactHotToaster position="top-right" toastOptions={{ duration }}>
        {({ message, id, skin, visible }) => (
          <div
            className={`Toast__container ${
              visible ? 'Toast--animateEnter' : 'Toast--animateExit'
            }`}
          >
            <Component
              message={message}
              onClose={() => _toast.dismiss(id)}
              skin={skin}
              visible={visible}
            />
          </div>
        )}
      </ReactHotToaster>
    </>
  );
}

Toaster.propTypes = {
  /** Delay in ms to close all toasts */
  duration: PropTypes.number,
  /** Customize the toast by passing a custom component. */
  toastComponent: PropTypes.elementType,
};

export const toast = {
  error,
  info,
  success,
  warning,
};

export default Toaster;
