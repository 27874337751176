import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clone from 'lodash/clone';
import isNil from 'lodash/isNil';

import BrainStyledSingleSelect from '@shared/components/BrainStyledSingleSelect';

import FieldLabel from '../../FieldLabel';

import classes from './styles.scss';

export default function SelectField({
  label,
  info,
  value,
  settings: { options, boolSupport = false },
  onChange,
  disabled = false,
  isRequired,
  ...otherProps
}) {
  if (boolSupport && options.length > 2) {
    /* eslint-disable no-console */
    console.warn('Are you using boolean values with more then two options?');
  }

  const [localValue, setLocalValue] = useState(
    value ? { value, label: `${value}` } : null,
  );
  const [localOptions, setLocalOptions] = useState([]);

  useEffect(() => {
    const newOptions = boolSupport
      ? options.map(({ value: v, ...rest }) => ({
          ...rest,
          value: v ? 'true' : 'false',
        }))
      : options;
    setLocalOptions(newOptions);
  }, [options]);

  useEffect(() => {
    if (options && options.length > 0) {
      const selectedOption = clone(
        isNil(value) ? null : options.find((op) => op.value === value),
      );
      if (boolSupport && selectedOption) {
        selectedOption.value = value ? 'true' : 'false';
      }
      setLocalValue(selectedOption);
    }
  }, [value, options]);

  const localOnChange = (newValue) => {
    setLocalValue(newValue);
    onChange(boolSupport ? newValue?.value === 'true' : newValue?.value);
  };

  // from here: https://github.com/JedWatson/react-select/issues/3140
  const hiddenInput = (
    <input
      tabIndex={-1}
      autoComplete="off"
      style={{ opacity: 0, height: 0, display: 'block' }}
      value={localValue === null ? '' : localValue}
      required={isRequired}
      readOnly
    />
  );

  return (
    <div className={classes['select-field']}>
      {isRequired && !disabled && hiddenInput}
      <BrainStyledSingleSelect
        label={<FieldLabel label={label} info={info} />}
        value={localValue}
        onChange={localOnChange}
        options={localOptions}
        disabled={disabled}
        {...otherProps}
      />
    </div>
  );
}

SelectField.propTypes = {
  settings: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
          .isRequired,
        style: PropTypes.object,
      }),
    ).isRequired,
    boolSupport: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  label: PropTypes.node.isRequired,
  info: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};
