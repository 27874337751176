// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3ndoMKN8 ._22sf-4M2 {\n    font-size: 14px;\n    font-weight: bold;\n    letter-spacing: 0.1px;\n    margin: 0 0 10px 0;\n  }\n\n    ._3ndoMKN8 ._22sf-4M2 a {\n      color: var(--brain-primary);\n    }\n  ._3ndoMKN8 h2 {\n    margin-top: 10px;\n  }\n  ._3ndoMKN8 ._22SMHiMA {\n    display: flex;\n    align-items: flex-end;\n    justify-content: space-between;\n  }\n  ._3ndoMKN8 ._22SMHiMA h1 {\n      font-weight: bold;\n      font-size: 30px;\n      line-height: 24px;\n      letter-spacing: 0.21px;\n      color: var(--defaultBlack);\n      margin: 0;\n    }\n  ._3ndoMKN8 ._22SMHiMA ._3vFtlgeU a {\n        margin-right: 15px;\n      }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs-header": "_3ndoMKN8",
	"breadcrumbs": "_22sf-4M2",
	"title": "_22SMHiMA",
	"links": "_3vFtlgeU"
};
export default ___CSS_LOADER_EXPORT___;
