const { fetch: fetchPolyfill } = require('whatwg-fetch');

const { mockFetch } = require('@shared/utils/mocks/fetchMock');

const { testAccessTokenDecoded } = require('./TestUserData');

window.fetch = mockFetch(fetchPolyfill);

window.gapi = {
  client: {
    request: ({ name }) => ({
      execute: (handler) => {
        const responseMock = {
          result: {
            id: name + Math.random().toString(36),
          },
        };
        handler(responseMock);
      },
    }),
    drive: {
      files: {
        create: ({ resource }) => {
          const responseMock = {
            result: {
              id: resource.name + Math.random().toString(36),
            },
          };
          return new Promise((resolve) => {
            resolve(responseMock);
          });
        },
        update: ({ fileId }) => {
          const responseMock = {
            result: {
              id: fileId,
            },
          };
          return new Promise((resolve) => {
            resolve(responseMock);
          });
        },
        copy: ({ fileId }) => {
          const responseMock = {
            result: {
              id: fileId + Math.random().toString(36),
            },
          };
          return new Promise((resolve) => {
            resolve(responseMock);
          });
        },
        list: () =>
          Promise.resolve({
            body: '{"files":[]}',
          }),
      },
    },
    getToken: () => testAccessTokenDecoded,
    setToken: () => null,
  },
};
