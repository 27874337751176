import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import cc from 'classcat';

import logo from 'images/logo.svg';

import classes from './VersionDetail.scss';

export default function VersionDetail({ isExpanded, collapseSidebar }) {
  const [showVersionPopUp, setShowVersionPopUp] = useState(false);

  const showPopup = useCallback(() => {
    collapseSidebar();
    setShowVersionPopUp(true);
  }, [collapseSidebar]);

  const hidePopup = useCallback(() => {
    setShowVersionPopUp(false);
  }, []);

  return (
    <>
      <button
        className={cc([classes.versionDetail, { [classes.expanded]: isExpanded }])}
        type="button"
        onClick={showPopup}
      >
        v{process.env.APP_VERSION}
      </button>
      {showVersionPopUp && <VersionPopup onClose={hidePopup} />}
    </>
  );
}

VersionDetail.propTypes = {
  collapseSidebar: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

function VersionPopup({ onClose }) {
  const [backendVersion, setBackendVersion] = useState(null);

  useEffect(() => {
    fetch(`${process.env.PRODUCTION_RUN.BACKEND_API_URL}/backend-version`)
      .then((res) => res.json()) // Fetch data as readable stream
      .then((data) => {
        setBackendVersion(data.backendVersion);
      });
  }, []);

  return (
    <Dialog classes={{ paper: classes.modal }} open onClose={onClose}>
      <img className={classes.brainLogo} src={logo} alt="BRAIN" />
      <div className={classes.label}>
        <span>Front End: </span>
        <span title={`v${process.env.APP_VERSION}`}>v{process.env.APP_VERSION}</span>
      </div>
      <div className={classes.label}>
        <span>Back End: </span>
        <span
          title={backendVersion}
          className={cc([{ [classes.loader]: !backendVersion }])}
        >
          {backendVersion || <CircularProgress size={16} color="inherit" />}
        </span>
      </div>
    </Dialog>
  );
}

VersionPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
};
