import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { produce } from 'immer';

import BrainFileUploader from '@shared/components/BrainFileUploader';
import { arrayBufferToString } from '@shared/utils/converters';
import useDownloadFromGDrive from '@shared/hooks/useDownloadFromGDrive';

import DescriptorLabel from '../DescriptorLabel';

import DynamicFileLabel from './DynamicFileLabel';
import classes from './styles.scss';

export default function FileDescriptor({
  id,
  label = '',
  descriptorState,
  descriptorState: { isDynamic = false, dynamicLabel = '', isNew = false },
  updatedAt,
  onChange,
  onDelete,
  disabled = false,
}) {
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [localLabel, setLocalLabel] = useState(dynamicLabel);
  const [localFileState, setLocalFileState] = useState({});

  useEffect(() => {
    setLocalFileState({
      fileChange: descriptorState.fileChange,
      fileData: descriptorState.fileData,
    });
  }, [descriptorState]);

  useEffect(() => {
    setLocalLabel(dynamicLabel);
  }, [dynamicLabel]);

  const uploadFile = (fileData) => {
    const reader = new FileReader();
    const newFileState = {
      fileChange: 'upload',
      fileData: {
        name: fileData.name,
        type: fileData.type,
      },
    };
    setLocalFileState(newFileState);
    reader.onload = () => {
      const uploadedState = produce(newFileState, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.fileData.file = arrayBufferToString(reader.result);
      });
      setLocalFileState(uploadedState);
      onChange({
        descriptorId: id,
        descriptorState: {
          ...descriptorState,
          dynamicLabel: localLabel,
          ...uploadedState,
        },
        isInitialState: false,
      });
    };
    reader.readAsArrayBuffer(fileData);
  };

  const deleteFile = () => {
    setLocalFileState({ fileChange: 'archive' });
    onChange({
      descriptorId: id,
      descriptorState: {
        ...descriptorState,
        dynamicLabel: localLabel,
        fileChange: 'archive',
      },
      isInitialState: false,
    });
  };

  /** Clear unsaved file */
  const clearFile = () => {
    onChange({
      descriptorId: id,
      descriptorState: {
        ...descriptorState,
        dynamicLabel: localLabel,
      },
      isInitialState: true,
    });
  };

  const downloadFile = useDownloadFromGDrive();

  const onDeleteDescriptor = () => {
    if (descriptorState.fileName) {
      onDelete({
        descriptorId: id,
        extraChanges: {
          ...descriptorState,
          fileChange: 'archive',
        },
      });
    } else {
      onDelete({
        descriptorId: id,
      });
    }
  };

  const onLabelChange = (newLabel) => {
    setLocalLabel(newLabel);
    // Before publishing the change, we need the previous setState to pass. Otherwise if the postProcess
    // returns the old value, this won't be recognized as a state change ans the label won't change.
    setTimeout(() => {
      onChange({
        descriptorId: id,
        descriptorState: {
          ...descriptorState,
          ...localFileState,
          dynamicLabel: newLabel,
        },
        postProcess: (newDescriptorState, descriptors) => {
          const nameSet = new Set();
          descriptors.forEach((desc) => {
            if (desc.id !== id && !desc.descriptorState.deletedAt) {
              nameSet.add(desc.descriptorState.dynamicLabel ?? desc.label ?? desc.name);
            }
          });
          let uniqueLabel = newLabel;
          for (let i = 1; nameSet.has(uniqueLabel); i += 1) {
            uniqueLabel = `${newLabel} (${i})`;
          }
          setLocalLabel(uniqueLabel);
          return {
            ...newDescriptorState,
            dynamicLabel: uniqueLabel,
          };
        },
        isInitialState: (newState) =>
          newState.dynamicLabel === dynamicLabel && !localFileState.fileChange,
      });
    }, 0);
  };

  return (
    <div className={classes.container} data-testid="file-descriptor">
      <BrainFileUploader
        label={
          isDynamic ? (
            <DynamicFileLabel
              label={localLabel}
              disabled={disabled}
              filename={descriptorState?.fileName}
              onLabelChange={onLabelChange}
              onDelete={onDeleteDescriptor}
              onDeleteMouseOver={() => setShowDeleteBox(true)}
              onDeleteMouseOut={() => setShowDeleteBox(false)}
              isNew={isNew}
            />
          ) : (
            <DescriptorLabel label={label} info={descriptorState.info} />
          )
        }
        value={descriptorState}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        downloadFile={() => downloadFile(descriptorState)}
        clearFile={clearFile}
        updatedAt={descriptorState.updatedAt ?? updatedAt}
        disabled={disabled}
        fileTypes={descriptorState.fileTypes}
      />
      {isDynamic && showDeleteBox && (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div className={classes.deleteBox} onMouseOver={() => setShowDeleteBox(false)}>
          Remove slot
        </div>
      )}
    </div>
  );
}

FileDescriptor.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.node,

  updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  descriptorState: PropTypes.shape({
    fileName: PropTypes.string,
    gDriveId: PropTypes.string,
    fileTypes: PropTypes.string,
    uploadedBy: PropTypes.string,
    gDriveFolderId: PropTypes.string,
    gDriveOldFilesFolderId: PropTypes.string,
    updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    info: PropTypes.node,
    isDynamic: PropTypes.bool,
    dynamicLabel: PropTypes.string,
    fileChange: PropTypes.oneOf(['upload', 'archive']),
    fileData: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      file: PropTypes.object,
    }),
    isNew: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
};
