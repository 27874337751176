import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import BrainCheckbox from '@shared/components/BrainCheckbox';

export default function CheckboxField({
  name,
  value,
  disabled,
  label,
  onChange,
  className = '',
}) {
  return (
    <BrainCheckbox
      value={name}
      disabled={disabled}
      checked={isNil(value) ? false : value}
      label={label}
      onChange={onChange}
      className={className}
    />
  );
}

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
