import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';

import logo from 'images/logo.svg';

import classes from './styles.scss';

export default function SignInPage({ handleGoogleSignIn }) {
  const [googleDisplayed, setGoogleDisplayed] = useState(false);
  const divRef = useRef(null);

  // init the gsi client and show the one-tap prompt
  useEffect(() => {
    if (!googleDisplayed) {
      window.google.accounts.id.initialize({
        client_id: process.env.GOOGLE_API_CLIENT_ID,
        callback: handleGoogleSignIn,
        cancel_on_tap_outside: false,
      });
      window.google.accounts.id.prompt(() => {
        const buttonOptions = { theme: 'outline', size: 'large' };
        window.google.accounts.id.renderButton(divRef.current, buttonOptions);

        setGoogleDisplayed(true);
      });
    }
  }, [divRef.current]);

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <div className={classes.pageContainer}>
        <div className={classes.signInContainer}>
          <img className={classes.logo} src={logo} alt="Brain Logo" title="Brain App" />
          <div className={classes.googleSignInContainer} ref={divRef}>
            <div>
              {Boolean(!googleDisplayed) && (
                <CircularProgress
                  size={50}
                  classes={{ root: classes['circular-progress'] }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

SignInPage.propTypes = {
  handleGoogleSignIn: PropTypes.func.isRequired,
};
