import jwtDecode from 'jwt-decode';

import { requestHeaders, apiUrl } from 'utils/userManagementUtils';

const decodeToken = (token) => jwtDecode(token).tags;

export const exchangeUMToken = async (googleIdToken) => {
  const response = await fetch(`${apiUrl}/exchange-token`, {
    method: 'POST',
    headers: requestHeaders,
    body: JSON.stringify({
      authProviderToken: googleIdToken,
      authProvider: 'google',
    }),
  });
  const { newToken } = await response.json();
  return { umToken: newToken, umPermissions: decodeToken(newToken) };
};

export const extractRefreshToken = (response) => {
  const newToken = response.headers.get('x-healthy-token');
  return { umToken: newToken, umPermissions: decodeToken(newToken) };
};
const umClient = { extractRefreshToken, exchangeUMToken };
export default umClient;
