export default function TrashCanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.5px"
      height="16px"
      viewBox="0 0 12.5 16"
    >
      <g fill="none" fillRule="evenodd" stroke="currentColor">
        <path
          strokeWidth=".713"
          d="M11.7067 2.9206H.765a.3214.3214 0 00-.315.328c0 .1812.1402.328.315.328h.583l.398 10.794c.0244.6606.5401 1.1783 1.1746 1.1783H9.551c.6345 0 1.1493-.5177 1.1746-1.1773l.397-10.795h.584a.3214.3214 0 00.315-.3271.3214.3214 0 00-.315-.329zm-1.61 11.4248a.555.555 0 01-.5457.5476H2.9206a.555.555 0 01-.5457-.5476L1.9778 3.5765h8.5151l-.3962 10.769z"
        />
        <path
          strokeWidth=".713"
          d="M5.3743 6.5516a.3214.3214 0 00-.3158-.3223h-.0056a.3224.3224 0 00-.3084.3345l.0981 5.354a.3214.3214 0 00.315.3224h.0055a.3224.3224 0 00.3084-.3345l-.0972-5.354zm1.9155-.3223a.3214.3214 0 00-.3149.328v5.354c0 .1822.1411.329.315.329a.3214.3214 0 00.3148-.328V6.5581a.3214.3214 0 00-.3149-.329z"
        />
        <path
          strokeWidth="1.426"
          d="M4.3026 3.2252v-1.809c0-.3681.299-.6662.6662-.6662h2.534c.3682 0 .6663.299.6663.6662v1.809H4.3026z"
        />
      </g>
    </svg>
  );
}
