import { css } from '@emotion/react';
import Link from '@material-ui/core/Link';

import BrainButton from '@shared/components/BrainButton';

const containerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
`;

function getUrl() {
  if (process.env.HEALTHY_ENV === 'staging') {
    return 'https://cps-staging.healthy.io';
  }
  if (process.env.HEALTHY_ENV === 'prod') {
    return 'https://cps.healthy.io';
  }
  return 'https://cps-develop.healthy.io';
}

const cpsUrl = getUrl();

export default function CPSPage() {
  return (
    <>
      <div css={containerCss}>
        <Link href={cpsUrl} target="_blank" data-testid="login">
          <BrainButton variant="primary" data-testid="cps-link-button">
            Go to CPS
          </BrainButton>
        </Link>
      </div>
    </>
  );
}
