// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button._356qastx {\n  padding: 12px 0 !important;\n  min-height: initial !important;\n  text-align: center !important;\n  border: none;\n  border-top: 1px solid var(--sideBarBorderColor) !important;\n  color: var(--sideBarGreyText);\n  transition:\n    background-color ease-in-out var(--sideBarExpansionTime),\n    width ease-in-out var(--sideBarExpansionTime) !important;\n  font-size: 10px !important;\n  width: var(--sideBarWidth)\n}\n\nbutton._356qastx._2DEHSaLD {\n    width: 100%;\n    background-color: #f8f8f8 !important;\n  }\n\n._3JJ59bcX {\n  color: var(--defaultBlack);\n  padding: 25px;\n  background-color: var(--ultraLightGrey);\n}\n\n._3JJ59bcX .T4H4mcBX {\n    width: 50px;\n    margin: 0 auto 25px;\n  }\n\n._3JJ59bcX ._3YZl6l0M {\n    display: flex;\n    align-items: center;\n    color: inherit\n  }\n\n._3JJ59bcX ._3YZl6l0M:first-of-type {\n      margin-bottom: 5px;\n    }\n\n._3JJ59bcX ._3YZl6l0M > :first-of-type {\n      width: 100px;\n    }\n\n._3JJ59bcX ._3YZl6l0M > :last-of-type {\n      width: 55px;\n      overflow: hidden;\n      white-space: nowrap;\n      text-overflow: ellipsis\n    }\n\n._3JJ59bcX ._3YZl6l0M > :last-of-type._3P-tDWja {\n        justify-content: center;\n      }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"versionDetail": "_356qastx",
	"expanded": "_2DEHSaLD",
	"modal": "_3JJ59bcX",
	"brainLogo": "T4H4mcBX",
	"label": "_3YZl6l0M",
	"loader": "_3P-tDWja"
};
export default ___CSS_LOADER_EXPORT___;
