import flatMapDepth from 'lodash/flatMapDepth';

export const processPluralType = (type) => {
  if (['CAPA', 'ECO'].includes(type)) {
    return type;
  }
  if (['ECO US'].includes(type)) {
    return 'ECO (US)';
  }

  return `${type}s`;
};

export const getFilterName = (tableFilter) => tableFilter.filterName ?? tableFilter.name;

export const getValueFromDescriptor = (descriptor) => {
  const descriptorState = descriptor?.descriptorState ?? descriptor?.state ?? {};
  if (descriptor?.type === 'multiselect') {
    return descriptorState.options
      .filter((o) => o.selected)
      .map((o) => o.name)
      .join(', ');
  }
  if (descriptor?.type === 'asyncMultiselect') {
    return (
      descriptorState.selections &&
      descriptorState?.selections
        .filter((o) => o.selected)
        .map((o) => o.label)
        .join(', ')
    );
  }
  return (
    descriptorState.label ?? descriptorState.value ?? descriptorState.defaultValue ?? ''
  );
};

export const getStep = ({ stageName, stepName }, process) =>
  process.stages
    .find((s) => s.name === stageName)
    ?.steps.find((s) => s.name === stepName);

export const findDescriptorInArray = (
  { descriptorName, nestedDescriptorName },
  descriptors,
) => {
  const descriptor = descriptors?.find((d) => d.name === descriptorName) ?? {};
  const descriptorKey = descriptor.descriptorState ? 'descriptorState' : 'state';
  if (nestedDescriptorName) {
    return descriptor[descriptorKey]?.descriptors?.find(
      (d) => d.name === nestedDescriptorName,
    );
  }
  return descriptor;
};

export const getDescriptor = (
  { stageName, stepName, descriptorName, nestedDescriptorName },
  process,
) => {
  let descriptors;
  if (stepName) {
    // search in specific step
    descriptors = getStep({ stageName, stepName }, process)?.descriptors;
  } else {
    // search in all steps and stages
    descriptors = flatMapDepth(
      process.stages,
      (stage) => stage.steps.map((step) => step.descriptors),
      2,
    );
  }

  return findDescriptorInArray({ descriptorName, nestedDescriptorName }, descriptors);
};

export const getValueFromDescriptorPath = (descriptorPath, descriptors) => {
  const descriptor = findDescriptorInArray(descriptorPath, descriptors);
  return getValueFromDescriptor(descriptor);
};

export const getValueFromProcessPath = (descriptorPath, process) => {
  const descriptor = getDescriptor(descriptorPath, process);
  return getValueFromDescriptor(descriptor);
};

export const getValueFromMetadataPath = (metaPath, process) => {
  let descriptor;
  if (process.metadata && typeof process.metadata.find === 'function') {
    descriptor = process.metadata.find((val) => val.name === metaPath.name);
  }
  return getValueFromDescriptor(descriptor);
};

export const getProcessTypePrefix = (shortname, { domain }) =>
  domain ? `${shortname}/${domain}/` : `${shortname}/`;

export const buildProcsssLink = (process, { domain }) => {
  const steps = process.stages.reduce(
    (previousValue, currentValue) => previousValue.concat(currentValue.steps),
    [],
  );

  const processTypeShortname = process.template.shortname;

  const processLink = `/process/${getProcessTypePrefix(processTypeShortname, {
    domain,
  })}${process.id}`;

  // If process has only one step, redirect to it:
  return steps.length === 1
    ? `${processLink}/stage/${process.stages[0].id}/step/${steps[0].id}`
    : processLink;
};
