import PropTypes from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import Loader from '@shared/components/Loader';

import handleChunkLoadingError from './handleChunkLoadingError';
import reporter from './reporter';

function DefaultFallbackComponent({ componentStack, error }) {
  // Show a loader instead of the error page until the refresh kicks in
  if (error.toString().includes('ChunkLoadError')) return <Loader />;

  return (
    <div>
      <h3>Oops! An error occurred!</h3>
      <p>Here’s what we know…</p>
      <p>
        <strong>Error:</strong> <pre>{error.toString()}</pre>
      </p>
      <p>
        <strong>Stacktrace:</strong> <pre>{componentStack}</pre>
      </p>
    </div>
  );
}

DefaultFallbackComponent.propTypes = {
  componentStack: PropTypes.string,
  error: PropTypes.object,
};

export function ErrorBoundary(
  { children, fallbackComponent = DefaultFallbackComponent } = {
    fallbackComponent: DefaultFallbackComponent,
  },
) {
  const myErrorHandler = (err, componentStack) => {
    if (err.toString().includes('ChunkLoadError')) {
      const triggeredReload = handleChunkLoadingError();
      if (!triggeredReload) reporter.error(err, { componentStack });
    } else {
      // Send Sentry log
      reporter.error(err, { context: 'Error boundary page', componentStack });
    }
  };

  return (
    <ReactErrorBoundary FallbackComponent={fallbackComponent} onError={myErrorHandler}>
      {children}
    </ReactErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallbackComponent: PropTypes.elementType,
};
