import { createSlice } from '@reduxjs/toolkit';
import isArray from 'lodash/isArray';

import { getFilterName } from './processTableUtils';

export const initialState = {
  processList: [],
  processListLoading: true,
  processListError: false,
  currentPage: 0,
  rowsPerPage: 10,
  totalCount: 0,
  template: null,
  selectedFilters: {},
  appliedFilters: {},
  searchText: null,
  domain: null,
  type: null,
};

export const sliceKey = 'processTable';

const processTableSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    fetchProcessList(state) {
      state.processListLoading = true;
      state.processListError = false;
    },
    fetchProcessListSuccess(
      state,
      { payload: { processList, currentPage, rowsPerPage, totalCount } },
    ) {
      state.currentPage = currentPage;
      state.rowsPerPage = rowsPerPage;
      state.totalCount = totalCount;
      state.processList = processList;
      state.processListLoading = false;
      state.processListError = false;
    },
    fetchProcessListError(state) {
      state.processListLoading = false;
      state.processListError = true;
    },
    setTemplate(state, { payload: { data: template } }) {
      state.template = template;
      template.tableFields.forEach((t) => {
        const name = getFilterName(t);

        state.selectedFilters[name] = [];
        state.appliedFilters[name] = [];
      });
    },
    setProcessType(state, { payload: { type, domain } }) {
      state.type = type;
      state.domain = domain;
    },
    setFilters(state, action) {
      state.selectedFilters = action.payload.selectedFilters;
    },
    setFilter(state, action) {
      const { filterName, filterValue } = action.payload;

      if (isArray(filterValue)) {
        state.selectedFilters[filterName] = filterValue;
        return;
      }

      if (!state.selectedFilters[filterName]) {
        state.selectedFilters[filterName] = [];
      }
      if (state.selectedFilters[filterName].indexOf(filterValue) > -1) {
        state.selectedFilters[filterName].splice(
          state.selectedFilters[filterName].indexOf(filterValue),
          1,
        );
      } else {
        state.selectedFilters[filterName].push(filterValue);
      }
    },
    applyFilters(state) {
      state.appliedFilters = state.selectedFilters;
    },
    discardUnappliedFilters(state) {
      state.selectedFilters = state.appliedFilters;
    },
    setSearchBarText(state, action) {
      state.searchText = action.payload.searchText;
    },
    deleteProcessSuccess(state, { payload: { processId } }) {
      state.processList = state.processList.filter(
        (item) => String(item.id) !== String(processId),
      );
    },
    resetPage(state) {
      state.currentPage = 0;
    },
    resetFilters(state) {
      Object.keys(state.selectedFilters).forEach((filterName) => {
        state.selectedFilters[filterName] = [];
      });
      Object.keys(state.appliedFilters).forEach((filterName) => {
        state.appliedFilters[filterName] = [];
      });
    },
  },
});

export const {
  fetchProcessList,
  fetchProcessListSuccess,
  fetchProcessListError,
  setTemplate,
  setFilter,
  setFilters,
  applyFilters,
  discardUnappliedFilters,
  deleteProcessSuccess,
  setSearchBarText,
  resetPage,
  resetFilters,
  setProcessType,
} = processTableSlice.actions;

export const { reducer } = processTableSlice;
