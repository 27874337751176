import { createSelector } from 'reselect';

import { initialState, sliceKey } from './slice';

const selectNotificationsDomain = createSelector(
  (state) => state?.[sliceKey] ?? initialState,
  (substate) => substate,
);

export default selectNotificationsDomain;
