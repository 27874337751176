import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

import BrainTooltipTrigger from '@shared/components/BrainTooltipTrigger';

import classes from './styles.scss';

const hasEllipsis = (element) => element && element.offsetWidth < element.scrollWidth;

export default function FieldLabel({
  label,
  info,
  hasError,
  settings: { tooltipSettings } = {},
}) {
  const [labelTitle, setLabelTitle] = useState(null);
  const labelRef = useRef(null);

  useEffect(() => {
    setLabelTitle(hasEllipsis(labelRef.current) ? labelRef.current.innerText : null);
  }, [labelRef]);

  return (
    <div className={classes['field-label']}>
      <span className={classes.label} title={labelTitle} ref={labelRef}>
        {label}
      </span>
      {info && (
        <BrainTooltipTrigger
          title={info}
          {...tooltipSettings}
          className={cc([
            classes['field-label-tooltip'],
            { [classes.tooltipError]: hasError },
          ])}
        />
      )}
    </div>
  );
}

FieldLabel.propTypes = {
  label: PropTypes.node.isRequired,
  info: PropTypes.node,
  hasError: PropTypes.bool,
  settings: PropTypes.shape({ tooltipSettings: PropTypes.object }),
};
