// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".otmwauuZ {\n  color: var(--defaultBlack)\n}\n.otmwauuZ.yz-YoWC4 {\n    color: var(--lightGrey);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": "otmwauuZ",
	"disabled": "yz-YoWC4"
};
export default ___CSS_LOADER_EXPORT___;
