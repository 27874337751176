export default function TrashCanIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41">
      <g fill="none" fillRule="evenodd">
        <g stroke="currentColor">
          <path
            fill="currentColor"
            fillRule="nonzero"
            strokeWidth=".63"
            d="M29.483 12H11.517a.518.518 0 00-.517.52c0 .287.231.52.517.52h.958l.653 17.094A1.929 1.929 0 0015.057 32h10.886c1.042 0 1.89-.82 1.929-1.866l.653-17.095h.958a.518.518 0 00.517-.52.518.518 0 00-.517-.519zM26.84 30.094a.896.896 0 01-.896.867H15.057a.896.896 0 01-.896-.867l-.652-17.055h13.982l-.652 17.055z"
          />
          <path
            fill="currentColor"
            fillRule="nonzero"
            strokeWidth=".5"
            d="M22.5 18c-.276 0-.5.22-.5.491v8.018c0 .271.224.491.5.491s.5-.22.5-.491V18.49a.496.496 0 00-.5-.491zm-4 0c-.276 0-.5.22-.5.491v8.018c0 .271.224.491.5.491s.5-.22.5-.491V18.49a.496.496 0 00-.5-.491z"
          />
          <path
            strokeWidth="1.5"
            d="M17 12V8.63a.63.63 0 01.63-.63h5.74a.63.63 0 01.63.63V12h-7z"
          />
        </g>
      </g>
    </svg>
  );
}
