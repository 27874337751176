import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isOpen: false,
  loading: false,
  error: false,
  tasks: null,
};

export const sliceKey = 'openTasks';

const openTasksSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    fetchTasks(state) {
      state.error = false;
      state.loading = true;
    },
    fetchTasksError(state) {
      state.loading = false;
      state.error = true;
    },
    fetchTasksSuccess(state, action) {
      state.loading = false;
      state.tasks = action.payload.tasks;
    },
    setIsOpen(state, action) {
      state.isOpen = action.payload.isOpen;
    },
  },
});

export const { setIsOpen, fetchTasks, fetchTasksError, fetchTasksSuccess } =
  openTasksSlice.actions;

export const { reducer } = openTasksSlice;
