export default function UserManagementIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(10 10)">
          <circle cx="13.5" cy="13.5" r="13.5" fill="currentColor" />
          <g fill="#FFF">
            <g transform="translate(2 6.5) translate(0 .75)">
              <path
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="1.44"
                d="M5.581 5.25c1.958.028 3.468.733 4.534 2.114.389.504.6 1.123.6 1.76h0v5.407l-5.132 1.295-.002.174-.184-.127-.5.127.02-.463c-1.548-1.106-2.6-2.077-3.16-2.912-.473-.708-.836-1.685-1.089-2.93L.602 9.35c-.146-.815.065-1.653.58-2.301.933-1.175 2.36-1.774 4.281-1.797h.054l.064-.001h0z"
              />
              <ellipse cx="5.581" cy="2.625" rx="2.567" ry="2.625" />
            </g>
            <g transform="translate(2 6.5) translate(12 .75)">
              <path
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="1.44"
                d="M5.581 5.25c1.907.027 3.39.696 4.45 2.008.461.57.687 1.294.633 2.025h0l-.03.349c-.16 1.71-.524 2.942-1.093 3.695-.637.844-1.956 1.65-3.957 2.42L5.58 16l-.398-.105-.287.105.008-.178-4.456-1.16v-5.67c0-.55.157-1.087.453-1.55.917-1.435 2.437-2.165 4.561-2.191h.057l.062-.001h0z"
              />
              <ellipse cx="5.581" cy="2.625" rx="2.567" ry="2.625" />
            </g>
            <g transform="translate(2 6.5) translate(6 2.75)">
              <path
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="1.44"
                d="M5.581 5.25c1.959.028 3.469.733 4.534 2.114.389.504.6 1.123.6 1.76h0v5.407c-2.557 1.116-4.473 1.55-5.746 1.303-1.166-.043-2.673-.433-4.52-1.172v-5.67c0-.55.156-1.087.452-1.55.917-1.435 2.437-2.165 4.561-2.191h.056l.063-.001h0z"
              />
              <ellipse cx="5.581" cy="2.625" rx="2.567" ry="2.625" />
            </g>
          </g>
        </g>
        <rect width="46" height="46" fill="#FFF" fillOpacity=".01" rx="3" />
      </g>
    </svg>
  );
}
