import { takeLatest, call, put } from 'redux-saga/effects';
import get from 'lodash/get';
import pick from 'lodash/pick';

import history from '@shared/redux/history';
import batchedMutations from '@shared/utils/graphQl/batchedMutations';

import { brainFlattenGqlRequest } from 'utils/gqlUtils';
import { getErrorMessage } from 'utils/sharedSagaUtils';

import {
  updateLocationMutationCreator,
  updateScheduleMutationCreator,
  updateStoreProductMutationCreator,
  createStoreProductMutation,
  createLocationMutation,
  createSchedulesMutation,
  getLocationDataQuery,
} from 'containers/Loco/locoQueries';

import { locoRequest } from '../locoGQlClient';
import { dbStoreToLocation } from '../locoUtils';

import {
  fetchLocation,
  updateLocation,
  createLocation,
  fetchLocationSuccess,
  locationQueryError,
  mutationQuerySuccess,
} from './slice';

function locationFieldsToDB(location) {
  return pick(location, [
    'originalId',
    'name',
    'type',
    'country',
    'city',
    'postcode',
    'courierNote',
    'address',
    'contactInformation',
    'active',
    'referenceNumber',
    'comment',
  ]);
}

function* drillAwayAfterMutation() {
  yield call(history.push, '/loco');
}

function* fetchLocationData({ payload: { locationId } }) {
  try {
    const response = yield call(locoRequest, getLocationDataQuery, {
      locationId: parseInt(locationId, 10),
    });
    const store = yield call(brainFlattenGqlRequest, response.store);
    yield put(fetchLocationSuccess({ location: dbStoreToLocation(store) }));
  } catch (e) {
    console.error(e);
    yield put(locationQueryError({ message: getErrorMessage(e) }));
  }
}

function* performCreateSchedule(location, storeId) {
  const weekdaysMap = {};
  location.openingHours.forEach((sch) => {
    weekdaysMap[sch.dayOfWeek] = sch;
  });
  const createDaySection = (index) => ({
    schedule: {
      ...weekdaysMap[index],
      storeId,
    },
  });

  yield call(locoRequest, createSchedulesMutation, {
    sunday: createDaySection(0),
    monday: createDaySection(1),
    tuesday: createDaySection(2),
    wednesday: createDaySection(3),
    thursday: createDaySection(4),
    friday: createDaySection(5),
    saturday: createDaySection(6),
  });
}

function* performCreateProducts(location, storeId) {
  const existingIds = location.items.map(({ value }) => value);
  yield call(locoRequest, createStoreProductMutation, {
    item1: {
      storeProduct: {
        storeId,
        active: existingIds.includes(1),
        productId: 1,
      },
    },
    item2: {
      storeProduct: {
        storeId,
        active: existingIds.includes(2),
        productId: 2,
      },
    },
  });
}

function* createLocationData({ payload: { location } }) {
  try {
    const response = yield call(locoRequest, createLocationMutation, {
      storeInput: {
        store: locationFieldsToDB(location),
      },
    });

    const { createStore } = yield call(brainFlattenGqlRequest, response);

    const {
      store: { id },
    } = createStore;

    yield performCreateSchedule(location, id);
    yield performCreateProducts(location, id);

    yield put(mutationQuerySuccess());
  } catch (e) {
    console.error(e);
    yield put(locationQueryError({ message: getErrorMessage(e) }));
  }
}

function generateUpdateScheduleMutations(location, scheduleChangesArr) {
  return scheduleChangesArr.map((name) => {
    const scheduleValue = get(location, name);
    return {
      mutation: updateScheduleMutationCreator,
      inputType: 'UpdateScheduleByStoreIdAndDayOfWeekInput!',
      variables: {
        dayOfWeek: scheduleValue.dayOfWeek,
        storeId: location.id,
        patch: scheduleValue,
      },
    };
  });
}

function generateUpdateProductsMutations(location) {
  const existingIds = location.items.map(({ value }) => value);
  const products = [1, 2];
  return products.map((productId) => ({
    mutation: updateStoreProductMutationCreator,
    inputType: 'UpdateStoreProductByStoreIdAndProductIdInput!',
    variables: {
      patch: {
        active: existingIds.includes(productId),
      },
      productId,
      storeId: location.id,
    },
  }));
}

function generateUpdateLocationMutations(location) {
  return {
    mutation: updateLocationMutationCreator,
    inputType: 'UpdateStoreInput!',
    variables: {
      patch: locationFieldsToDB(location),
      id: location.id,
    },
  };
}

function* updateLocationData({ payload: { location, changes } }) {
  const changePaths = Object.keys(changes);
  const scheduleChangesArr = changePaths.filter((path) =>
    path.startsWith('openingHours'),
  );
  const hasScheduleChanges = scheduleChangesArr.length > 0;
  const hasItemChanges = changePaths.includes('items');
  const hasStoreChanges =
    changePaths.length > scheduleChangesArr.length + (hasItemChanges ? 1 : 0);

  try {
    let mutations = [];
    // Store changes (root table only)
    if (hasStoreChanges) {
      mutations.push(generateUpdateLocationMutations(location));
    }

    // Schedule changes
    if (hasScheduleChanges) {
      mutations = mutations.concat(
        generateUpdateScheduleMutations(location, scheduleChangesArr),
      );
    }

    // Item changes
    if (hasItemChanges) {
      mutations = mutations.concat(generateUpdateProductsMutations(location));
    }

    yield call(batchedMutations, mutations, locoRequest);

    yield put(mutationQuerySuccess());
  } catch (e) {
    console.error(e);
    yield put(locationQueryError({ message: getErrorMessage(e) }));
  }
}

// Watcher saga
export default function* stepPageSaga() {
  yield takeLatest(fetchLocation, fetchLocationData);
  yield takeLatest(updateLocation, updateLocationData);
  yield takeLatest(createLocation, createLocationData);
  yield takeLatest(mutationQuerySuccess, drillAwayAfterMutation);
}
