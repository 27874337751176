// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._2FVwg9A1 {\n  font-size: 14px;\n  color: var(--defaultBlack);\n  background-color: var(--lightGrey);\n  margin: 2px 2px 0 0;\n  height: initial;\n  padding: 4px 0;\n  max-width: 95%;\n  border-radius: 9999px;\n}\n\n  div._2FVwg9A1 svg {\n    color: var(--darkGrey);\n  }\n\nspan._3Fl9SIvh {\n  white-space: initial;\n  padding: 0 16px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiValue": "_2FVwg9A1",
	"chipLabel": "_3Fl9SIvh"
};
export default ___CSS_LOADER_EXPORT___;
