// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._3uzqSXuo {\n  flex-direction: column-reverse;\n  display: flex;\n}\n\n  div._3uzqSXuo label._1s4BwgUF {\n    position: relative;\n    color: var(--defaultBlack);\n    font-size: 13px;\n    letter-spacing: 0.1px;\n    line-height: 16px;\n    transform: unset;\n    margin-top: 3px\n  }\n\n  div._3uzqSXuo label._1s4BwgUF.LPIpHz5Y {\n      color: var(--lightGreyText);\n    }\n\n  div._3uzqSXuo label._1s4BwgUF._3XNt0wpo {\n      color: var(--brain-primary);\n    }\n\n  div._3uzqSXuo label._1s4BwgUF._1yIdhOeB {\n      color: var(--red);\n    }\n\n  div._3uzqSXuo div._1ODTNprT {\n    color: var(--defaultBlack);\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    line-height: 17px;\n    margin: 0\n  }\n\n  div._3uzqSXuo div._1ODTNprT::before,\n    div._3uzqSXuo div._1ODTNprT:hover:not(._31AchTa0)::before,\n    div._3uzqSXuo div._1ODTNprT:hover::before {\n      border-bottom: 1px solid var(--defaultBlack);\n    }\n\n  div._3uzqSXuo div._1ODTNprT.LPIpHz5Y::before,\n      div._3uzqSXuo div._1ODTNprT.LPIpHz5Y:hover::before {\n        border-bottom: 1px solid var(--lightGrey);\n      }\n\n  div._3uzqSXuo div._1ODTNprT._3y0eHwO4 {\n      color: var(--defaultBlack);\n    }\n\n  div._3uzqSXuo div._1ODTNprT > input {\n      padding: 0 0 3px;\n    }\n\n  div._3uzqSXuo ._1WvguA22 {\n    color: var(--lightGreyText);\n    margin-bottom: 2px\n  }\n\n  div._3uzqSXuo ._1WvguA22:hover {\n      background-color: transparent;\n      cursor: pointer;\n      color: var(--greyText);\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textField": "_3uzqSXuo",
	"textLabel": "_1s4BwgUF",
	"disabled": "LPIpHz5Y",
	"focusedLabel": "_3XNt0wpo",
	"error": "_1yIdhOeB",
	"textInput": "_1ODTNprT",
	"Mui-disabled": "_31AchTa0",
	"focusedInput": "_3y0eHwO4",
	"clearIcon": "_1WvguA22"
};
export default ___CSS_LOADER_EXPORT___;
