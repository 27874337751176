// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n  div._15hi-rpQ::-webkit-scrollbar {\n    background-color: transparent;\n    width: 9px;\n    height: 9px;\n  }\ndiv._15hi-rpQ::-webkit-scrollbar-thumb {\n    background-color: rgba(0, 0, 0, 0.15);\n    border-radius: 4px;\n    border: 2px solid transparent;\n    background-clip: padding-box\n  }\ndiv._15hi-rpQ::-webkit-scrollbar-thumb:hover {\n      background-color: rgba(0, 0, 0, 0.2);\n      border-radius: 4px;\n      border: 2px solid transparent;\n      background-clip: padding-box;\n    }\ndiv._15hi-rpQ {\n\n  flex-grow: 1;\n  flex-shrink: 1 !important;\n  overflow-y: auto;\n  overflow-x: hidden;\n  min-height: 150px\n}\ndiv._15hi-rpQ.US5IW_t2 {\n    overflow: hidden;\n  }\ndiv._15hi-rpQ button._1nxeJz4D,\n  div._15hi-rpQ a._houIruw {\n    width: 100%;\n    border: none;\n    display: flex;\n    align-items: center;\n    position: relative\n  }\ndiv._15hi-rpQ button._1nxeJz4D.P3h4Tnkc, div._15hi-rpQ a._houIruw.P3h4Tnkc {\n      color: var(--sideBarBlue);\n    }\ndiv._15hi-rpQ button._1nxeJz4D:hover, div._15hi-rpQ a._houIruw:hover {\n      background-color: var(--sideBarBgColor);\n    }\ndiv._15hi-rpQ button._1nxeJz4D span._3Sr5fY7X, div._15hi-rpQ a._houIruw span._3Sr5fY7X {\n      flex-grow: 1;\n    }\ndiv._15hi-rpQ button._1nxeJz4D svg:first-child, div._15hi-rpQ a._houIruw svg:first-child {\n      width: var(--sideBarWidth);\n      flex-shrink: 0;\n      padding: 0 8px;\n    }\ndiv._15hi-rpQ button._1nxeJz4D svg:last-child, div._15hi-rpQ a._houIruw svg:last-child {\n      width: 24px;\n      flex-shrink: 0;\n      margin: 0 12px;\n    }\ndiv._15hi-rpQ a._houIruw {\n    text-decoration: none;\n    padding: 0 0 0 var(--sideBarWidth);\n    min-height: calc(0.75 * var(--sideBarWidth))\n  }\ndiv._15hi-rpQ a._houIruw:last-of-type {\n      margin-bottom: 5px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navTree": "_15hi-rpQ",
	"collapsed": "US5IW_t2",
	"button": "_1nxeJz4D",
	"link": "_houIruw",
	"active": "P3h4Tnkc",
	"text": "_3Sr5fY7X"
};
export default ___CSS_LOADER_EXPORT___;
