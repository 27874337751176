import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import BrainSelect from '@shared/components/BrainSelect';

import FieldLabel from '../../FieldLabel';

import classes from './styles.scss';

export default function AsyncSelectField({
  label,
  info,
  value,
  onChange,
  disabled = false,
  fetchOptions,
  settings = {},
  ...otherProps
}) {
  const [localValue, setLocalValue] = useState({});
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const localOnChange = (newValue) => {
    setLocalValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={classes['async-select-descriptor']}>
      <BrainSelect
        label={<FieldLabel label={label} info={info} />}
        value={localValue}
        onChange={localOnChange}
        isAsync
        isClearable
        fetchOptions={() => fetchOptions(settings.queryName)}
        disabled={disabled}
        {...otherProps}
      />
    </div>
  );
}

AsyncSelectField.propTypes = {
  fetchOptions: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    queryName: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
  }),

  label: PropTypes.node.isRequired,
  info: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
