export default function ControlledDocumentsIcon() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(7, 8) scale(1.2)">
          <rect fillOpacity="0.01" fill="#FFFFFF" x="0" y="0" width="46" height="46" />
          <rect
            stroke="currentColor"
            strokeWidth="2"
            x="4.51351351"
            y="3.81081081"
            width="9.24324324"
            height="12.0540541"
            rx="1.6"
          />
          <rect
            stroke="currentColor"
            strokeWidth="2"
            fill="currentColor"
            x="13.6486486"
            y="10.8378378"
            width="8.54054054"
            height="11.3513514"
            rx="1.6"
          />
          <rect
            stroke="currentColor"
            strokeWidth="2"
            fill="currentColor"
            x="13.6486486"
            y="10.8378378"
            width="8.54054054"
            height="11.3513514"
            rx="1.6"
          />
          <rect
            stroke="#FFFFFF"
            strokeWidth="2"
            fill="#FFFFFF"
            x="7.32432432"
            y="6.62162162"
            width="8.54054054"
            height="11.3513514"
            rx="1.6"
          />
          <rect
            stroke="currentColor"
            strokeWidth="2"
            fill="currentColor"
            x="8.02702703"
            y="7.32432432"
            width="9.24324324"
            height="12.0540541"
            rx="1.6"
          />
          <rect
            stroke="#FFFFFF"
            strokeWidth="2"
            fill="#FFFFFF"
            x="12.9459459"
            y="10.8378378"
            width="8.54054054"
            height="11.3513514"
            rx="1.6"
          />
          <rect
            stroke="currentColor"
            strokeWidth="2"
            fill="#FFFFFF"
            x="13.6486486"
            y="11.5405405"
            width="8.54054054"
            height="11.3513514"
            rx="1.6"
          />
        </g>
      </g>
    </svg>
  );
}
