import { useState, useEffect } from 'react';

const loadedScripts = {};

const createScriptTag = (src) => {
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.async = true;
  newScript.src = src;

  return newScript;
};

const addEventListeners = (script, onLoad, onError) => {
  script.addEventListener('load', onLoad, false);
  script.addEventListener('error', onError, false);
};

const removeEventListeners = (script, onLoad, onError) => {
  script.removeEventListener('load', onLoad);
  script.removeEventListener('error', onError);
};

export default function useLoadScript(scriptSrc) {
  const [state, setState] = useState({
    isLoadingScript: true,
    errorLoadingScript: false,
  });

  useEffect(() => {
    // Define callbacks
    const onLoad = () => {
      const newState = { isLoadingScript: false, errorLoadingScript: false };
      loadedScripts[scriptSrc] = newState;
      setState(newState);
    };
    const onError = () => {
      const newState = { isLoadingScript: false, errorLoadingScript: true };
      loadedScripts[scriptSrc] = newState;
      setState(newState);
    };

    // By default, we create a new script tag and don't add event listeners to an existing tag
    let shouldCreateNewScript = true;
    let shouldRedoEventListeners = false;

    // But if the script tag is already in the page, we have some edge cases to deal with
    const isScriptAlreadyInPage = scriptSrc in loadedScripts;

    if (isScriptAlreadyInPage) {
      // First, we fetch the saved state
      const prevScriptState = loadedScripts[scriptSrc];

      // Different edge cases
      const stillLoading = prevScriptState.isLoadingScript;
      const loadedSuccessfully =
        !prevScriptState.isLoadingScript && !prevScriptState.errorLoadingScript;
      const loadedWithError =
        !prevScriptState.isLoadingScript && prevScriptState.errorLoadingScript;

      switch (true) {
        case stillLoading:
          shouldCreateNewScript = false;
          shouldRedoEventListeners = true;
          break;

        case loadedSuccessfully:
          shouldCreateNewScript = false;
          shouldRedoEventListeners = false;

          // We let the user know the script was already loaded successfully
          setState({ isLoadingScript: false, errorLoadingScript: false });
          break;

        case loadedWithError:
          shouldCreateNewScript = true;
          shouldRedoEventListeners = false;

          // We remove the previous script tag
          document.querySelector(`script[src="${scriptSrc}"]`).remove();
          break;

        default:
          break;
      }
    }

    let scriptTag = false;

    if (shouldCreateNewScript) {
      scriptTag = createScriptTag(scriptSrc);
      addEventListeners(scriptTag, onLoad, onError);
      document.body.appendChild(scriptTag);
    } else if (shouldRedoEventListeners) {
      scriptTag = document.querySelector(`script[src="${scriptSrc}"]`);
      addEventListeners(scriptTag, onLoad, onError);
    }

    return () => {
      if (scriptTag) removeEventListeners(scriptTag, onLoad, onError);
    };
  }, []);

  return state;
}
