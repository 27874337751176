import PropTypes from 'prop-types';

import InputLabel from '../FieldComponentsRenderer/InputLabel';
import { fieldDictionary } from '../fieldUtils';

export default function FormFieldRenderer({
  type,
  name,
  label,
  settings,
  info,
  onChange,
  value,
  validate,
  disabled,
  fetchOptions,
  isRequired,
}) {
  const Component = fieldDictionary[type];
  if (!Component) {
    console.error(`Input field of type '${type}' does not exist`);
    return null;
  }
  return (
    <Component
      key={name}
      name={name}
      data-testid={`form-component-${name}`}
      label={<InputLabel isOptional={isRequired === false} label={label} />}
      settings={settings}
      info={info}
      onChange={(newValue) => {
        onChange(name, newValue);
      }}
      value={value}
      disabled={disabled}
      validate={validate}
      {...(fetchOptions ? { fetchOptions } : {})}
      {...(isRequired ? { isRequired } : {})}
    />
  );
}

FormFieldRenderer.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  settings: PropTypes.object,
  info: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: PropTypes.bool,
  fetchOptions: PropTypes.func,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
};
