// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n  ._3150OhIs > div {\n    min-height: 76px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateWrapper": "_3150OhIs"
};
export default ___CSS_LOADER_EXPORT___;
