import PropTypes from 'prop-types';
import cc from 'classcat';

import classes from './styles.scss';
import { calculateStatus } from './utils';

const ItemStatus = ({ conditions }) => {
  const status = calculateStatus(conditions);
  return <span className={cc([classes.itemStatus, classes[status]])}>{status}</span>;
};

ItemStatus.propTypes = { conditions: PropTypes.array };

export default ItemStatus;
