export default function ViewFileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41">
      <g fill="none" fillRule="evenodd">
        <path d="M13 12h20.5v20.5H13z" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          transform="translate(-2.278, -1.577)"
          d="M23 17.733c3.1 0 5.866 1.846 7.216 4.767-1.35 2.92-4.107 4.767-7.216 4.767-3.11 0-5.866-1.846-7.216-4.767 1.35-2.92 4.115-4.767 7.216-4.767M23 16c-4.09 0-7.585 2.695-9 6.5 1.415 3.805 4.91 6.5 9 6.5s7.585-2.695 9-6.5c-1.415-3.805-4.91-6.5-9-6.5zm0 4.333c1.13 0 2.045.971 2.045 2.167 0 1.196-.916 2.167-2.045 2.167-1.13 0-2.045-.971-2.045-2.167 0-1.196.916-2.167 2.045-2.167m0-1.733c-2.03 0-3.682 1.75-3.682 3.9s1.653 3.9 3.682 3.9c2.03 0 3.682-1.75 3.682-3.9S25.029 18.6 23 18.6z"
        />
      </g>
    </svg>
  );
}
