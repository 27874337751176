import { useState, useMemo, useEffect } from 'react';
import { css } from '@emotion/react';

import BrainButton from '@shared/components/BrainButton';
import { toast } from '@shared/components/Toaster';
import BrainBackdrop from '@shared/components/BrainForm/BrainBackdrop';

import { createCorrectionRequest } from 'containers/MeColorboard/utils/requests';

import FileDescriptor from 'components/Descriptors/File';
import BaseTextField from 'components/Descriptors/Text';
import DateDescriptor from 'components/Descriptors/Date';
import SelectDescriptor from 'components/Descriptors/Select';
import MainPageHeader from 'components/MainPageHeader';

const containerCss = css`
  display: flex;
  flex-direction: column;
`;

const fileCss = css`
  margin-top: 20px;
`;

const backdropCss = css`
  width: 100%;
  margin-right: 25px;
`;

const buttonsCss = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  button:first-of-type {
    margin-right: 22px;
  }
`;

const fullWidthCss = css`
  grid-column-start: 1;
  width: 100%;
  background-color: var(--white);
  font-size: 14px;
  display: grid;
  margin-top: 20px;
`;

const singleDescriptorCss = ({ fulllWidth }) => css`
  min-width: 240px;
  display: inline-block;
  margin-right: 63px;
  margin-bottom: 29px;
  ${fulllWidth && fullWidthCss}
`;

const productOptions = ['dip', 'uti', 'acr'];

export default function LotCorrectionsPage() {
  const emptyFields = {
    algoName: '',
    timestamp: '',
    stickLotNumber: '',
    product: '',
    correction: '',
  };
  const [fields, setFields] = useState(emptyFields);
  const [isValid, setIsValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted) {
      setSubmitted(false);
    }
  }, [isValid]);

  function isJSON(str) {
    try {
      return JSON.stringify(str) && !!str;
    } catch (e) {
      return false;
    }
  }

  function isDate(str) {
    try {
      return !Number.isNaN(Date.parse(str)) && !!str;
    } catch (e) {
      return false;
    }
  }

  function formValidator(fieldsObj) {
    const emptyField = Object.keys(fieldsObj).find((key) => fieldsObj[key] === '');
    if (emptyField) return false;
    const idx = productOptions.indexOf(fieldsObj.product);
    if (idx === -1) return false;
    if (!isJSON(fieldsObj.correction)) return false;
    if (!isDate(fieldsObj.timestamp)) return false;
    return true;
  }

  function onFileChange(event) {
    const fieldObj = fields;
    try {
      if (event.descriptorState?.fileData) {
        const content = event.descriptorState?.fileData?.file;
        fieldObj.correction = JSON.parse(content);
        setFields(fieldObj);
        const validVal = formValidator(fields);
        setIsValid(validVal);
      } else {
        fieldObj.correction = '';
        setFields(fieldObj);
        setIsValid(false);
      }
    } catch (error) {
      fieldObj.correction = '';
      setFields(fieldObj);
      setIsValid(false);
      toast.error(`Failed to upload txt file: ${error}`, {
        duration: 1500,
      });
    }
  }

  async function onSubmit() {
    try {
      await createCorrectionRequest(fields);
      toast.success(`Correction was Successfully uploaded`, {
        duration: 1500,
      });
      setFields(emptyFields);
      setSubmitted(true);
      setIsValid(false);
    } catch (error) {
      toast.error(`Correction upload was failed: ${error}`, {
        duration: 1500,
      });
    }
  }

  function onInputChange(event) {
    const newVal = event.descriptorState?.value;
    fields[event.descriptorId] = newVal;
    const validVal = formValidator(fields);
    setIsValid(validVal);
    setFields(fields);
  }

  const fileDescriptorState = useMemo(
    () => ({
      gDriveFolderId: '',
      gDriveOldFilesFolderId: '',
      fileTypes: '.txt,.json',
    }),
    [submitted],
  );

  return (
    <>
      <MainPageHeader title="Lot Correction" />
      <div css={containerCss}>
        <div>
          <BrainBackdrop classes={{ backdrop: backdropCss }}>
            <form>
              <div css={singleDescriptorCss({ fulllWidth: false })}>
                <BaseTextField
                  label="Algo Name"
                  id="algoName"
                  descriptorState={{ value: fields.algoName }}
                  onChange={(e) => {
                    onInputChange(e);
                  }}
                  name="base text field"
                />
              </div>
              <div css={singleDescriptorCss({ fulllWidth: false })}>
                <DateDescriptor
                  label="Timestamp"
                  id="timestamp"
                  descriptorState={{ value: fields.timestamp, enableTime: true }}
                  onChange={(e) => {
                    onInputChange(e);
                  }}
                  name="base text field"
                />
              </div>
              <div css={singleDescriptorCss({ fulllWidth: false })}>
                <BaseTextField
                  label="Dipstick Lot Number"
                  id="stickLotNumber"
                  descriptorState={{ value: fields.stickLotNumber }}
                  onChange={(e) => {
                    onInputChange(e);
                  }}
                  name="base text field"
                />
              </div>
              <div css={singleDescriptorCss({ fulllWidth: false })}>
                <SelectDescriptor
                  label="Product"
                  id="product"
                  descriptorState={{ value: fields.product, options: productOptions }}
                  onChange={(e) => {
                    onInputChange(e);
                  }}
                  name="base text field"
                />
              </div>
              <div css={fileCss}>
                <FileDescriptor
                  label="Correction File"
                  onChange={(e) => {
                    onFileChange(e);
                  }}
                  descriptorState={fileDescriptorState}
                />
              </div>
            </form>
          </BrainBackdrop>
          <div css={buttonsCss}>
            <BrainButton
              type="submit"
              onClick={() => {
                onSubmit();
              }}
              disabled={!isValid}
              data-testid="step-page-apply-button"
            >
              Apply
            </BrainButton>
          </div>
        </div>
      </div>
    </>
  );
}
