// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2WgNTnuF {\n  margin-left: 5px;\n  text-transform: uppercase;\n  font-weight: 600;\n  font-size: 13px\n}\n._2WgNTnuF._34KPp-3f {\n    color: var(--green);\n  }\n._2WgNTnuF._3p_Qaj3s {\n    color: var(--orange);\n  }\n._2WgNTnuF._1_wgvZVN {\n    color: var(--red);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemStatus": "_2WgNTnuF",
	"pass": "_34KPp-3f",
	"ongoing": "_3p_Qaj3s",
	"fail": "_1_wgvZVN"
};
export default ___CSS_LOADER_EXPORT___;
