import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';

import { injectReducerAndSaga } from '@shared/redux/injectors/injectReducerAndSaga';

import { reducer, sliceKey, triggerNotification } from './slice';
import selectNotificationsDomain from './selectors';

const defaultAnchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

/**
 *
 * Add `<HealthySnackbarProvider>` as a sibling to you <App /> component when you need to display notifications.
 * Based on `notistack`: https://iamhosseindhv.com/notistack
 * Note: If you're using material-ui `<ThemeProvider>`, make sure `<HealthySnackbarProvider />` is a child of it under the DOM tree, e.g.:
 *
 * import HealthySnackbarProvider from '@shared/components/HealthySnackbarProvider';
 *
 * <ThemeProvider theme={theme}>
      <HealthySnackbarProvider />
      <App />
    </ThemeProvider>
 *
 * If you want a custom component instead of the default provided, pass you own to SnackComponent prop, e.g.:
 *
 * import HealthySnackbarProvider from '@shared/components/HealthySnackbarProvider';
 * import MyCustomSnackBar from 'components/MyCustomSnackBar';
 *
 * <HealthySnackbarProvider SnackComponent={MyCustomSnackBar} />
 *
 * The SnackComponent passes the following props:
 * - `message`
 * - `onClick`: an handler to be called in case you want to dismiss the snack before autoHide
 * - `variant`: The variant used to customize you component appearance according
 */
function HealthySnackbarProvider({
  children,
  classes = {},
  maxSnack = 3,
  iconVariant = {},
  autoHideDuration = 4000,
  anchorOrigin = defaultAnchorOrigin,
  SnackComponent = null,
  ...restProps
}) {
  const { notification } = useSelector(selectNotificationsDomain);
  const notistackRef = useRef(null);
  const dispatch = useDispatch();
  const [variant, setVariant] = useState('default');

  useEffect(() => {
    if (notification && notistackRef) {
      notistackRef.current.enqueueSnackbar(notification.message, {
        variant: notification.type ?? 'default',
      });
      setVariant(notification.type);
      dispatch(triggerNotification({ notification: null }));
    }
  }, [notification]);

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const optionalProps = {
    ...(SnackComponent && {
      content: (key, message) => (
        <div>
          <SnackComponent
            key={key}
            message={message}
            variant={variant}
            onClick={onClickDismiss(key)}
          />
        </div>
      ),
    }),
  };

  return (
    <SnackbarProvider
      classes={classes}
      maxSnack={maxSnack}
      iconVariant={iconVariant}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      ref={notistackRef}
      action={(key) => (
        <Button style={{ color: '#fff' }} onClick={onClickDismiss(key)}>
          Dismiss
        </Button>
      )}
      {...optionalProps}
      {...restProps}
    >
      {children}
    </SnackbarProvider>
  );
}

HealthySnackbarProvider.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  maxSnack: PropTypes.number,
  iconVariant: PropTypes.shape({
    success: PropTypes.node,
    warning: PropTypes.node,
    error: PropTypes.node,
    info: PropTypes.node,
  }),
  autoHideDuration: PropTypes.number,
  SnackComponent: PropTypes.func,
  anchorOrigin: PropTypes.exact({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
};

export default injectReducerAndSaga({
  key: sliceKey,
  reducer,
})(HealthySnackbarProvider);

export { triggerNotification } from './slice';
