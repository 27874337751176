import { call } from 'redux-saga/effects';
import produce from 'immer';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';

import { gqlRequest } from '@shared/utils/graphQl';

import { brainFlattenGqlRequest } from 'utils/gqlUtils';
import { flatCollectDeepNested } from 'utils/collectDeepNested';
import { fetchDescriptorsByPathQuery } from 'utils/sharedQueries';

export function getTemplateFieldsByType({ template }) {
  return {
    descriptor: template.filter(({ type }) => type === 'descriptor'),
    metadata: template.filter(({ type }) => type === 'metadata'),
    root: template.filter(({ type }) => type === 'root'),
  };
}

export function* fetchDescriptorsForAutomationSaga({ processId, descriptorsToFetch }) {
  // query all paths
  const queryParams = {
    processFilter: { id: { equalTo: processId } },
    stageFilter: {
      name: {
        in: uniq(descriptorsToFetch.map(({ path: { stageName } }) => stageName)),
      },
    },
    stepFilter: {
      name: { in: uniq(descriptorsToFetch.map(({ path: { stepName } }) => stepName)) },
    },
    descriptorFilter: {
      name: {
        in: uniq(
          descriptorsToFetch.map(({ path: { descriptorName } }) => descriptorName),
        ),
      },
    },
  };
  const response = yield call(gqlRequest, fetchDescriptorsByPathQuery, queryParams);
  const flatResponse = yield call(brainFlattenGqlRequest, response);

  // extract descriptors list
  return yield call(flatCollectDeepNested, flatResponse, 'descriptors');
}

export function updateProcessWithStepStatuses(process) {
  return produce((draft) => {
    draft.stages.forEach((stage) => {
      stage.steps.forEach((step) => {
        /* eslint-disable no-param-reassign */
        step.status = step.descriptors.find(
          (d) => d.type === 'status',
        )?.descriptorState?.value;
      });
    });
  }, process);
}

/*
 * getDescriptorTree
 *
 * @param {array} fetchedDescriptors - an array of descriptors
 *   (i.e. the `descriptors` prop under the `step` object).
 *
 *
 * @return {object} an object with descriptor names as keys.
 *   If it's a meta descriptor, it will be an object that has the nested descriptor names as keys.
 *   it will look something like this:
 *  {
 *    descriptorName1: { ...descriptor object },
 *    descriptorName2: {
 *      nestedDescriptorName1: { ...descriptor object },
 *      nestedDescriptorName2: { ...descriptor object },
 *  }
 *
 */
export function getDescriptorTree(fetchedDescriptors) {
  const descriptorTree = {};

  fetchedDescriptors.forEach((descriptor) => {
    const {
      name,
      descriptorState: { descriptors },
    } = descriptor;

    if (descriptor.type === 'meta') {
      descriptorTree[name] = { ...descriptor };
      descriptors.forEach((nestedDescriptor) => {
        descriptorTree[name][nestedDescriptor.name] = nestedDescriptor;
      });
    } else {
      descriptorTree[name] = descriptor;
    }
  });

  return descriptorTree;
}

export function getDescriptorData(templateDescriptors, fetchedDescriptors) {
  if (!templateDescriptors || !fetchedDescriptors) return [];

  const descriptorTree = getDescriptorTree(fetchedDescriptors);

  const descriptorData = [];
  templateDescriptors.forEach((descriptor) => {
    const { descriptorName } = descriptor.path;
    const fetchedDescriptor = descriptorTree[descriptorName];

    descriptorData.push({ ...descriptor, ...fetchedDescriptor });
  });

  return descriptorData;
}

export function getMetaDescriptorData(templateNestedDescriptors, fetchedDescriptors) {
  if (!templateNestedDescriptors || !fetchedDescriptors) return [];

  const descriptorTree = getDescriptorTree(fetchedDescriptors);

  const metaDescriptorData = [];

  templateNestedDescriptors.forEach((nestedDescriptor) => {
    const { descriptorName, nestedDescriptorName } = nestedDescriptor.path;
    const fetchedDescriptor = descriptorTree[descriptorName][nestedDescriptorName];

    metaDescriptorData.push({ ...nestedDescriptor, ...fetchedDescriptor });
  });

  return metaDescriptorData;
}

export function getDescriptorsData({ descriptorsToFetch, fetchedDescriptors }) {
  // separate descriptors from meta descriptors
  const { templateDescriptors, templateNestedDescriptors } = groupBy(
    descriptorsToFetch,
    (item) =>
      item.path.nestedDescriptorName
        ? 'templateNestedDescriptors'
        : 'templateDescriptors',
  );
  const { fetchedMetaDescriptors, otherFetchedDescriptors } = groupBy(
    fetchedDescriptors,
    (item) =>
      item.type === 'meta' ? 'fetchedMetaDescriptors' : 'otherFetchedDescriptors',
  );

  // get descriptors data
  const metaDescriptorData = getMetaDescriptorData(
    templateNestedDescriptors,
    fetchedMetaDescriptors,
  );
  const descriptorData = getDescriptorData(templateDescriptors, otherFetchedDescriptors);

  return [...descriptorData, ...metaDescriptorData];
}

export const getErrorMessage = (e) => e?.response?.errors[0]?.message ?? '';
