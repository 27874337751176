// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a.rmrUYZqj,\nbutton._1ExqJ4xF {\n  border: none;\n  border-top: 1px solid var(--sideBarBorderColor);\n  display: flex;\n  align-items: center;\n  position: relative\n}\na.rmrUYZqj._1x5m_G-R, button._1ExqJ4xF._1x5m_G-R {\n    color: var(--sideBarBlue);\n  }\na.rmrUYZqj._1x5m_G-R img._2jY4KvR1, button._1ExqJ4xF._1x5m_G-R img._2jY4KvR1 {\n      opacity: 1;\n    }\na.rmrUYZqj:hover, button._1ExqJ4xF:hover {\n    background-color: var(--sideBarBgColor);\n  }\na.rmrUYZqj img, button._1ExqJ4xF img {\n    width: var(--sideBarWidth);\n    flex-shrink: 0;\n    padding: 0 8px\n  }\na.rmrUYZqj img._2jY4KvR1, button._1ExqJ4xF img._2jY4KvR1 {\n      position: absolute;\n      left: 0;\n      opacity: 0;\n      transition: opacity ease-in-out 50ms;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "rmrUYZqj",
	"button": "_1ExqJ4xF",
	"active": "_1x5m_G-R",
	"activeImg": "_2jY4KvR1"
};
export default ___CSS_LOADER_EXPORT___;
