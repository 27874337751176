import { useSelector } from 'react-redux';

import { isBrainTestUser } from '@shared/utils/integrationTestUtils';

import { userDataSelector } from './useUserData';

export default function useHasPermissions(scope) {
  const { umPermissions, email } = useSelector(userDataSelector);
  if (isBrainTestUser(email)) {
    return true;
  }
  const rolePermissions = {}; // Check once we add roles.
  const permissions = { ...rolePermissions, ...umPermissions?.additionalClaims };

  const scopeCopy = scope
    //    .replace(/infosec-incident-reports/, 'incident-reports')
    .replace(/wound/, 'spot');

  return !!permissions[scopeCopy];
}
