import { useCallback } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

import useImageWithFallback from '@shared/hooks/useImageWithFallback';

import useUserData from 'containers/App/useUserData';
import { useUserProfile } from 'containers/OpenTasks/useUserProfile';

import OpenTasksIcon from 'images/icon-components/OpenTasksIcon';
import avatarFallback from 'images/side-bar/default-avatar.svg';

import classes from './UserDetails.scss';

export default function UserDetails({ isExpanded }) {
  const { name, email, avatarUrl } = useUserData();

  const { showUserProfile, setShowUserProfile, tasks } = useUserProfile();

  const { imagePath } = useImageWithFallback({
    imagePath: avatarUrl,
    fallbackPath: avatarFallback,
  });

  const toggleOpenTasks = useCallback(() => {
    setShowUserProfile(!showUserProfile);
  }, [showUserProfile]);

  const numTasks = tasks ? tasks.length : 0;

  return (
    <>
      <div className={classes.userDetails}>
        <img src={imagePath} alt="" />
        <div>
          <div className={classes.name}>{name}</div>
          <div className={classes.email}>{email}</div>
        </div>
      </div>

      <button
        className={cc([classes.tasksButton, { [classes.active]: showUserProfile }])}
        type="button"
        onClick={toggleOpenTasks}
      >
        <div className={cc([classes.tasksIcon, { [classes.expanded]: isExpanded }])}>
          <OpenTasksIcon />
          {!!numTasks && <span className={classes.badge}>{numTasks}</span>}
        </div>
        <span className={classes.text}>Open tasks</span>
        {!!numTasks && <span className={classes.badge}>{numTasks}</span>}
      </button>
    </>
  );
}

UserDetails.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
};
