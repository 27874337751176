import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import classes from './styles.scss';

export default function LoadingStateIcon({ color = 'var(--brain-primary)' }) {
  return (
    <div
      className={classes.progressIcon}
      style={{ '--color': color }}
      data-testid="loading-state-icon"
    >
      <CircularProgress size={50} color="inherit" />
    </div>
  );
}

LoadingStateIcon.propTypes = {
  color: PropTypes.string,
};
