import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import useHasPermissions from 'containers/App/useHasPermissions';

import UnauthorizedPage from '../UnauthorizedPage';

export default function PermissionRoute({ children, scope, ...restProps }) {
  if (!useHasPermissions(scope)) {
    return <UnauthorizedPage />;
  }

  return <Route {...restProps}>{children}</Route>;
}

PermissionRoute.propTypes = {
  children: PropTypes.node,
  scope: PropTypes.string.isRequired,
};
