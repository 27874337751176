import { GraphQLClient } from 'graphql-request';

let client;
let middleware;
let errorHandler;

/**
 *
 * @param {string} graphQlEndpoint - the endpoint of the service.
 * @param {Object} options
 * @param {function[]} options.requestMiddleware - callbacks that are executed before returning the response.
 * recieve the response as a parameter.
 * @param {function} options.errorHandler - custom error handler, will be executed first on response error.
 * @example <caption>basic</caption>
 * const client = initGqlClient('https://www.my-endpoint.com', {});
 * @example <caption> to use with authentication headers and middleware: </caption>
 * const client = initGqlClient('https://www.my-endpoint.com', {requestMiddleware: [exampleFn]});
 * client.setHeaders({'Authorization': 'Bearer token'})
 * @returns Reference to the gqlClient object.
 */
export function initGqlClient(graphQlEndpoint, options = {}) {
  if (!graphQlEndpoint) {
    throw new TypeError('You must provide a URL endpoint for initialization');
  }
  const { requestMiddleware, errorHandler: errorHandlerFn, ...restOptions } = options;

  middleware = requestMiddleware ?? [];

  errorHandler = errorHandlerFn;

  client = new GraphQLClient(graphQlEndpoint, {
    ...restOptions,
  });

  return client;
}

export async function gqlRequest(...args) {
  try {
    const response = await client.rawRequest(...args);
    // from here: https://advancedweb.hu/how-to-use-async-functions-with-array-foreach-in-javascript/
    await middleware.reduce(async (memo, nextFn) => {
      await memo;
      await nextFn(response);
    }, undefined);

    return response.data;
  } catch (e) {
    if (errorHandler) {
      return errorHandler(e);
    }
    throw e;
  }
}
