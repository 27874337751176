import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import classes from './styles.scss';

export default function Control({
  selectProps: { label },
  children,
  isDisabled,
  innerRef,
  innerProps,
}) {
  return (
    <TextField
      label={label}
      inputRef={innerRef}
      disabled={isDisabled}
      classes={{
        root: classes.textField,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focused,
          disabled: classes.disabled,
        },
        'data-testid': 'brain-select-label',
      }}
      InputProps={{
        inputComponent: 'div',
        classes: {
          root: classes.inputField,
          focused: classes.focused,
          disabled: classes.disabled,
          input: classes.input,
        },
        inputProps: {
          children,
          'data-testid': 'brain-select-input',
        },
      }}
      {...innerProps}
    />
  );
}

Control.propTypes = {
  selectProps: PropTypes.PropTypes.shape({
    label: PropTypes.node.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  innerRef: PropTypes.func.isRequired,
  innerProps: PropTypes.object.isRequired,
};
