import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

import ConfirmationDialog from '@shared/components/BrainDialogs/ConfirmationDialog';

/**
 * Use the `BeforeUnloadBrainDialog` component when you need to warn users before leaving a page - with a Brain dialog.
 * A wrapper for react-router-dom's `Prompt` component that also warns when the user tries to: reload / close tab / close window / leave the SPA
 *
 * Using the code idea shown in: https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
 */
function BeforeUnloadBrainDialog({
  when,
  navigate,
  message = 'All changes made will be lost. Continue anyway?',
}) {
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState();

  const showModal = (location) => {
    setLastLocation(location);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && when) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmationDialog
        name="confirm-cancel-dialog"
        dialogTitle="You have unsaved changes"
        isOpen={modalVisible}
        onConfirm={handleConfirmNavigationClick}
        onCancel={closeModal}
      >
        {message}
      </ConfirmationDialog>
    </>
  );
}

BeforeUnloadBrainDialog.propTypes = {
  /** Prompt the user if true */
  when: PropTypes.bool.isRequired,
  /** The actual navigation function. For example: (location)=>history.push(location) */
  navigate: PropTypes.func.isRequired,
  /** Sets the wording for the message informing. Defaults to "You have unsaved changes which will be lost if you leave this page. Do you want to continue?". */
  message: PropTypes.string,
};

export default BeforeUnloadBrainDialog;
