export default function UploadCloudIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14">
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M10.063 5.245l3.091 4.034H10.84v3.51H9.285v-3.51H6.972l3.09-4.034z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.783"
          d="M12.888 12.79h3.824c1.957 0 3.559-1.555 3.559-3.457 0-1.897-1.602-3.454-3.56-3.454h-.282c-.289-1.162-1.361-2.036-2.65-2.036-.313 0-.632.064-.891.152C12.255 2.25 10.552 1 8.539 1 6 1 3.932 3.002 3.932 5.474c0 .153.013.306.025.459C2.282 6.214 1 7.635 1 9.333c0 1.902 1.602 3.458 3.565 3.458h2.407"
        />
      </g>
    </svg>
  );
}
